import { Modal, Select, Table } from "antd";
import React, { useMemo, useState } from "react";
import ChantButton from "../../../../CommonComponents/ChantButton/ChantButton";
import dayjs from "dayjs";

const EditOrderModal = ({
  editableOrderId,
  tableData,
  editedRecords,
  editStatus,
  setEditStatus,
  handleRecordStatusChange,
  handleUpdateStatus,
  statusUpdateLoading,
}) => {
  const options = [
    { label: "--Select--", value: "select" },
    { label: "Shipped", value: "shipped" },
    { label: "Picked up", value: "pickedup" },
    { label: "Refunded", value: "refunded" },
    { label: "Unclaimed", value: "unclaimed" },
    { label: "Unfulfilled", value: "unfulfilled" },
  ];

  const currentData = useMemo(() => {
    return tableData?.find((rec) =>
      editableOrderId.includes(`${rec.id}|${rec.itemIndex}`)
    );
  }, [editableOrderId, tableData]);

  const [disableSave, setDisableSave] = useState(false);

  const editableTableCols = [
    {
      title: "Status",
      dataIndex: "status",
      className: "header-white",
      render: (data, record) => {
        let records = editedRecords || {};
        records[record.id] = { ...(editedRecords[record.id] || {}) };

        let value = records[record.id][record.itemIndex] || data;

        return (
          <Select
            disabled={statusUpdateLoading}
            value={value}
            popupMatchSelectWidth={false}
            onChange={(value) => {
              if (value === "select") {
                setDisableSave(true);
                return;
              } else {
                setDisableSave(false);
              }

              let records = editedRecords || {};
              records[record.id] = {
                ...(editedRecords[record.id] || {}),
              };
              records[record.id][record.itemIndex] = value;

              handleRecordStatusChange(records);
            }}
            style={{ width: "100%" }}
            options={options?.map((item) => ({
              value: item.value,
              label: <span>{item.label}</span>,
            }))}
          />
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      className: "header-white",
    },
    {
      title: "Status Updated",
      dataIndex: "statusUpdateTimestamp",
      className: "header-white",
      render: (data) => {
        let date = dayjs.unix(data);
        if (date.isValid() && data) {
          return <span>{dayjs.unix(data).format("MMM D") || ""}</span>;
        }
        return "";
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      className: "header-white",
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      title: "Item",
      dataIndex: "itemName",
      className: "header-white",
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      className: "header-white",
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      title: "Size",
      dataIndex: "size",
      className: "header-white",
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      title: "Color",
      dataIndex: "color",
      className: "header-white",
      render: (data) => {
        return <span>{data}</span>;
      },
    },
  ];

  return (
    <Modal
      open={editStatus}
      onCancel={() => {
        setEditStatus(false);
      }}
      width={700}
      height={500}
      title={""}
      centered
      footer={[
        <ChantButton
          key="submit"
          type="primary"
          onClick={handleUpdateStatus}
          disabled={disableSave || statusUpdateLoading}
          loading={statusUpdateLoading}
        >
          Save
        </ChantButton>,
      ]}
    >
      <div
        style={{
          paddingTop: 20,
          width: "100%",
          maxHeight: "500px",
          overflow: "auto",
        }}
      >
        <Table
          bordered
          columns={editableTableCols}
          dataSource={[currentData]}
          pagination={false}
          className="table-backdrop"
          bodyStyle={{ backgroundColor: "#ffffff" }}
          scroll={{ x: "max-content" }}
        />
      </div>
    </Modal>
  );
};

export default EditOrderModal;
