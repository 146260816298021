import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Layout, Menu, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAdminData,
  selectCurrentGroupData,
  selectUserDetails,
} from "../../../redux/selectors/adminData";
import useSideNav from "./useSideNav";
import "./SideNav.css";
import { fbLogoutUser } from "../../../services/firebaseService/endPoints/user";
import { useNavigate } from "react-router-dom";
import { adminGroupChange } from "../../../redux/actions/adminData";
import { LogoutIcon } from "../../../utils/icons.jsx";
import useGlobalStore from "../../../store/store";
import { isMobile } from "../../../utils/helper";
import { resetThemeColors } from "../../../redux/actions/app";
const { Sider } = Layout;

const SideNav = ({ expanded, handleSideNavCollapse }) => {
  const groupData = useSelector(selectCurrentGroupData);
  const adminData = useSelector(selectAdminData);
  const userData = useSelector(selectUserDetails);
  const navigate = useNavigate();
  const resetAllStores = useGlobalStore((state) => state.resetAllStores);
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const { getStyles, adminLinks, clubAdminLinks } = useSideNav();

  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState("");
  const [manuallyToggled, setManuallyToggled] = useState(false);

  const { backgroundColor, color, style } = getStyles(groupData);

  const sidenavExpanded = useGlobalStore((state) => state.app.sidenavExpanded);
  const setSidenavExpanded = useGlobalStore(
    (state) => state.app.setSidenavExpanded
  );

  const handleCollapse = (value) => {
    handleSideNavCollapse(value);
    setSidenavExpanded(!sidenavExpanded);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
    setManuallyToggled(true);
  };

  const onSelect = ({ key, keyPath }) => {
    setSelectedKey(key);
    if (keyPath.length > 1) {
      setOpenKeys([keyPath[1]]);
    }
  };

  const handleLogout = () => {
    fbLogoutUser().then(() => {
      dispatch(resetThemeColors());
      resetAllStores();
      navigate("/login");
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Handle admin group change
    const path = window.location.pathname.split("/")[1];

    if (
      groupData?.dashboardType !== "superadmin" &&
      userData.role === "super-admin" &&
      path === "superadmin"
    ) {
      dispatch(adminGroupChange({ group: "", dashboard: "superadmin" }));
    }

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [groupData?.dashboardType, userData.role, dispatch]);

  const rootEl = document.querySelector(":root");
  rootEl.style.setProperty("--top-margin", expanded ? "0px" : "-5px");

  const subMenuParentMap = useMemo(() => {
    return {
      "/admin/members": "sub1",
      "/admin/membershipcard": "sub1",

      "/admin/content": "sub2",
      "/admin/welcome": "sub2",
      "/admin/gallery": "sub2",
      "/admin/partners": "sub2",
      "/admin/chants": "sub2",

      "/admin/store": "sub3",
      "/admin/store/orders": "sub3",

      "/admin/tickets": "sub4",

      "/admin/website": "sub5",

      "/admin/stadium": "sub6",

      "/admin/directory": "sub7",

      "/admin/help": null,

      "/admin/groupSettings": "sub8",
      "/admin/appSettings": "sub8",
      "/admin/paymentDetails": "sub8",
    };
  }, []);

  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const prevPathRef = useRef();

  useEffect(() => {
    if (expanded) {
      const locationPath = window.location.pathname;

      // Check if path has changed
      if (locationPath !== currentPath) {
        setCurrentPath(locationPath);
        setSelectedKey(locationPath);
      }

      let path = locationPath.split("/");
      const updatedPath = `/${path[1]}/${path[2]}`;

      if (
        prevPathRef.current !== locationPath &&
        subMenuParentMap[updatedPath] &&
        openKeys[0] !== subMenuParentMap[updatedPath] &&
        !manuallyToggled
      ) {
        setOpenKeys([subMenuParentMap[updatedPath]]);
        setCurrentPath(updatedPath);
        setManuallyToggled(false);
      }

      if (manuallyToggled) {
        setManuallyToggled(false);
      }

      prevPathRef.current = locationPath;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, openKeys, manuallyToggled, subMenuParentMap]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <Sider
      collapsible
      collapsed={!expanded}
      onCollapse={handleCollapse}
      collapsedWidth={width > 500 ? "80" : "0"}
      width={215}
      style={{
        backgroundColor: backgroundColor,
        position: "fixed",
        overflow: "auto",
        height: "100%",
        zIndex: "100",
        // borderRight: "1px solid var(--primary-text-color)",
        ...style,
        "--sidenav-trigger-bg-color": backgroundColor,
        "--sidenav-submenu-item-margin": expanded ? "8px" : "0px",
      }}
      className="side-nav"
    >
      <div className="demo-logo-vertical" />
      <Menu
        theme="dark"
        mode="inline"
        items={
          userData.role === "admin"
            ? adminData?.dashboardType === "admin"
              ? adminLinks
              : adminData?.dashboardType === "clubadmin"
                ? clubAdminLinks
                : []
            : []
        }
        defaultSelectedKeys={[window.location.pathname]}
        // selectedKeys={[window.location.pathname]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        onSelect={onSelect}
        selectedKeys={[selectedKey]}
        onClick={() => isMobile() && handleSideNavCollapse(true)}
      />
      <div>
        <Popconfirm
          title="Are you sure?"
          onCancel={() => {}}
          onConfirm={() => {
            handleLogout();
          }}
          okText="Yes"
          cancelText="No"
        >
          <div
            className="sidenav-logout-btn"
            style={{
              "--logout-btn-bg-color": backgroundColor,
              "--logout-btn-text-color": color,
            }}
          >
            {expanded ? (
              <Button
                className="btn-info"
                style={{
                  background: "transparent",
                  width: 100,
                  borderColor: color,
                  color: color,
                }}
                block
              >
                Logout
              </Button>
            ) : (
              <i className="anticon cursor-pointer">
                <img alt="logout-button" src={LogoutIcon} width={22} />
              </i>
            )}
          </div>
        </Popconfirm>
      </div>
    </Sider>
  );
};
export default SideNav;
