import React, { Component } from "react";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import logger from "../../../utils/logger";
import MyGoogleMap from "./MyGoogleMap";

// Use the correct environment variable for Google Maps
Geocode.setApiKey(import.meta.env.VITE_GOOGLE_MAPS_API_KEY);
Geocode.enableDebug();

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: this.props.lat || 33.7488,
      long: this.props.long || -84.3877,
      city: "Atlanta",
      state: "GA",
      area: "",
      placeId: "",
      showInfo: false,
    };
  }

  updateFormMapData = (_) => {
    this.props.mapUpdate({
      address: this.props.address,
      lat: this.state.lat,
      long: this.state.long,
      placeId: this.state.placeId,
    });
  };

  onMarkerDragEnd = (e) => {
    Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);

        this.props.updateAddress(address);

        this.setState({
          // address: address || "",
          area: area || "",
          city: city || "",
          state: state || "",
          lat: e.latLng.lat(),
          long: e.latLng.lng(),
          placeId: response.results[0].place_id,
        });
      },
      (error) => {
        logger.error(error);
      }
    );
  };

  onPlaceSelected = (e) => {
    if (e.address_components) {
      const address = e.formatted_address,
        addressArray = e.address_components,
        city = this.getCity(addressArray),
        area = this.getArea(addressArray),
        state = this.getState(addressArray),
        latValue = e.geometry.location.lat(),
        lngValue = e.geometry.location.lng();

      this.props.updateAddress(address);
      this.setState({
        // address: address || "",
        area: area || "",
        city: city || "",
        state: state || "",
        lat: latValue,
        long: lngValue,
        placeId: e.place_id,
      });
    }
  };

  handleInputChange = (event) => {
    // this.setState({ address: event.target.value });
    this.props.updateAddress(event.target.value);
  };

  render() {
    return (
      <div
        className="google-map-main-container"
        style={{ margin: "24px 20px" }}
      >
        <MyGoogleMap
          lat={this.state.lat}
          lng={this.state.long}
          onMarkerDragEnd={this.onMarkerDragEnd}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `70vh`, width: `140vh` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />

        <Autocomplete
          style={{ width: "100%", height: "40px", paddingLeft: "16px" }}
          ref={this.autocompleteRef}
          value={this.props.address}
          onChange={this.handleInputChange}
          onPlaceSelected={this.onPlaceSelected}
          types={["establishment", "geocode"]}
        />
      </div>
    );
  }

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  fetchCurrentLocation = () => {
    if (this.props.isGeolocationAvailable) {
      if (this.props.isGeolocationEnabled) {
        if (this.props.coords) {
          this.setState({
            lat: this.props.coords.latitude,
            long: this.props.coors.longitude,
          });
        }
      } else {
        logger.log("location denied");
      }
    }
  };

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    // if (this.props.coords !== null || this.props.place_id === "") {
    this.fetchCurrentLocation();
    // }
  }

  componentWillReceiveProps(newProps) {
    if (
      this.props.coords === null &&
      newProps.coords !== null &&
      newProps.placeId === ""
    ) {
      // if (newProps.isGeolocationEnabled && newProps.isGeolocationAvailable) {
      Geocode.fromLatLng(
        newProps.coords.latitude,
        newProps.coords.longitude
      ).then(
        (response) => {
          const address = response.results[0].formatted_address,
            addressArray = response.results[0].address_components,
            city = this.getCity(addressArray),
            area = this.getArea(addressArray),
            state = this.getState(addressArray);

          this.props.updateAddress(address);
          this.setState({
            // address: address ? address : "",
            area: area ? area : "",
            city: city ? city : "",
            state: state ? state : "",
            lat: response.results[0].geometry.location.lat,
            long: response.results[0].geometry.location.lng,
            placeId: response.results[0].place_id,
          });
          // this.updateFormMapData();
        },
        (error) => {
          logger.error(error);
        }
      );
      // }
    }
  }
}

export default Map;
