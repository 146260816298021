import axios from "axios";
import logger from "../../../../../utils/logger";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../../connection";

export const sendPollNotification = async (
  groupId,
  groupName,
  pollId,
  question
) => {
  try {
    const notificationBody = {
      type: "polls",
      gId: groupId,
      topic: `${groupId}_pollUpdates`,
      tit: groupName + " Poll",
      bdy: question,
      pId: pollId,
      uniqueId: pollId,
    };

    await axios.post(
      "https://us-central1-chant2019.cloudfunctions.net/sendBulkNotification",
      JSON.stringify(notificationBody),
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    return true;
  } catch (error) {
    logger.error("Failed to send poll notification", error);
    return false;
  }
};

export const fbUploadPollImage = async (file, groupName, fileName) => {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage);
    const folderRef = ref(storageRef, `${groupName}/polls/${fileName}.jpg`);

    uploadBytes(folderRef, file)
      .then(async (snapshot) => {
        const downloadUrl = await getDownloadURL(snapshot.ref);
        resolve(downloadUrl, snapshot.metadata.fullPath);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
