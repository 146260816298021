import React from "react";
import { Tooltip, Table, message, Popconfirm, Button } from "antd";
import moment from "moment";

import { connect } from "react-redux";
import {
  setGettingStartedItemStatus,
  startAddContents,
  startDeleteContent,
  startRefreshContents,
} from "../../../redux/actions/adminData";
import { fbDeleteContent } from "../../../services/firebaseService/endPoints/admin/content";
import { getContentsFromLocal } from "../../../services/indexedDb/content";
import LoadingModal from "../../commons/LoadingModal";
import { switchCollapseHorizontal } from "../../../helperFunctions/util";
import CopyEventModal from "./CopyEventModal";
import {
  GUIDE_ENGAGE,
  TOPIC_POST_EVENTS,
} from "../../../constants/gettingStarted";
import {
  CopyIcon,
  DeleteIcon,
  EditIcon,
  ReloadIcon,
} from "../../../utils/icons.jsx";
import { withRouter } from "../../../utils/helper";
import dayjs from "dayjs";
import ScreenHeader from "../../../CommonComponents/ScreenHeader";
import ChantButton from "../../../CommonComponents/ChantButton/ChantButton";
import TabNav from "../../../CommonComponents/TabNav/TabNav";

class ListContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "current",
      isLoading: true,
      pagination: {},
      tableData: [],
      currentContentId: "",
      sorter: { field: "", order: "" },
      filters: {
        details: ["details.eventStartDate"],
        rsvp: ["rsvp.attending"],
        showOnHomePage: ["showOnHomePageEnd"],
      },
      showCopyEventModal: false,
    };
  }

  toggleCopyEventModal = () => {
    this.setState({
      showCopyEventModal: !this.state.showCopyEventModal,
    });
  };

  handleEditContent = (data) => {
    localStorage.setItem(
      "contentsConfiguration",
      JSON.stringify({
        tab: this.state.tab,
        pagination: this.state.pagination,
        tableData: this.state.tableData,
        sorter: this.state.sorter,
        filters: this.state.filters,
      })
    );
    this.props.router.navigate(
      `/admin/content/edit/${this.props.currentGroup}/${data.id}`
    );
  };

  handleViewContent = (data) => {
    localStorage.setItem(
      "contentsConfiguration",
      JSON.stringify({
        tab: this.state.tab,
        pagination: this.state.pagination,
        tableData: this.state.tableData,
        sorter: this.state.sorter,
        filters: this.state.filters,
      })
    );
    this.props.router.navigate(
      `/admin/content/view/${this.props.currentGroup}/${data.id}`
    );
  };

  handleDeleteContent = (data) => {
    let confirm = window.confirm("Are you sure?");
    if (confirm) {
      const msg = message.loading("Deleting content", 0);
      fbDeleteContent(data.id, !!data.details.image, this.props.currentGroup)
        .then(() => {
          msg();
          this.props.dispatch(
            startDeleteContent(this.state.tab, this.props.currentGroup, data.id)
          );
          message.success("Successfully deleted content");
          this.setState({ isLoading: true });
        })
        .catch((err) => {
          msg();
          message.error(err.message);
        });
    }
  };

  handleCopyContent = (data) => {
    localStorage.setItem(
      "contentsConfiguration",
      JSON.stringify({
        tab: this.state.tab,
        pagination: this.state.pagination,
        tableData: this.state.tableData,
        sorter: this.state.sorter,
        filters: this.state.filters,
      })
    );
    // this.props.router.navigate(
    //   `/admin/content/edit/${this.props.currentGroup}/${data.id}`
    // );
    this.setState({ currentContentId: data.id });
    this.toggleCopyEventModal();
  };

  changeTab = (tabName) => {
    this.setState(() => ({
      tab: tabName,
      isLoading: true,
      pagination: { current: 1, position: "top" },
      tableData: [],
    }));
  };

  handleTableChange = (pagination, filters, sorter) => {
    filters = { ...this.state.filters, ...filters };

    if (filters?.details?.[0] !== this.state?.filters?.details?.[0])
      sorter = { ...sorter, columnKey: "details", order: "ascend" };
    if (filters.rsvp[0] !== this.state.filters.rsvp[0])
      sorter = { ...sorter, columnKey: "rsvp", order: "descend" };
    if (filters.showOnHomePage[0] !== this.state.filters.showOnHomePage[0])
      sorter = { ...sorter, columnKey: "showOnHomePage", order: "ascend" };

    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sorter,
      filters,
    });
    this.fetchContents(pager.current, sorter, filters);
    // document.querySelector(".ant-table-body").scrollTo(0, 0);
  };

  handleContentRefresh = () => {
    // this.props.dispatch(refreshContents(this.state.tab, this.props.currentGroup))
    this.props.dispatch(
      startRefreshContents(this.state.tab, this.props.currentGroup)
    );
    this.props.dispatch(
      startAddContents(this.state.tab, this.props.currentGroup)
    );

    this.setState((prevState) => ({
      isLoading: true,
      tableData: [],
      pagination: { ...prevState.pagination, current: 1, total: undefined },
    }));
  };

  changeHeaderColor = () => {
    let primaryColor = "";
    if (
      this.props.adminData?.data?.configuration &&
      this.props.adminData?.data?.configuration?.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    let elements = document.querySelectorAll("th");
    elements.forEach((elem) => {
      elem.style.backgroundColor = primaryColor;
    });
  };

  getTimeFromMatchName = (matchName) => {
    const match = matchName.match(/\((\d{1,2}[a-z]{2}-[A-Za-z]+-\d{2})\)/);

    const datePart = match ? match[1] : null;

    return datePart;
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData?.data?.configuration &&
      this.props.adminData?.data?.configuration?.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    const tabItems = [
      {
        key: "current",
        label: "Current",
      },
      {
        key: "past",
        label: "Past",
      },
    ];

    const handleTabChange = (key) => {
      this.changeTab(key);
    };

    const columns = [
      {
        title: window.screen.width > 500 ? "Action" : "",
        className: "header-white",
        width: 150,
        render: (data) => {
          if (data.loading) {
            return {
              children: <></>,
              colSpan: 3,
            };
          }
          return (
            <>
              {/* {this.state.tab === "current" && ( */}

              <div style={{ display: "flex", gap: "8px" }}>
                <Tooltip title="edit">
                  <i
                    className="ml-1 cursor-pointer"
                    onClick={() => this.handleEditContent(data)}
                  >
                    <img
                      src={EditIcon}
                      style={{ width: 25, height: 25 }}
                      alt="edit"
                    />
                  </i>
                </Tooltip>
                <Tooltip title="copy">
                  <i
                    className="ml-1 cursor-pointer"
                    onClick={() => this.handleCopyContent(data)}
                  >
                    <img
                      src={CopyIcon}
                      style={{ width: 20, height: 20 }}
                      alt="copy"
                    />
                  </i>
                </Tooltip>
                <Tooltip title="delete">
                  <i
                    className="ml-1 cursor-pointer"
                    onClick={() => this.handleDeleteContent(data)}
                  >
                    <img
                      src={DeleteIcon}
                      style={{ width: 25, height: 25 }}
                      alt="delete"
                    />
                  </i>
                </Tooltip>
                {this.state.tab === "past" && (
                  <Tooltip title="view" className="flex-center">
                    <i
                      className="fa fa-eye cursor-pointer ml-1"
                      aria-hidden="true"
                      onClick={() => {
                        this.handleViewContent(data);
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            </>
          );
        },
      },
      {
        title: "Type",
        width: window.screen.width > 500 ? 160 : 100,
        className: "header-white",
        key: "type",
        sorter: "true",
        dataIndex: "type",
        sortOrder:
          this.state.sorter.columnKey === "type" && this.state.sorter.order,
      },
      {
        title: "Match",
        width: 150,
        className: "header-white",
        key: "matchName",
        dataIndex: "awayTeam",
        sorter: true,
        render: (data, record) => {
          return (
            <span>
              {data.team_name} <br />
              {record?.matchDate
                ? dayjs.unix(record?.matchDate).format("Do MMM, YYYY")
                : this.getTimeFromMatchName(record?.matchName)}
            </span>
          );
        },
        sortOrder:
          this.state.sorter.columnKey === "matchName" &&
          this.state.sorter.order,
      },
      {
        title: "Title",
        // width: window.screen.width > 500 ? 420 : 120,
        // width: 200,
        key: "title",
        align: "left",
        className: "header-white",
        dataIndex: "title",
        sorter: true,
        sortOrder:
          this.state.sorter.columnKey === "title" && this.state.sorter.order,
      },
      {
        title: "Event Points",
        key: "eventPoints",
        align: "left",
        className: "header-white",
        render: (data) => {
          const { points = undefined, season = undefined } =
            data?.checkIn || {};
          return <span>{points && season ? `${points} / ${season}` : ""}</span>;
        },
      },
      {
        title: "Volunteer",
        key: "slotName",
        align: "left",
        className: "header-white",
        render: (data) => {
          const values = Object.values(data?.volunteerRequests || {})
            .sort((a, b) => a?.sortIndex - b?.sortIndex)
            .map((item) => ({
              title: item?.title,
              points: item?.points,
              slots: item?.slotCount,
            }));

          return (
            <>
              {values.map((val, index) => (
                <div key={index}>
                  {`${val.title}: ${val.slots} slots / ${val.points}pts`}
                </div>
              ))}
            </>
          );
        },
      },
      {
        title: "Date",
        key: "details",
        dataIndex: "details",
        className: "header-white",
        // width: window.screen.width > 500 ? 220 : 120,
        sorter: true,
        sortOrder:
          this.state.sorter.columnKey === "details" && this.state.sorter.order,
        render: (details) => {
          let startDate = "",
            endDate = "";
          if (details.eventStartDate) {
            startDate = moment.unix(details.eventStartDate);
            startDate = startDate.isValid()
              ? startDate.format("Do MMM, YYYY")
              : "";
          }
          if (details.eventEndDate && details.eventEndDate !== 9999999999) {
            endDate = moment.unix(details.eventEndDate);
            endDate = endDate.isValid() ? endDate.format("-Do MMM, YYYY") : "";
          }
          return (
            <span>
              {startDate === endDate.slice(1)
                ? `${startDate}${endDate}`
                : `${startDate}${endDate}`}
            </span>
          );
        },
      },
      {
        title: "Location",
        // width: window.screen.width > 500 ? 200 : 100,
        key: "details.location",
        className: "header-white",
        sorter: true,
        render: (rec) => {
          if (rec.location) {
            return <span>{rec.location}</span>;
          } else {
            let allLocations = [];
            if (rec.mapsData) {
              allLocations.push(rec.mapsData);
            }
            allLocations.push(...(rec.additionalLocations || []));

            let location = allLocations
              .map((loc) => loc.name || "")
              .filter((locName) => locName)
              .join(",");
            return <span>{location}</span>;
          }
        },
        sortOrder:
          this.state.sorter.columnKey === "details.location" &&
          this.state.sorter.order,
      },
      {
        title: "RSVP",
        // width: window.screen.width > 500 ? 130 : 100,
        key: "rsvp",
        dataIndex: "rsvp",
        className: "header-white",
        sorter: true,
        sortOrder:
          this.state.sorter.columnKey === "rsvp" && this.state.sorter.order,
        render: (rsvp) => {
          return (
            <>
              <span className="color-green">
                {rsvp ? rsvp.attending || 0 : 0}
              </span>
              &nbsp;|&nbsp;
              <span className="color-yellow">{rsvp ? rsvp.mayBe || 0 : 0}</span>
              &nbsp;|&nbsp;
              <span className="color-red">{rsvp ? rsvp.no || 0 : 0}</span>
            </>
          );
        },
      },
      // }, {
      //     title: 'Show on Home?',
      //     width: 150,
      //     key: '',
      //     render: (data) => {
      //         if (data.loading) return { props: { colSpan: 0 } }
      //         if (data.showOnHomePageStart)
      //             return <>Y</>
      //         else
      //             return <>N</>
      //     },

      // }
      {
        title: "Show on home screen",
        // width: window.screen.width > 500 ? 200 : 150,
        width: 300,
        key: "showOnHomePage",
        className: "header-white",
        sorter: true,
        sortOrder:
          this.state.sorter.columnKey === "showOnHomePage" &&
          this.state.sorter.order,
        render: (data) => {
          if (data.loading) return { props: { colSpan: 0 } };
          let showOnHomePageStart = "",
            showOnHomePageEnd = "",
            color = "";
          if (data.showOnHomePageStart) {
            showOnHomePageStart = moment.unix(data.showOnHomePageStart);
            if (showOnHomePageStart.unix() < Date.now() / 1000) color = "green";
            showOnHomePageStart = showOnHomePageStart.isValid()
              ? showOnHomePageStart.format("Do MMM")
              : "";
          }

          if (data.showOnHomePageEnd) {
            showOnHomePageEnd = moment.unix(data.showOnHomePageEnd);
            color =
              "green" && showOnHomePageEnd.unix() > Date.now() / 1000
                ? (color = "green")
                : (color = "");
            showOnHomePageEnd = showOnHomePageEnd.isValid()
              ? showOnHomePageEnd.format("-Do MMM")
              : "";
          }

          return (
            <span style={{ color }}>
              {showOnHomePageStart}
              {showOnHomePageEnd}
            </span>
          );
        },
        dataIndex: "",
      },
      {
        title: "Notification1",
        // width: window.screen.width > 500 ? 220 : 120,
        className: "header-white",
        // align: 'center',
        key: "notifications[0]",
        render: (data) => {
          let date = data.notifications[0];
          return date && moment.unix(date).isValid()
            ? // ? moment.unix(date).format("Do MMM @ HH:mm")
              moment.unix(date).format("Do MMM, YYYY")
            : "";
        },
      },
      {
        title: "Notification2",
        // width: window.screen.width > 500 ? 220 : 120,
        key: "notifications[1]",
        className: "header-white",
        render: (data) => {
          let date = data.notifications[1];
          return date && moment.unix(date).isValid()
            ? moment.unix(date).format("Do MMM, YYYY")
            : "";
        },
      },
      {
        title: "Notification3",
        // width: window.screen.width > 500 ? 220 : 120,
        key: "notifications[2]",
        className: "header-white",
        render: (data) => {
          let date = data.notifications[2];
          return date && moment.unix(date).isValid()
            ? moment.unix(date).format("Do MMM, YYYY")
            : "";
        },
      },
    ];
    if (this.props.user.isAuthenticated === undefined) {
      return <LoadingModal />;
    }
    return (
      <div
        className="mx-auto"
        style={{ padding: "12px", display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            fontSize: "15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ScreenHeader
            title="News / Events"
            elementCount={this.state.tableData?.length}
            actions={
              <div style={{ display: "flex", gap: "12px" }}>
                <ChantButton
                  onClick={() => {
                    this.props.router.navigate("/admin/content/create");
                  }}
                >
                  Create News/Event
                </ChantButton>

                <Popconfirm
                  title="Refresh content?"
                  onConfirm={() => {
                    this.handleContentRefresh();
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <div className="icon-container" style={{ cursor: "pointer" }}>
                    <img
                      src={ReloadIcon}
                      alt="icon"
                      style={{ fontSize: "20px" }}
                    />
                  </div>
                </Popconfirm>
              </div>
            }
          />

          <div style={{ paddingTop: "2px" }}>
            <TabNav
              items={tabItems}
              activeKey={this.state.tab}
              handleTabChange={handleTabChange}
            />
          </div>

          <Table
            bordered
            columns={columns}
            loading={this.state.isLoading}
            bodyStyle={{
              fontSize: window.screen.width > 500 ? "18px" : "12px",
              backgroundColor: "#ffffff",
              // boxShadow: "0px 3px 10px 1px",
            }}
            dataSource={this.state.tableData}
            // scroll={
            //   window.screen.width > 500
            //     ? { x: 2150, y: "60vh" }
            //     : { x: 1450, y: "60vh" }
            // }
            pagination={{
              position: ["bottomLeft"],
              defaultPageSize: 100,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100],
            }}
            onChange={this.handleTableChange}
            rowKey="id"
            key="id"
            scroll={{ x: "max-content" }}
            // size="middle"
          />
        </div>

        <CopyEventModal
          open={this.state.showCopyEventModal}
          handleModalOpen={this.toggleCopyEventModal}
          saveDetail={this.handleSave}
          groupId={this.props.currentGroup}
          websiteConfig={this.state}
          currentContentId={this.state.currentContentId}
          primaryColor={primaryColor}
        />
      </div>
    );
  }

  fetchContents = (
    page,
    sorter = this.state.sorter,
    filters = this.state.filters
  ) => {
    let orderBy = sorter.columnKey;
    let order = sorter.order;
    orderBy = filters[orderBy] ? filters[orderBy][0] : orderBy;
    let skip = page ? (page - 1) * 10 : 0;

    this.setState({ isLoading: true });
    getContentsFromLocal(
      skip || 0,
      100,
      this.state.tab,
      this.props.currentGroup,
      orderBy,
      order === "ascend" ? "next" : "prev"
    ).then((data) => {
      this.setState((prevState) => ({ tableData: data, isLoading: false }));
      if (data?.length > 0) {
        this.props.dispatch(
          setGettingStartedItemStatus(
            this.props.currentGroup,
            GUIDE_ENGAGE,
            TOPIC_POST_EVENTS,
            true
          )
        );
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.adminData[`${this.state.tab}Contents`]?.records !==
        this.state.pagination.total &&
      this.props.adminData[`${this.state.tab}Contents`]?.records !== undefined
    ) {
      this.fetchContents(this.state.pagination.current);
      this.setState((prevState) => {
        let pagination = { ...prevState.pagination };
        pagination.total =
          this.props.adminData[`${this.state.tab}Contents`].records;
        pagination.defaultPageSize = 10;
        pagination.position = "top";
        return { pagination };
      });
    }

    if (
      this.props.adminData[`${this.state.tab}Contents`].records === undefined
    ) {
      this.props.dispatch(
        startAddContents(this.state.tab, this.props.currentGroup)
      );
    }

    if (
      this.props.currentGroup !== prevProps.currentGroup ||
      this.state.tab !== prevState.tab
    ) {
      this.props.dispatch(
        startAddContents(this.state.tab, this.props.currentGroup)
      );
      this.fetchContents(this.state.pagination.current);
      this.setState((prevState) => {
        let pagination = { ...prevState.pagination };
        pagination.total =
          this.props.adminData[`${this.state.tab}Contents`].records;
        pagination.defaultPageSize = 10;
        return { pagination };
      });
    }

    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.setState({ isLoading: true, pagination: {}, tableData: [] });
      this.changeHeaderColor();
    }
  }

  componentDidMount() {
    let config = localStorage.getItem("contentsConfiguration");
    if (config) {
      config = JSON.parse(config);
      this.fetchContents(
        config.pagination.current,
        config.sorter,
        config.filters
      );
      this.setState({ ...config, isLoading: false });
    } else {
      this.props.dispatch(
        startAddContents(this.state.tab, this.props.currentGroup)
      );
    }
    this.changeHeaderColor();
    localStorage.setItem(
      "contentsConfiguration",
      JSON.stringify({
        tab: this.state.tab,
        pagination: this.state.pagination,
        tableData: this.state.tableData,
        sorter: this.state.sorter,
        filters: this.state.filters,
      })
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(ListContent));
