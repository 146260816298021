import { ContentState, convertToRaw, EditorState } from "draft-js";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import ImageUploadButton from "./ImageUploaderButton";
import { db } from "../../../services/firebaseService/connection";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { connect } from "react-redux";
import { uploadImage } from "../../../services/firebaseService/endPoints/admin/members";
import { getWebsiteConfig } from "../../../services/firebaseService/endPoints/admin/website";
import { Spin } from "antd";
import { doc, setDoc } from "firebase/firestore";
import { postUpdateCDNUtil } from "../../../helperFunctions/util";

const fontSize = undefined;

class About extends React.Component {
  state = {
    overview: EditorState.createEmpty(),
    overviewImage: null,
    mission: EditorState.createEmpty(),
    missionImage: null,
    codeOfConduct: EditorState.createEmpty(),
    codeOfConductImage: null,
    ticketSummary: EditorState.createEmpty(),
    ticketImage: null,
    bylaws: EditorState.createEmpty(),
    imageFileKeys: [
      "overviewImage",
      "missionImage",
      "codeOfConductImage",
      "ticketImage",
      // , 'membershipSummaryImage'
    ],
    isSaving: false,
  };

  openLeaderSetupModal = () => {
    this.setState({
      leadershipModal: !this.state.leadershipModal,
    });
  };

  handleEditorStateChange = (state, name) => {
    this.setState({
      [name]: state,
    });
  };

  handleSave = (state) => {
    this.setState({
      leadership: {
        ...this.state.leadership,
        ...state.leadership,
      },
    });
  };

  saveToFirebase = () => {
    function delay(delayInms) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(2);
        }, delayInms);
      });
    }
    const save = async () => {
      this.setState({ isSaving: true });

      let currentState = this.state;

      let path = `${this.props.adminData.data.groupName}/website/`;
      let imageUploadPromises = [];

      let imageFileKeys = this.state.imageFileKeys;
      if (imageFileKeys) {
        for (let key of imageFileKeys) {
          if (Array.isArray(currentState[key])) {
            let images = currentState[key];
            let index = 0;
            for (let image of images) {
              if (image && (typeof image === "object") & !image.url) {
                imageUploadPromises.push(
                  uploadImage(
                    image,
                    `${path}about/${key}/${index + 1}`,
                    index
                  ).then(async ({ downloadUrl, index }) => {
                    let imageIndex = index;

                    currentState[key][imageIndex] = await downloadUrl;
                  })
                );
                await delay(1000);
              }
              index++;
            }
          } else if (key.includes(".")) {
            let keyParts = key.split(".");
            let file = currentState[keyParts[0]][keyParts[1]];

            if (file && typeof file === "object" && !file.url) {
              imageUploadPromises.push(
                uploadImage(file, `${path}about/${keyParts[0]}`)
                  .then(({ downloadUrl }) => downloadUrl)
                  .then((downloadUrl) => {
                    currentState[keyParts[0]][keyParts[1]] = downloadUrl;
                  })
              );
              await delay(1000);
            }
          } else {
            let image = currentState[key];
            if (image && typeof image === "object" && !image.url) {
              imageUploadPromises.push(
                uploadImage(image, `${path}about/${key}`)
                  .then(({ downloadUrl }) => downloadUrl)
                  .then((downloadUrl) => {
                    currentState[key] = downloadUrl;
                  })
              );
              await delay(1000);
            }
          }
        }
      }

      await Promise.all(imageUploadPromises);
      delete currentState.isSaving;
      delete currentState.imageFileKeys;

      let about = currentState;
      about.overview = draftToHtml(
        convertToRaw(about.overview.getCurrentContent())
      );
      about.mission = draftToHtml(
        convertToRaw(about.mission.getCurrentContent())
      );
      about.codeOfConduct = draftToHtml(
        convertToRaw(about.codeOfConduct.getCurrentContent())
      );
      about.ticketSummary = draftToHtml(
        convertToRaw(about.ticketSummary.getCurrentContent())
      );
      about.bylaws = draftToHtml(
        convertToRaw(about.bylaws.getCurrentContent())
      );

      let docRef = doc(db, "website", this.props.adminData.data.id);
      setDoc(docRef, { about }, { merge: true }).then(() => {
        this.fetchData();
        postUpdateCDNUtil(this.props.currentGroup);
        this.setState({ isSaving: false });
      });
    };
    save();
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <>
        <div
          className="border rounded"
          style={{
            backgroundColor: "#ffffff",
            // boxShadow: "0px 3px 10px 1px",
            marginTop: 30,
            padding: "15px 30px",
          }}
        >
          <div className=" p-2">
            <p style={{ fontSize: "20", fontWeight: "bold", marginBottom: 0 }}>
              About
            </p>
            <div className="mx-auto" style={{ width: "90%" }}>
              <div className="d-flex" style={{ flexWrap: "wrap" }}>
                <div style={{ flex: 1 }}>Overview</div>
                <div style={{ flex: 3 }}>
                  <div className="form-group col">
                    <div
                      style={{ border: "1px solid #ced4da", borderRadius: 5 }}
                    >
                      <Editor
                        editorState={this.state.overview}
                        onEditorStateChange={(state) =>
                          this.handleEditorStateChange(state, "overview")
                        }
                        toolbar={{
                          options: ["inline", "list", "link", "fontSize"],
                          inline: {
                            inDropdown: false,
                            options: ["bold", "italic", "underline"],
                          },
                          list: {
                            inDropdown: false,
                            options: ["unordered", "ordered"],
                          },
                          fontSize: {
                            icon: fontSize,
                            options: [
                              8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60,
                              72, 96,
                            ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          link: {
                            inDropdown: false,
                            defaultTargetOption: "_self",
                            options: ["link", "unlink"],
                            linkCallback: undefined,
                          },
                        }}
                        stripPastedStyles={true}
                        tabIndex="2"
                      />
                    </div>
                  </div>
                  <div className="form-group col">
                    <ImageUploadButton
                      text="Add Image"
                      aspectRatio={1}
                      images={[this.state.overviewImage]}
                      onSave={(images) => {
                        this.setState({
                          overviewImage: images[0],
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex" style={{ flexWrap: "wrap" }}>
                <div style={{ flex: 1 }}>Mission</div>
                <div style={{ flex: 3 }}>
                  <div className="form-group col">
                    <div
                      style={{ border: "1px solid #ced4da", borderRadius: 5 }}
                    >
                      <Editor
                        editorState={this.state.mission}
                        onEditorStateChange={(state) =>
                          this.handleEditorStateChange(state, "mission")
                        }
                        toolbar={{
                          options: ["inline", "list", "link", "fontSize"],
                          inline: {
                            inDropdown: false,
                            options: ["bold", "italic", "underline"],
                          },
                          fontSize: {
                            icon: fontSize,
                            options: [
                              8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60,
                              72, 96,
                            ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          list: {
                            inDropdown: false,
                            options: ["unordered", "ordered"],
                          },
                          link: {
                            inDropdown: false,
                            defaultTargetOption: "_self",
                            options: ["link", "unlink"],
                            linkCallback: undefined,
                          },
                        }}
                        stripPastedStyles={true}
                        tabIndex="2"
                      />
                    </div>
                  </div>
                  <div className="form-group col">
                    <ImageUploadButton
                      text="Add Image"
                      aspectRatio={1}
                      images={[this.state.missionImage]}
                      onSave={(images) => {
                        this.setState({
                          missionImage: images[0],
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex" style={{ flexWrap: "wrap" }}>
                <div style={{ flex: 1 }}>Code of Conduct</div>
                <div style={{ flex: 3 }}>
                  <div className="form-group col">
                    <div
                      style={{ border: "1px solid #ced4da", borderRadius: 5 }}
                    >
                      <Editor
                        editorState={this.state.codeOfConduct}
                        onEditorStateChange={(state) =>
                          this.handleEditorStateChange(state, "codeOfConduct")
                        }
                        toolbar={{
                          options: ["inline", "list", "link", "fontSize"],
                          inline: {
                            inDropdown: false,
                            options: ["bold", "italic", "underline"],
                          },
                          fontSize: {
                            icon: fontSize,
                            options: [
                              8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60,
                              72, 96,
                            ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          list: {
                            inDropdown: false,
                            options: ["unordered", "ordered"],
                          },
                          link: {
                            inDropdown: false,
                            defaultTargetOption: "_self",
                            options: ["link", "unlink"],
                            linkCallback: undefined,
                          },
                        }}
                        stripPastedStyles={true}
                        tabIndex="2"
                      />
                    </div>
                  </div>
                  <div className="form-group col">
                    <ImageUploadButton
                      text="Add Image"
                      aspectRatio={1}
                      images={[this.state.codeOfConductImage]}
                      onSave={(images) => {
                        this.setState({
                          codeOfConductImage: images[0],
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex" style={{ flexWrap: "wrap" }}>
                <div style={{ flex: 1 }}>Bylaws and Minutes</div>
                <div style={{ flex: 3 }}>
                  <div className="form-group col">
                    <div
                      style={{ border: "1px solid #ced4da", borderRadius: 5 }}
                    >
                      <Editor
                        editorState={this.state.bylaws}
                        onEditorStateChange={(state) =>
                          this.handleEditorStateChange(state, "bylaws")
                        }
                        toolbar={{
                          options: ["inline", "list", "link", "fontSize"],
                          inline: {
                            inDropdown: false,
                            options: ["bold", "italic", "underline"],
                          },
                          fontSize: {
                            icon: fontSize,
                            options: [
                              8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60,
                              72, 96,
                            ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          list: {
                            inDropdown: false,
                            options: ["unordered", "ordered"],
                          },
                          link: {
                            inDropdown: false,
                            defaultTargetOption: "_self",
                            options: ["link", "unlink"],
                            linkCallback: undefined,
                          },
                        }}
                        stripPastedStyles={true}
                        tabIndex="2"
                      />
                    </div>
                  </div>
                  {/* <div className="form-group col">
                    <ImageUploadButton
                      text="Add Image"
                      aspectRatio={1}
                      images={[this.state.codeOfConductImage]}
                      onSave={(images) => {
                        this.setState({
                          codeOfConductImage: images[0],
                        });
                      }}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 p-2">
            <p style={{ fontSize: "20", fontWeight: "bold", marginBottom: 0 }}>
              Tickets
            </p>
            <div className="mx-auto" style={{ width: "90%" }}>
              <div className="d-flex" style={{ flexWrap: "wrap" }}>
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: 0, marginTop: 27 }}>Summary</p>
                </div>
                <div style={{ flex: 3 }}>
                  <div className="form-group col">
                    <div
                      style={{ border: "1px solid #ced4da", borderRadius: 5 }}
                    >
                      <Editor
                        editorState={this.state.ticketSummary}
                        onEditorStateChange={(state) =>
                          this.handleEditorStateChange(state, "ticketSummary")
                        }
                        toolbar={{
                          options: ["inline", "list", "link", "fontSize"],
                          inline: {
                            inDropdown: false,
                            options: ["bold", "italic", "underline"],
                          },
                          fontSize: {
                            icon: fontSize,
                            options: [
                              8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60,
                              72, 96,
                            ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          list: {
                            inDropdown: false,
                            options: ["unordered", "ordered"],
                          },
                          link: {
                            inDropdown: false,
                            defaultTargetOption: "_self",
                            options: ["link", "unlink"],
                            linkCallback: undefined,
                          },
                        }}
                        stripPastedStyles={true}
                        tabIndex="2"
                      />
                    </div>
                  </div>
                  <div className="form-group col">
                    <ImageUploadButton
                      text="Add Image"
                      aspectRatio={1}
                      images={[this.state.ticketImage]}
                      onSave={(images) => {
                        this.setState({
                          ticketImage: images[0],
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="p-2">
              <button
                style={{
                  backgroundColor: primaryColor,
                  borderRadius: 5,
                  border: "0px none",
                  boxShadow: "transparent 0px 0px 0px",
                  outline: "none",
                  padding: "5px 10px",
                  width: "200px",
                }}
                className="default-text-color"
                disabled={this.state.isSaving}
                onClick={this.saveToFirebase}
              >
                {this.state.isSaving ? (
                  <Spin size="small" spinning={true} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  getState(websiteConfig) {
    if (websiteConfig && websiteConfig.about) {
      let overview = EditorState.createEmpty();
      let mission = EditorState.createEmpty();
      let codeOfConduct = EditorState.createEmpty();
      let bylaws = EditorState.createEmpty();
      let membershipSummary = EditorState.createEmpty();
      let ticketSummary = EditorState.createEmpty();
      let overviewImage = "";
      let missionImage = "";
      let codeOfConductImage = "";
      let ticketImage = "";

      if (websiteConfig.about.overview) {
        let overviewDraft = htmlToDraft(websiteConfig.about.overview);
        if (overviewDraft) {
          let contentState = ContentState.createFromBlockArray(
            overviewDraft.contentBlocks
          );
          let editorState = EditorState.createWithContent(contentState);
          overview = editorState;
        }
        overviewImage = websiteConfig.about.overviewImage;
      }
      if (websiteConfig.about.mission) {
        let missionDraft = htmlToDraft(websiteConfig.about.mission);
        if (missionDraft) {
          let contentState = ContentState.createFromBlockArray(
            missionDraft.contentBlocks
          );
          let editorState = EditorState.createWithContent(contentState);
          mission = editorState;
        }
        missionImage = websiteConfig.about.missionImage;
      }
      if (websiteConfig.about.codeOfConduct) {
        let codeOfConductDraft = htmlToDraft(websiteConfig.about.codeOfConduct);
        if (codeOfConductDraft) {
          let contentState = ContentState.createFromBlockArray(
            codeOfConductDraft.contentBlocks
          );
          let editorState = EditorState.createWithContent(contentState);
          codeOfConduct = editorState;
        }
        codeOfConductImage = websiteConfig.about.codeOfConductImage;
      }

      if (websiteConfig.about.bylaws) {
        let bylawsDraft = htmlToDraft(websiteConfig.about.bylaws);
        if (bylawsDraft) {
          let contentState = ContentState.createFromBlockArray(
            bylawsDraft.contentBlocks
          );
          let editorState = EditorState.createWithContent(contentState);
          bylaws = editorState;
        }
      }
      if (websiteConfig.about.ticketSummary) {
        let ticketSummaryDraft = htmlToDraft(websiteConfig.about.ticketSummary);
        if (ticketSummaryDraft) {
          let contentState = ContentState.createFromBlockArray(
            ticketSummaryDraft.contentBlocks
          );
          let editorState = EditorState.createWithContent(contentState);
          ticketSummary = editorState;
        }
        ticketImage = websiteConfig.about.ticketImage;
      }

      return {
        overview,
        mission,
        bylaws,
        codeOfConduct,
        ticketSummary,
        ticketImage,
        overviewImage,
        missionImage,
        codeOfConductImage,
      };
    }
    return {
      overview: EditorState.createEmpty(),
      mission: EditorState.createEmpty(),
      bylaws: EditorState.createEmpty(),
      codeOfConduct: EditorState.createEmpty(),
      membershipSummary: EditorState.createEmpty(),
      ticketSummary: EditorState.createEmpty(),
    };
  }

  fetchData() {
    getWebsiteConfig(this.props.currentGroup).then((data) => {
      this.setState({ ...this.getState(data) });
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentGroup !== this.props.currentGroup) {
      this.fetchData();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
  };
};

export default connect(mapStateToProps)(About);
