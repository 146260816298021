import React from "react";
import "./TabNav.css";
import { Tabs } from "antd";

const TabNav = ({ items, activeKey, handleTabChange }) => {
  const onChange = (key) => {
    handleTabChange(key);
  };

  return (
    <div className="tab-nav-container">
      <Tabs
        defaultActiveKey="1"
        activeKey={activeKey}
        items={items}
        onChange={onChange}
      />
    </div>
  );
};

export default TabNav;
