import { Checkbox, DatePicker, Form, Input, InputNumber, Select } from "antd";
import { useState } from "react";
import useGlobalStore from "../../../../../../store/store";
import { useSelector } from "react-redux";
import { selectCurrentGroupData } from "../../../../../../redux/selectors/adminData";
import usePackages from "../../../../../hooks/usePackages";
import { countries } from "countries-list";
import dayjs from "dayjs";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  writeBatch,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import { db } from "../../../../../../services/firebaseService/connection";
import { toast } from "react-toastify";
import { fetchMembers } from "../../../../../../services/api/members";
import {
  fetchFormLayout,
  fetchPackages,
} from "../../../../../../services/api/packages";

const useAddMemberForm = (handleAddMemberFormModal) => {
  const [form] = Form.useForm();
  const [selectedMonth, setSelectedMonth] = useState(null);
  const { formLayout } = useGlobalStore((state) => state.formLayout);
  const groupData = useSelector(selectCurrentGroupData);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleMemberRefresh = () => {
    fetchMembers(groupData.id);
    fetchPackages(groupData.id);
    fetchFormLayout(groupData.id);
  };

  const { packagesBySeason } = usePackages(groupData?.id);

  const countryNames = Object.values(countries).map((country) => ({
    name: country.name,
  }));

  const years = Array.from({ length: 20 }, (_, index) => ({
    year: `${dayjs().year() - index}`,
  }));

  const userRoles = [
    "admin",
    "member",
    "ticket-scanner",
    "capo",
    "leader",
    "fan",
  ];

  const Months = {
    Jan: 31,
    Feb: 29,
    Mar: 31,
    Apr: 30,
    May: 31,
    Jun: 30,
    Jul: 31,
    Aug: 31,
    Sep: 30,
    Oct: 31,
    Nov: 30,
    Dec: 31,
  };

  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      const values = await form.validateFields();

      // Sanitize and validate the data
      const sanitizedValues = Object.entries(values).reduce(
        (acc, [key, value]) => {
          if (value !== undefined && value !== null) {
            // Handle nested objects
            if (typeof value === "object") {
              // Remove empty objects and objects with only undefined/null values
              const sanitizedObj = Object.entries(value).reduce(
                (objAcc, [k, v]) => {
                  if (v !== undefined && v !== null) {
                    objAcc[k] = v;
                  }
                  return objAcc;
                },
                {}
              );

              // Only add the object if it has properties
              if (Object.keys(sanitizedObj).length > 0) {
                acc[key] = sanitizedObj;
              }
            } else {
              acc[key] = value;
            }
          }
          return acc;
        },
        {}
      );

      // Ensure required fields are present
      if (!sanitizedValues.email || !sanitizedValues.fullName) {
        throw new Error("Required fields are missing");
      }

      let finalData = { ...sanitizedValues };

      // Handle package data safely
      if (sanitizedValues.package) {
        try {
          const packageDetails = sanitizedValues.package.split("|");
          if (packageDetails.length === 2) {
            const [packageId, packageSeason] = packageDetails;
            if (packageId && packageSeason) {
              finalData = {
                ...finalData,
                package: packageId,
                packageSeason: packageSeason,
              };
            }
          }
        } catch (error) {
          console.error("Error processing package details:", error);
        }
      }

      const docRef = doc(db, "invite_emails", groupData.id);
      const docSnapshot = await getDoc(docRef);

      if (!docSnapshot.exists()) {
        return;
      }

      const inviteEmailsData = docSnapshot.data();
      const emails = inviteEmailsData.emails || [];
      const emailIndex = emails.indexOf(finalData.email);

      const membersRef = collection(db, "userGroup", groupData.id, "members");
      const q = query(membersRef, where("email", "==", finalData.email));
      const querySnapshot = await getDocs(q);

      // Check if member with this email already exists in userGroup
      if (!querySnapshot.empty) {
        toast.error("A member with this email already exists in the group");
        return;
      }

      // Check if member with this email already exists in invite_emails
      if (emailIndex !== -1) {
        toast.error("A member with this email has already been invited!");
        return;
      }

      const batch = writeBatch(db);

      // Add email to the emails array
      batch.update(docRef, {
        emails: arrayUnion(finalData.email),
      });

      // Create new user document
      const userDetailsRef = collection(
        db,
        "invite_emails",
        groupData?.id,
        "userDetails"
      );
      const newUserDoc = doc(userDetailsRef);

      const safeFinalData = Object.entries(finalData).reduce(
        (acc, [key, value]) => {
          if (value !== undefined && value !== null) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      batch.set(newUserDoc, safeFinalData);

      try {
        await batch.commit();
        form.resetFields();
        handleAddMemberFormModal(false);
        toast.success("User added successfully!");
        handleMemberRefresh();
      } catch (error) {
        toast.error("Failed to add user. Please try again.");
      }
    } catch (error) {
      console.error("Form submission error:", error);
      if (error.errorFields) {
        const firstErrorField = error.errorFields?.[0]?.name?.[0];
        if (firstErrorField) {
          form.scrollToField(firstErrorField, {
            behavior: "smooth",
            block: "center",
          });
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    handleAddMemberFormModal(false);
  };

  const getCustomField = (
    { fieldType, fieldTitle, fieldHelp, dropdownValues = [] },
    key,
    form
  ) => {
    const baseProps = {
      placeholder: fieldHelp || `Enter ${fieldTitle?.toLowerCase()}`,
      style: { width: "100%" },
    };

    const renderField = () => {
      switch (fieldType) {
        case "text":
          return <Input {...baseProps} />;

        case "number":
          return <InputNumber {...baseProps} />;

        case "checkbox":
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                defaultChecked={false}
                onChange={(e) => {
                  // Set the form field value explicitly to false when unchecked
                  form.setFieldValue(key, e.target.checked);
                }}
              />
            </div>
          );

        case "dropdown":
          return (
            <Select {...baseProps}>
              {dropdownValues.map((value) => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          );

        case "date":
          return <DatePicker {...baseProps} format="YYYY-MM-DD" />;

        case "textarea":
          return <Input.TextArea {...baseProps} rows={4} />;

        default:
          return <Input {...baseProps} />;
      }
    };

    return (
      <Form.Item
        label={<span className="form-label">{fieldTitle}</span>}
        name={key}
      >
        {renderField()}
      </Form.Item>
    );
  };

  return {
    form,
    years,
    Months,
    userRoles,
    countryNames,
    selectedMonth,
    submitLoading,
    packagesBySeason,
    formLayout: formLayout?.[groupData?.id],
    handleSubmit,
    handleCancel,
    getCustomField,
    setSelectedMonth,
  };
};

export default useAddMemberForm;
