import React from "react";

const StoreSettingsSelect = ({
  name,
  onChange,
  tabIndex = 0,
  value,
  placeholder,
  dataList = [],
  listBuilder,
  dataOnlyValue,
  dataKey,
  dataValue,
}) => {
  return (
    <select
      className="form-control form-control-sm"
      style={{
        border: "1px solid",
        borderRadius: "3px",
        boxShadow: "0px 0px 1px 1px #cccccc",
      }}
      name={name}
      onChange={onChange}
      tabIndex={tabIndex}
      value={value}
    >
      <option key="0" value="">
        {placeholder}
      </option>
      {dataList.length > 0 &&
        dataList.map((data) =>
          listBuilder ? (
            listBuilder(data)
          ) : (
            <option
              key={dataOnlyValue ? data : data[dataKey]}
              value={dataOnlyValue ? data : data[dataKey]}
            >
              {dataOnlyValue ? data : data[dataValue]}
            </option>
          )
        )}
    </select>
  );
};

export default StoreSettingsSelect;
