const facebookAppId = import.meta.env.VITE_FACEBOOK_APP_ID;

export function initFacebookSdk() {
  return new Promise((resolve) => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: "v8.0",
      });
      resolve();
    };

    // load facebook sdk script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}

export function checkLoginStatus() {
  return new Promise((resolve, reject) => {
    window.FB.getLoginStatus(function (response) {
      let responseObj = {
        status: response.status,
      };
      if (response.status === "connected") {
        var uid = response.authResponse.userID;
        var accessToken = response.authResponse.accessToken;

        responseObj = {
          ...responseObj,
          uid,
          accessToken,
        };
      }
      resolve(responseObj);
    });
  });
}

export function loginWithFacebook() {
  return new Promise((resolve, reject) => {
    window.FB.login(function (response) {
      resolve(response);
    });
  });
}
