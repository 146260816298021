import React, { useEffect } from "react";
import { Slide, toast, ToastContainer } from "react-toastify";
import { selectCurrentGroupData } from "../../../redux/selectors/adminData";
import { useSelector } from "react-redux";
import ChantButton from "../../../CommonComponents/ChantButton/ChantButton";
import { useNavigate } from "react-router-dom";
import "./News.css";

const News = () => {
  const groupData = useSelector(selectCurrentGroupData);

  const navigate = useNavigate();

  useEffect(() => {
    if (groupData?.registrationLink?.startsWith("https://chant.page.link/")) {
      toast(
        <div>
          <p style={{ marginBottom: "4px", fontSize: "14px" }}>
            Required: Please update the QR / Registration link in
            Members/Onboard/Option #4. The current code will no longer work for
            onboarding.
          </p>
          <ChantButton
            onClick={() => {
              toast.dismiss();
              navigate("/admin/members/onboard");
            }}
          >
            Continue
          </ChantButton>
        </div>,
        {
          position: "top-center",
          autoClose: false,
          className: "wide-toast",
          closeButton: ({ closeToast }) => (
            <span
              onClick={closeToast}
              style={{
                // color: "black",
                padding: "6px",
                cursor: "pointer",
              }}
            >
              ✕
            </span>
          ),
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData?.registrationLink]);

  return (
    <ToastContainer
      position="top-right"
      closeButton
      autoClose={5000}
      newestOnTop={true}
      theme="colored"
      transition={Slide}
      //   closeButton={(close) => <button onClick={close}>X</button>}
    />
  );
};

export default News;
