import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectTheme } from "../../redux/selectors/app";
import { selectCurrentGroupData } from "../../redux/selectors/adminData";
import { setThemeColors } from "../../redux/actions/app";

const ThemeProvider = ({ children }) => {
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);
  const adminData = useSelector(selectCurrentGroupData);

  const darkenRGBColor = ({ red, green, blue }, darkenAmount) => {
    const amount = Math.min(Math.max(darkenAmount, 0), 1);

    const r = Math.max(0, Math.floor(parseInt(red, 10) * (1 - amount)));
    const g = Math.max(0, Math.floor(parseInt(green, 10) * (1 - amount)));
    const b = Math.max(0, Math.floor(parseInt(blue, 10) * (1 - amount)));

    return `rgb(${r}, ${g}, ${b})`;
  };

  const lightenRGBColor = ({ red, green, blue }, lightenAmount) => {
    const amount = Math.min(Math.max(lightenAmount, 0), 1);

    const r = Math.min(
      255,
      Math.floor(parseInt(red, 10) + (255 - parseInt(red, 10)) * amount)
    );
    const g = Math.min(
      255,
      Math.floor(parseInt(green, 10) + (255 - parseInt(green, 10)) * amount)
    );
    const b = Math.min(
      255,
      Math.floor(parseInt(blue, 10) + (255 - parseInt(blue, 10)) * amount)
    );

    return `rgb(${r}, ${g}, ${b})`;
  };

  const isLightColor = ({ r, g, b }) => {
    const luminance =
      0.2126 * parseInt(r) + 0.7152 * parseInt(g) + 0.0722 * parseInt(b);
    return luminance > 200;
  };

  useEffect(() => {
    let primaryColor = adminData?.configuration?.primaryColor;

    if (primaryColor) {
      const { r, g, b } = primaryColor;

      const primaryTextColor = isLightColor({ r, g, b })
        ? "rgb(0, 0, 0)"
        : "rgb(255, 255, 255)";

      dispatch(
        setThemeColors({
          "primary-background": `rgb(${r}, ${g}, ${b})`,
          "primary-background-dark": darkenRGBColor(
            {
              red: r,
              green: g,
              blue: b,
            },
            0.15
          ),
          "primary-background-darker": darkenRGBColor(
            {
              red: r,
              green: g,
              blue: b,
            },
            0.2
          ),
          "primary-background-light": lightenRGBColor(
            {
              red: r,
              green: g,
              blue: b,
            },
            0.9
          ),
          "primary-text-color": primaryTextColor,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminData?.configuration?.primaryColor]);

  useEffect(() => {
    // Update CSS variables when theme changes
    Object.entries(theme).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
  }, [theme]);

  return <>{children}</>;
};

export default ThemeProvider;
