import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import ScreenHeader from "../../../../CommonComponents/ScreenHeader";
import DescriptionText from "../../../../CommonComponents/DescriptionText";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../services/firebaseService/connection";
import { getDayjsFromTimestamp } from "../../../../utils/helper";
import { getCurrencyUnicode } from "../../../../helperFunctions/util";
import DefaultUserImage from "../../../../images/default-profile-pic.png";

import logger from "../../../../utils/logger";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Table, Tooltip } from "antd";
import useGlobalStore from "../../../../store/store";

const AdminGiveaways = () => {
  const [allGiveaways, setAllGiveaways] = useState([]);
  const [loading, setLoading] = useState(false);

  const groupData = useSelector(selectCurrentGroupData);

  const { setSelectedGiveawayDetails } = useGlobalStore.getState().giveaways;

  let groupCurrencyISO = groupData.paymentDetails
    ? groupData.paymentDetails.currency || "USD"
    : "USD";
  let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

  const navigate = useNavigate();

  const handleViewPollDetails = (data) => {
    setSelectedGiveawayDetails(data);
    navigate(`/admin/content/giveaways/${data?.id}`);
  };

  const columns = [
    {
      title: "Actions",
      width: 100,
      align: "center",
      render: (data) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data && data.id && (
              <i
                className="ml-3"
                style={{ cursor: "pointer" }}
                onClick={() => handleViewPollDetails(data)}
              >
                <EyeOutlined />
              </i>
            )}
          </div>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      className: "header-white",
      dataIndex: "status",
      width: 100,
    },
    {
      title: "Giveaway",
      key: "giveaway",
      className: "header-white",
      dataIndex: "giveaway",
      width: 200,
    },
    {
      title: "Winners",
      key: "winners",
      className: "header-white",
      dataIndex: "winners",
      width: 200,
      render: (data) =>
        data.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {data.map((item, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                <img
                  src={item.image}
                  alt="Giveaway image"
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop
                    e.target.src = DefaultUserImage;
                  }}
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 50,
                    objectFit: "cover",
                  }}
                />
                {item.name}
              </div>
            ))}
          </div>
        ) : null,
    },
    {
      title: "Raised/Goal",
      key: "raised",
      className: "header-white",
      width: 150,
      render: (data) => {
        if (data?.goalAmount === 0) return "";

        const formatAmount = (amount) => (amount === 0 ? "0" : (amount ?? "-"));

        return `${groupCurrency}${formatAmount(data?.receivedAmount)} / ${groupCurrency}${data?.goalAmount}`;
      },
    },
    {
      title: "# Entrants",
      key: "entrantsCount",
      className: "header-white",
      dataIndex: "entrantsCount",
      width: 100,
    },
    {
      title: "# Winners",
      key: "winnersCount",
      className: "header-white",
      dataIndex: "winnersCount",
      width: 100,
    },
    {
      title: "Proceeds Support",
      key: "proceedsSupport",
      className: "header-white",
      dataIndex: "proceedsSupport",
      width: 150,
      ellipsis: true,
      render: (data, record) => {
        const img = `https://firebasestorage.googleapis.com/v0/b/chant2019.appspot.com/o/${groupData?.id}%2Fpartner%2F${record?.partnerId}%2Flogo?alt=media`;

        return (
          <>
            {record?.partnerId ? (
              <div className="flex-center">
                <img
                  src={img}
                  alt={data?.name}
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                  }}
                />
              </div>
            ) : (
              <span>{data?.name}</span>
            )}
          </>
        );
      },
    },
    {
      title: "Donated By",
      key: "sponsor",
      className: "header-white",
      dataIndex: "sponsor",
      width: 150,
      render: (data) => {
        return data?.image ? (
          <div className="flex-center">
            <img
              src={data?.image}
              alt="sponsor"
              style={{
                width: "50px",
                height: "50px",
                objectFit: "cover",
              }}
            />
          </div>
        ) : (
          <span>{data?.name}</span>
        );
      },
    },
    {
      title: "Post Date",
      key: "postDate",
      className: "header-white",
      dataIndex: "postDate",
      width: 150,
      render: (data) =>
        getDayjsFromTimestamp(data)
          ? getDayjsFromTimestamp(data).format("Do MMM, YYYY")
          : "",
    },
    {
      title: "Close Date",
      key: "closedDate",
      className: "header-white",
      dataIndex: "closedDate",
      width: 150,
      render: (data) =>
        getDayjsFromTimestamp(data)
          ? getDayjsFromTimestamp(data).format("Do MMM, YYYY")
          : "",
    },
  ];

  const fetchGiveaways = async () => {
    try {
      setLoading(true);

      // Reference to the collection
      const giveawaysRef = collection(db, `giveaways/${groupData?.id}/events`);
      const giveawaysSnapshot = await getDocs(giveawaysRef);

      const giveaways = giveawaysSnapshot.docs.map((pollDoc) => {
        const giveawaysData = pollDoc.data();

        let status;
        if (giveawaysData.isDraft) {
          status = "Draft";
        } else if (giveawaysData.isLive) {
          status = "Live";
        } else if (giveawaysData.isCompleted) {
          status = "Closed";
        }

        const giveawayCloseDate =
          giveawaysData.winnersList && giveawaysData.winnersList.length > 0
            ? giveawaysData.winnersList.find(
                (item) => item.addedOn && item.addedOn > 0
              )?.addedOn || ""
            : "";

        return {
          id: pollDoc.id,
          status: status,
          partnerId: giveawaysData?.partnerId,
          postDate: giveawaysData?.addedOn,
          closedDate: giveawayCloseDate,
          giveaway: giveawaysData?.itemName,
          winnersCount: giveawaysData?.winnersList?.length || 0,
          entrantsCount: giveawaysData?.noOfParticipants || 0,
          goalAmount: giveawaysData?.goalAmount || 0,
          receivedAmount: giveawaysData?.receivedAmount || 0,
          sponsor: {
            name: giveawaysData?.donatedByText,
            image: giveawaysData?.donatedByImage,
          },
          proceedsSupport: {
            name: giveawaysData?.proceedsSupportName,
            image: giveawaysData?.proceedsSupportImage,
          },
          winners:
            giveawaysData?.winnersList?.map((item) => ({
              name: item?.userName || "",
              image: item?.userImage || "",
            })) || [],
        };
      });

      giveaways.sort((a, b) => (b.postDate || 0) - (a.postDate || 0));

      setAllGiveaways(giveaways);
    } catch (error) {
      logger.error("Error fetching giveaways:", error);
      setAllGiveaways([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (groupData?.id) {
      fetchGiveaways();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData?.id]);

  return (
    <div style={{ padding: "12px" }}>
      <ScreenHeader title="Giveaways" elementCount={allGiveaways?.length} />
      <div className="admin-poll-description">
        <DescriptionText>
          Create and run giveaways from the app. Groups with Stripe can sell
          'additional entries' (local laws permitting). Feature 'Donated by' and
          'Proceeds support' partners.
        </DescriptionText>
      </div>
      <Table
        bordered
        columns={columns}
        loading={loading}
        dataSource={allGiveaways}
        pagination={{
          position: ["bottomLeft"],
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
        rowKey="id"
        key="id"
        scroll={{ x: 1600 }}
      />
    </div>
  );
};

export default AdminGiveaways;
