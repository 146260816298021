// Lightbox.jsx
import {
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { downloadImage } from "../../services/firebaseService/endPoints/clubAdmin/gallery";
import { Popconfirm, Spin } from "antd";

const Lightbox = ({
  images,
  lightboxOpen,
  setLightboxOpen,
  setCurrentImageIndex,
  currentImageIndex,
  imgDeleteLoading,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadedImages, setLoadedImages] = useState({});

  useEffect(() => {
    if (!lightboxOpen) return;

    const preloadImage = (index) => {
      const imageToLoad = images[index]?.srcLg || images[index]?.src;
      if (!imageToLoad || loadedImages[imageToLoad]) return;

      const img = new Image();
      img.src = imageToLoad;
      img.onload = () => {
        setLoadedImages((prev) => ({ ...prev, [imageToLoad]: true }));
      };
    };

    const nextIndex =
      currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1;
    const prevIndex =
      currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1;

    preloadImage(nextIndex);
    preloadImage(prevIndex);
  }, [currentImageIndex, images, lightboxOpen, loadedImages]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!lightboxOpen) return;

      switch (e.key) {
        case "ArrowLeft":
          navigateImages("prev");
          break;
        case "ArrowRight":
          navigateImages("next");
          break;
        case "Escape":
          setLightboxOpen(false);
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lightboxOpen, setLightboxOpen, setCurrentImageIndex, images.length]);

  const navigateImages = (direction) => {
    setIsLoading(true);
    if (direction === "next") {
      setCurrentImageIndex((prev) =>
        prev === images.length - 1 ? 0 : prev + 1
      );
    } else {
      setCurrentImageIndex((prev) =>
        prev === 0 ? images.length - 1 : prev - 1
      );
    }
    setTimeout(() => setIsLoading(false), 100);
  };

  if (!lightboxOpen) return null;

  const currentImageSrc =
    images[currentImageIndex]?.srcLg || images[currentImageIndex]?.src;

  const handleDownload = () => {
    downloadImage(currentImageSrc);
  };

  return (
    <div className="lightbox">
      {!imgDeleteLoading && (
        <div className="lightbox-actions">
          <button onClick={handleDownload} className="lightbox-download">
            <DownloadOutlined />
          </button>

          <Popconfirm
            title="Delete image?"
            onConfirm={(e) => {
              e.stopPropagation();
              if (images[currentImageIndex].onDelete)
                images[currentImageIndex].onDelete();
            }}
            onCancel={(e) => e.stopPropagation()}
            okButtonProps={{
              loading: imgDeleteLoading,
              disabled: imgDeleteLoading,
            }}
            cancelButtonProps={{ disabled: imgDeleteLoading }}
            okText="Yes"
            cancelText="No"
          >
            <button className="lightbox-delete">
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      )}
      <button onClick={() => setLightboxOpen(false)} className="lightbox-close">
        <CloseOutlined />
      </button>

      <button
        onClick={() => navigateImages("prev")}
        className="lightbox-nav lightbox-prev"
      >
        <LeftOutlined />
      </button>

      <div className="lightbox-image-container">
        {imgDeleteLoading ? (
          <div
            className="flex-center"
            style={{ height: "400px", width: "100%" }}
          >
            <Spin />
          </div>
        ) : (
          <img
            key={currentImageSrc}
            src={currentImageSrc}
            alt={`expanded view ${currentImageIndex + 1}`}
            className={`lightbox-image ${isLoading ? "loading" : ""}`}
            onLoad={() => setIsLoading(false)}
          />
        )}
        {isLoading && <div className="loading-spinner"></div>}
      </div>

      <button
        onClick={() => navigateImages("next")}
        className="lightbox-nav lightbox-next"
      >
        <RightOutlined />
      </button>
    </div>
  );
};

export default Lightbox;
