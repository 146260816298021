import React from "react";
import FUpload from "../../commons/formFields/FUpload";
import imageCompression from "browser-image-compression";
import { Tooltip } from "antd";

class ImageUploadButton extends React.Component {
  state = {
    images: [],
    imageList: Array.from({ length: this.props.count || 1 }, () => []),
    savingImage: false,
    editConfiguration: {},
  };

  uploadAction = (file, fileList, index) => {
    let images = this.state.images;
    images[index] = file;
    this.setState({ images });
  };

  handleImageChange = async ({ fileList }, index) => {
    this.setState({
      savingImage: true,
    });
    let result = {};
    result.images = this.state.images;
    if (fileList.length === 0) {
      result.images[index] = "";
    } else {
      const options = {
        maxSizeMB: 3,
        maxWidthOrHeight: 2560,
        useWebWorker: true,
        alwaysKeepResolution: true,
        preserveExif: true,
      };
      let compressedImage = await imageCompression(
        fileList[0].originFileObj,
        options
      );
      result.images[index] = compressedImage;
    }

    if (!this.state.editConfiguration.imageChanged) {
      this.setState((prevState) => {
        let editConfiguration = Object.assign({}, prevState.editConfiguration);
        editConfiguration.imageChanged = true;
        return {
          editConfiguration,
        };
      });
    }
    result.imageList = [];
    result.imageList[index] = fileList;

    this.setState(result, () => {
      // Call onSave after state is updated with compressed image
      this.props.onSave && this.props.onSave(this.state.images);
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.images &&
      props.images.length > 0 &&
      !state.editConfiguration.imageChanged
    ) {
      let imageList = props.images.map((url, index) => {
        if (url) {
          return [{ uid: -1 * index - 1, url }];
        }
        return null;
      });

      imageList = imageList.filter((image) => image);
      return { imageList };
    }
    return null;
  }

  render() {
    const {
      count = 1,
      aspectRatio,
      disabled,
      label,
      tooltip,
      required,
      helperText,
    } = this.props;

    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {label && (
          <div className="col-md-4">
            <label>
              {label}
              {required && <span style={{ color: "red" }}> * </span>}
              {tooltip && (
                <Tooltip
                  placement="topLeft"
                  title={<div style={{ width: 400 }}>{tooltip}</div>}
                >
                  <span className="border-0 m-0 p-0 ml-2">
                    <i className="fa fa-question-circle-o" />
                  </span>
                </Tooltip>
              )}
            </label>
          </div>
        )}
        <div className={!label ? "col d-flex" : "d-flex"}>
          <div
            className="upload-button"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {Array.from({ length: count }, (_, index) => (
              <FUpload
                key={index}
                action={(file, fileList) =>
                  this.uploadAction(file, fileList, index)
                }
                fileList={this.state.imageList[index] || []}
                onChange={({ fileList }) =>
                  this.handleImageChange({ fileList }, index)
                }
                aspectRatio={aspectRatio}
                display="col"
                isAvatar={false}
                viewMode={false}
                onRemove={this.props.onDelete}
                disabled={disabled}
              />
            ))}
          </div>
        </div>
        {helperText && (
          <div
            className="flex-center"
            style={{ fontSize: "14px", fontWeight: "400", paddingLeft: "8px" }}
          >
            {helperText}
          </div>
        )}
      </div>
    );
  }
}

export default ImageUploadButton;
