export default class RegFormDetails {
  constructor() {
    this.title = "";
    this.intro = "";
    this.benefits = "";
    this.groupTermsURL = "";
    this.renewalDate = "";
    this.supportEmail = "";
    this.requireMembershipPackage = false;
    this.processingFee = "";
    this.formFields = {
      fullName: true,
      firstName: true,
      lastName: true,
      email: true,
      phone: "",
      address: "",
      joinMailingList: "",
      shareVolunteerOpportunities: "",
      officialClubMember: "",
      seasonTicketHolder: "",
    };
    this.hasDonations = false;
    this.isPublished = true;
    this.customFieldDetails = {};
    this.donationAutorenewal = false;
  }

  toDataSet(data, curent) {
    this.groupImageType = data.groupImageType;
    if (data.title) {
      this.title = data.title;
    } else {
      if (curent === "basicsStep") {
        this.hasError = {
          message: "Title is required.",
        };
        return;
      }
    }
    this.donationAutorenewal = data.donationAutorenewal || false;
    if (data.intro) {
      this.intro = data.intro;
    } else {
      if (curent === "basicsStep") {
        this.hasError = {
          message: "Intoduction is required.",
        };
        return;
      }
    }
    if (data.processingFee) {
      this.processingFee = data.processingFee;
    } else {
      if (curent === "basicsStep") {
        this.hasError = {
          message: "Processing fee is required.",
        };
        return;
      }
    }
    if (data.benefits) {
      this.benefits = data.benefits;
    }
    if (data.groupTermsURL) {
      this.groupTermsURL = data.groupTermsURL;
    }

    if (data.thankYouNote) {
      this.thankYouNote = data.thankYouNote;
    } else {
      if (curent === "basicsStep") {
        this.hasError = {
          message: "Thank you message is required.",
        };
        return;
      }
    }

    // if (data.renewalDate) {
    //     this.renewalDate = data.renewalDate;
    // } else {
    //     if (curent === 'basicsStep') {
    //         this.hasError = {
    //             message: "Renewal date field is required."
    //         }
    //         return;
    //     }
    // }

    if (data.supportEmail) {
      this.supportEmail = data.supportEmail;
    } else {
      if (curent === "basicsStep") {
        this.hasError = {
          message: "Support Email field is required.",
        };
        return;
      }
    }

    this.requireMembershipPackage = data.requireMembershipPackage || false;

    this.formFields = data.formFields;
    let customKeyFields = Object.keys(data.customFieldDetails || {});
    customKeyFields.forEach((key) => {
      this.customFieldDetails[key] = data.customFieldDetails[key];
    });
    this.hasDonations = data.hasDonations;
    this.chapters = data.chapters;
    this.includeNone = data.includeNone;
    // this.packages = data.packages.map(({ name, desc, price, familyMembership, id, includesTshirt, sizes, isDeleted, hasNoOfChildren, costPerChild, hasChildTshirtSizes, shippingOption, shippingCharge, pickupNote, shippingNote }) => {

    //     if (!name && !price.value) {
    //         return;
    //     }

    //     if (!name) {
    //         if (curent === 'membershipPackagesStep') {
    //             this.hasError = {
    //                 message: "Name field is required."
    //             }
    //             return;
    //         }
    //     }
    //     if (!price.value) {
    //         if (curent === 'membershipPackagesStep') {
    //             this.hasError = {
    //                 message: "Price field is required."
    //             }
    //             return;
    //         }
    //     }
    //     let pack = { name, desc, price, familyMembership, id, includesTshirt, isDeleted }
    //     if (includesTshirt) {
    //         pack.sizes = sizes;
    //     }

    //     if (shippingOption) {
    //         pack.shippingOption = shippingOption;

    //         if (shippingOption === 'Show Shipping' || shippingOption === 'Offer choice of pickup or shipping') {
    //             pack.shippingNote = shippingNote;
    //             if (shippingCharge) {
    //                 pack.shippingCharge = shippingCharge;
    //             } else {
    //                 if (curent === 'membershipPackagesStep') {
    //                     this.hasError = {
    //                         message: "Provide shipping charge."
    //                     }
    //                     return;
    //                 }
    //             }
    //         }

    //         if (shippingOption === 'Show Pickup' || shippingOption === 'Offer choice of pickup or shipping') {
    //             pack.pickupNote = pickupNote;
    //         }
    //     }

    //     if (familyMembership) {
    //         pack.hasNoOfChildren = hasNoOfChildren;
    //         if (pack.hasNoOfChildren) {
    //             pack.costPerChild = costPerChild;
    //             pack.hasChildTshirtSizes = hasChildTshirtSizes;
    //         }
    //     }
    //     return (pack);
    // }).filter(pack => pack);

    if (this.hasDonations) {
      this.partnerId = data.partnerId;
      if (data.donationDesc) {
        this.donationDesc = data.donationDesc;
      }

      if (data.donationTitle) {
        this.donationTitle = data.donationTitle;
      }

      if (data.amount1) {
        this.amount1 = data.amount1;
      }
      if (data.amount2) {
        this.amount2 = data.amount2;
      }
      if (data.amount3) {
        this.amount3 = data.amount3;
      }
      this.donationImage = data.donationImage;
      this.donationImageList = this.donationImageList;
    }
  }

  fromDataSet(data) {
    this.donationAutorenewal = data.donationAutorenewal || false;
    this.groupImageType = data.groupImageType || "wide";
    this.title = data.title || "";
    this.intro = data.intro || "";
    this.benefits = data.benefits || "";
    this.groupTermsURL = data.groupTermsURL || "";
    this.renewalDate = data.renewalDate || "";
    this.supportEmail = data.supportEmail || "";
    this.requireMembershipPackage = data.requireMembershipPackage || false;
    this.formFields = data.formFields;
    this.formFields.fullName = true;
    this.hasDonations = data.hasDonations;
    this.processingFee = data.processingFee || "";
    this.thankYouNote = data.thankYouNote || "";
    if (data.packages && data.packages.length > 0) {
      this.packages = data.packages.map(
        (
          {
            name,
            desc,
            price,
            image,
            id,
            shippingOption,
            pickupNote,
            shippingNote,
            askTshirtSize,
            childPrice,
            includesChildPrice,
            offerShipping,
            packageExpiryDate,
            productChoice,
            products,
            season,
            status,
            tshirtSizes,
            familyMembership,
            includesTshirt,
            sizes,
            hasNoOfChildren,
            costPerChild,
            hasChildTshirtSizes,
            shippingCharge,
            offerAutorenewal,
          },
          index
        ) => ({
          name,
          desc,
          price,
          image,
          id,
          shippingOption,
          pickupNote,
          shippingNote,
          askTshirtSize,
          childPrice,
          includesChildPrice,
          offerShipping,
          packageExpiryDate,
          productChoice,
          products,
          season,
          status,
          tshirtSizes,
          familyMembership,
          includesTshirt,
          sizes,
          hasNoOfChildren,
          costPerChild,
          hasChildTshirtSizes,
          shippingCharge,
          imageList: [{ uid: `${index}`, url: image }],
          editConfiguration: { imageChanged: false },
          offerAutorenewal,
        })
      );
    }
    this.isPublished = data.isPublished;

    if (this.hasDonations) {
      this.partnerId = data.partnerId;
      if (data.donationDesc) {
        this.donationDesc = data.donationDesc;
      }

      if (data.donationTitle) {
        this.donationTitle = data.donationTitle;
      }

      if (data.amount1) {
        this.amount1 = data.amount1;
      }
      if (data.amount2) {
        this.amount2 = data.amount2;
      }
      if (data.amount3) {
        this.amount3 = data.amount3;
      }
      this.donationImage = data.donationImage;
    }
    this.editConfiguration = { groupImageChanged: false };

    if (this.groupImageType === "wide") {
      this.groupImageWideList = [{ uid: `-1`, url: data.groupImageFile }];
    } else {
      this.groupImageRegularList = [{ uid: `-1`, url: data.groupImageFile }];
    }
    this.donationImageList = data.donationImageList;
    let customKeyFields = Object.keys(data.customFieldDetails || {});
    customKeyFields.forEach((key) => {
      let value = data.customFieldDetails[key];
      value.isSaved = true;
      this.customFieldDetails[key] = value;
    });

    this.chapters = data.chapters || [];
    this.includeNone = data.includeNone || false;
  }
}
