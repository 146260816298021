import React, { useCallback, useEffect, useMemo, useState } from "react";
import EventsFilters from "./EventsFilters";
import {
  EVENTS_ALL_EVENT,
  EVENTS_ALL_REPORT,
  MEMBER_BY_EVENT_REPORT,
  MEMBER_DETAILS_REPORT,
} from "../../../../../constants/leaderboard";
import { Avatar, Table } from "antd";
import { selectCurrentGroupData } from "../../../../../redux/selectors/adminData";
import { useSelector } from "react-redux";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../../services/firebaseService/connection";
import dayjs from "dayjs";
import {
  sortObjectByNumber,
  sortObjectByString,
} from "../../../../../utils/helper";
import useMembers from "../../Members/useMembers";
import logger from "../../../../../utils/logger";
import EventsByUser from "./EventsByUser";

const Events = () => {
  const [eventsDropdownList, setEventsDropdownList] = useState([]);
  const [seasons, setSeasons] = useState([]);

  const [filters, setFilters] = useState({
    report: MEMBER_BY_EVENT_REPORT,
    event: EVENTS_ALL_EVENT,
    season: seasons?.length > 0 ? seasons[0] : null,
  });

  const [eventsLoading, setEventsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);

  const [eventsData, setEventsData] = useState(undefined);

  const [showEventsFilter, setShowEventsFilter] = useState(false);

  const [search, setSearch] = useState("");

  const groupData = useSelector(selectCurrentGroupData);

  const { membersList, membersLoading } = useMembers();

  const memberColumns = useMemo(
    () => [
      {
        title: "Members (All events)",
        width: 300,
        dataIndex: "memberName",
        sorter: (a, b) => sortObjectByString(a, b, "memberName"),
        render: (name, record) => {
          const nameInitials =
            name
              ?.split(" ")
              ?.map((word) => word?.[0] || "")
              ?.join("")
              ?.toUpperCase() || "";

          const currentMember = membersList?.find(
            (item) => item?.id === record?.memberId
          );

          return (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              {" "}
              <div className="flex-center">
                {currentMember?.profileImage ? (
                  <Avatar size="large" src={currentMember?.profileImage} />
                ) : (
                  <Avatar
                    size="large"
                    style={{ backgroundColor: "var(--primary-background)" }}
                  >
                    {nameInitials}
                  </Avatar>
                )}
              </div>
              <span className="flex-center">{name}</span>
            </div>
          );
        },
      },
      {
        title: "Total",
        width: 150,
        dataIndex: "totalPoints",
        sorter: (a, b) => sortObjectByNumber(a, b, "totalPoints"),
      },
      {
        title: "Attending",
        width: 150,
        dataIndex: "attendingPoints",
        sorter: (a, b) => sortObjectByNumber(a, b, "attendingPoints"),
      },
      {
        title: "Volunteering",
        width: 150,
        dataIndex: "volunteeringPoints",
        sorter: (a, b) => sortObjectByNumber(a, b, "volunteeringPoints"),
      },
    ],
    [membersList]
  );

  const eventsColumns = useMemo(
    () => [
      {
        title: "Events",
        width: window.screen.width > 500 ? 300 : 150,
        dataIndex: "eventName",
        sorter: (a, b) => sortObjectByString(a, b, "eventName"),
      },
      {
        title: "Date",
        width: 200,
        dataIndex: "eventStartTime",
        render: (date) =>
          date ? dayjs(date * 1000).format("MMM DD, YYYY") : "-",
        sorter: (a, b) => sortObjectByNumber(a, b, "eventStartTime"),
      },
      {
        title: "Attendees",
        width: 150,
        dataIndex: "attendeesCount",
        sorter: (a, b) => sortObjectByNumber(a, b, "attendeesCount"),
      },
      {
        title: "Total",
        width: 150,
        dataIndex: "totalPoints",
        sorter: (a, b) => sortObjectByNumber(a, b, "totalPoints"),
      },
      {
        title: "Attending",
        width: 150,
        dataIndex: "attendingPoints",
        sorter: (a, b) => sortObjectByNumber(a, b, "attendingPoints"),
      },

      {
        title: "Volunteering",
        width: 150,
        dataIndex: "volunteeringPoints",
        sorter: (a, b) => sortObjectByNumber(a, b, "volunteeringPoints"),
      },
    ],
    []
  );

  const [columns, setColumns] = useState(memberColumns);

  const resetFilters = () => {
    setSearch("");
    setFilters({
      report: MEMBER_BY_EVENT_REPORT,
      event: EVENTS_ALL_EVENT,
      season: seasons?.length > 0 ? seasons[0] : null,
    });
  };

  const handleFilterChange = (type, selectedValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: selectedValue,
    }));
  };

  const handleDropdownChange = (type, selectedValue) => {
    handleFilterChange(type, selectedValue);
  };

  const fetchEvents = useCallback(async () => {
    const seasonsRef = collection(
      db,
      "leaderBoard",
      groupData.clubId,
      "groups",
      groupData.id,
      "eventLeaderBoard"
    );
    const objectMap = {};

    try {
      setEventsLoading(true);
      // Fetch all documents in the 'groups' collection
      const seasonsSnapshot = await getDocs(seasonsRef);

      for (const seasonsDoc of seasonsSnapshot.docs) {
        const season = seasonsDoc.id;
        const seasonData = seasonsDoc.data();

        const userSummary = { ...seasonData };

        const detailsRef = collection(
          db,
          "leaderBoard",
          groupData.clubId,
          "groups",
          groupData.id,
          "eventLeaderBoard",
          season,
          "users"
        );
        const detailsSnapshot = await getDocs(detailsRef);

        const pointsByEvent = {};
        const pointsByUser = {};

        detailsSnapshot.forEach((detailDoc) => {
          const dataByEvent = detailDoc.data().eventSummary;

          pointsByUser[detailDoc.id] = {
            ...pointsByUser[detailDoc.id],
            ...dataByEvent,
          };

          Object.entries(dataByEvent).forEach(([key, value]) => {
            pointsByEvent[key] = {
              ...pointsByEvent[key],
              eventDetails: { ...value?.eventDetail, eventId: key },
              members: {
                ...pointsByEvent?.[key]?.members,
                [detailDoc.id]: value,
              },
            };
          });
        });

        objectMap[season] = {
          userSummary,
          pointsByEvent,
          pointsByUser,
        };
      }

      initializeState(objectMap);
    } catch (error) {
      logger.error("Error fetching groups with details and deet:", error);
    } finally {
      setEventsLoading(false);
    }
  }, [groupData.clubId, groupData.id]);

  const initializeState = (objectMap) => {
    setEventsData(objectMap);

    const seasons = Object.keys(objectMap)
      .filter((item) => item !== "9999")
      .sort((a, b) => {
        return b - a;
      });

    //set seasons
    setSeasons(seasons);

    //set events dropdown list
    const sortedEventsList = Object.values(
      objectMap?.[seasons[0]]?.pointsByEvent
    ).sort(
      (a, b) =>
        b?.eventDetails?.eventStartTime - a?.eventDetails?.eventStartTime
    );

    const updatedChapterFilters =
      sortedEventsList?.map((item) => {
        const formattedDate = item?.eventDetails?.eventStartTime
          ? dayjs(item?.eventDetails?.eventStartTime * 1000).format(
              "MMM DD, YYYY"
            )
          : null;

        return {
          value: item?.eventDetails?.eventId,
          label: (
            <span>
              {`${item?.eventDetails?.title || "Unknown Event"} ${formattedDate ? `(${formattedDate})` : ""}`}
            </span>
          ),
        };
      }) || [];
    setEventsDropdownList(updatedChapterFilters);
  };

  const getTableData = useCallback(() => {
    let finalData = [];

    if (filters.report === MEMBER_BY_EVENT_REPORT) {
      setColumns(memberColumns);

      if (filters.event === EVENTS_ALL_EVENT) {
        const userSummary = eventsData?.[filters?.season]?.userSummary || {};
        finalData = Object.entries(userSummary).map(([key, value]) => ({
          memberId: key,
          memberName: value?.userName || "Unknown",
          attendingPoints: Number(value?.totalEventPoints || 0),
          volunteeringPoints: Number(value?.totalVolunteerPoints || 0),
          totalPoints:
            Number(value?.totalEventPoints || 0) +
            Number(value?.totalVolunteerPoints || 0),
        }));
      } else if (filters.event?.length > 0) {
        const eventMembers =
          eventsData?.[filters.season]?.pointsByEvent?.[filters.event]
            ?.members || {};
        finalData = Object.entries(eventMembers).map(([key, value]) => ({
          memberId: key,
          memberName: value?.userName || "Unknown",
          attendingPoints: Number(value?.checkInPoint || 0),
          volunteeringPoints: Number(value?.volunteerPoints || 0),
          totalPoints:
            Number(value?.checkInPoint || 0) +
            Number(value?.volunteerPoints || 0),
        }));
      }

      finalData.sort((a, b) => b.totalPoints - a.totalPoints);
    }

    if (filters.report === MEMBER_DETAILS_REPORT) {
      const userSummary = eventsData?.[filters?.season]?.userSummary || {};
      finalData = Object.entries(userSummary).map(([key, value]) => ({
        memberId: key,
        memberName: value?.userName || "Unknown",
        attendingPoints: Number(value?.totalEventPoints || 0),
        volunteeringPoints: Number(value?.totalVolunteerPoints || 0),
        totalPoints:
          Number(value?.totalEventPoints || 0) +
          Number(value?.totalVolunteerPoints || 0),
        eventDetails: eventsData?.[filters?.season]?.pointsByUser?.[key] || {},
      }));

      finalData.sort((a, b) => b.totalPoints - a.totalPoints);
    }

    if (filters.report === EVENTS_ALL_REPORT) {
      setColumns(eventsColumns);

      const seasonPoints = eventsData?.[filters.season]?.pointsByEvent || {};
      finalData = Object.values(seasonPoints).map((value) => {
        const totalEventPoints = Object.values(value.members)?.reduce(
          (acc, curr) => {
            acc.attendingPoints += Number(curr.checkInPoint || 0);
            acc.volunteeringPoints += Number(curr.volunteerPoints || 0);
            acc.totalPoints +=
              Number(curr.checkInPoint || 0) +
              Number(curr.volunteerPoints || 0);
            return acc;
          },
          { attendingPoints: 0, volunteeringPoints: 0, totalPoints: 0 }
        ) || { attendingPoints: 0, volunteeringPoints: 0, totalPoints: 0 };

        return {
          ...totalEventPoints,
          eventStartTime: value?.eventDetails?.eventStartTime,
          eventName: value?.eventDetails?.title || "Unnamed Event",
          eventId: value?.eventDetails?.eventId,
          attendeesCount: Object.keys(value?.members)?.length || 0,
        };
      });

      finalData.sort((a, b) => b.eventStartTime - a.eventStartTime);
    }

    return finalData;
  }, [
    filters.report,
    filters.event,
    filters.season,
    memberColumns,
    eventsData,
    eventsColumns,
  ]);

  const handleSearch = (value) => {
    setSearch(value);

    if (
      filters.report === MEMBER_BY_EVENT_REPORT ||
      filters.report === MEMBER_DETAILS_REPORT
    ) {
      setFilteredTableData(() =>
        tableData.filter((item) =>
          item.memberName.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else if (filters.report === EVENTS_ALL_REPORT) {
      setFilteredTableData(() =>
        tableData.filter((item) =>
          item.eventName.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  useEffect(() => {
    if (eventsData && seasons?.length > 0) {
      if (!filters.season) {
        setFilters((prev) => ({ ...prev, season: seasons[0] }));
      }

      const data = getTableData();
      setTableData(data);
    }

    if (filters.report === MEMBER_BY_EVENT_REPORT) {
      setShowEventsFilter(true);
    } else {
      setShowEventsFilter(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsData, filters, seasons]);

  useEffect(() => {
    fetchEvents();
    resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData?.id]);

  useEffect(() => {
    setFilteredTableData(tableData);
  }, [tableData]);

  return (
    <div style={{ width: "100%", height: "100%", padding: "0rem 12px" }}>
      <div
        className="mb-4 p-3 py-md-4 mx-md-auto"
        style={{
          width: "100%",
          border: "1px solid #dee2e6",
          borderRadius: "0.25rem",
          marginTop: 30,
          backgroundColor: "#ffffff",
        }}
      >
        <p>
          When creating an event use ‘event check-in’ and ‘volunteering’ to
          offer points and encourage members to attend. Reward the most engaged
          members by populating the ‘Rewards’ section with prizes.
        </p>

        <EventsFilters
          filters={filters}
          seasons={seasons}
          search={search}
          handleSearch={handleSearch}
          handleDropdownChange={handleDropdownChange}
          showEventsFilter={showEventsFilter}
          eventsDropdownList={eventsDropdownList}
          eventsLoading={eventsLoading || membersLoading}
          searchType={
            filters.report === MEMBER_BY_EVENT_REPORT ||
            filters.report === MEMBER_DETAILS_REPORT
              ? "members"
              : "events"
          }
        />

        <div className="pt-4">
          <Table
            bordered
            columns={columns}
            headerBorderRadius={0}
            loading={eventsLoading || membersLoading}
            dataSource={filteredTableData}
            scroll={{ x: 750 }}
            rowKey="memberId"
            bodyStyle={{
              fontSize: window.screen.width > 500 ? "18px" : "12px",
              backgroundColor: "#ffffff",
            }}
            expandable={
              filters.report === MEMBER_DETAILS_REPORT && {
                expandedRowRender: (record) => (
                  <EventsByUser
                    loading={eventsLoading || membersLoading}
                    eventDetails={record.eventDetails}
                  />
                ),
              }
            }
            pagination={{
              defaultPageSize: 100,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100],
              position: ["bottomLeft"],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Events;
