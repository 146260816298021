import { Spin } from "antd";

const Images = ({
  filteredData,
  tab,
  handleSelectImg,
  webImages,
  selectedImages,
  imagesLoading,
}) => {
  let nodes = [];
  let month = "";
  let year = 0;
  let internalNodes = [];

  const images = filteredData
    .sort((data1, data2) => (data2.imageDate || 0) - (data1.imageDate || 0))
    .filter((data) => (tab === "selected" ? data.showOnWeb : true));

  images.forEach((data) => {
    let date = new Date((data.imageDate || 0) * 1000);
    let newMonth = date.toLocaleDateString("default", { month: "long" });
    let newYear = date.getFullYear();

    if (!(month || year)) {
      month = newMonth;
      year = newYear;
    }

    const imageCard = (
      <div
        key={data.id}
        style={{
          height: 130,
          width: 170,
          margin: 15,
          backgroundImage: `url(${data.imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          cursor: "pointer",
          position: "relative",
        }}
      >
        <div className="overlay-check">
          <input
            type="checkbox"
            checked={
              selectedImages.hasOwnProperty(data.id)
                ? selectedImages[data.id]
                : webImages[data.id]
            }
            style={{
              height: 20,
              width: 20,
              borderRadius: "50%",
              position: "absolute",
              top: 10,
              right: 10,
            }}
            onChange={() => {
              const currentValue = selectedImages.hasOwnProperty(data.id)
                ? selectedImages[data.id]
                : webImages[data.id];
              handleSelectImg(!currentValue, data.id);
            }}
          />
        </div>
      </div>
    );

    if ((month !== newMonth || year !== newYear) && internalNodes.length > 0) {
      nodes.push(
        <div key={`${month}-${year}`}>
          <span style={{ fontWeight: "bold" }}>{`${month}, ${year}`}</span>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {internalNodes}
          </div>
        </div>
      );

      internalNodes = [imageCard];
      month = newMonth;
      year = newYear;
    } else {
      internalNodes.push(imageCard);
    }
  });

  if (month && year) {
    nodes.push(
      <div key={`${month}-${year}-final`}>
        <span style={{ fontWeight: "bold" }}>{`${month}, ${year}`}</span>
        <div style={{ display: "flex", flexWrap: "wrap" }}>{internalNodes}</div>
      </div>
    );
  }

  return (
    <div>
      {imagesLoading ? (
        <div
          className="flex-center"
          style={{ width: "100%", minHeight: "300px" }}
        >
          <Spin />
        </div>
      ) : (
        nodes
      )}{" "}
    </div>
  );
};

export default Images;
