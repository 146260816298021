import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { getCurrencyUnicode } from "../../../helperFunctions/util";
import dayjs from "dayjs";
import ReceiptElement from "./ReceiptElement";
import { useSelector } from "react-redux";
import { selectCurrentGroupData } from "../../../redux/selectors/adminData";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../services/firebaseService/connection";
import { parseString } from "../../../services/firebaseService/endPoints/admin/scripts";
import { convertStringObjectToJson } from "../../../utils/helper";
import { fetchMembers } from "../../../services/api/members";
import useGlobalStore from "../../../store/store";
import {
  getOrders,
  getStoreItems,
} from "../../../services/firebaseService/endPoints/admin/store";
import { fbGetDonationDataForGroup } from "../../../services/firebaseService/endPoints/admin/partners";
import PurchasesFilters from "./PurchasesFilters";
import "./PurchaseReport.css";
import usePackages from "../../hooks/usePackages";
import logger from "../../../utils/logger";
import DescriptionText from "../../../CommonComponents/DescriptionText";
import ScreenHeader from "../../../CommonComponents/ScreenHeader";

const PurchasesReport = () => {
  const [loading, setLoading] = useState(false);
  const [allMembers, setAllMembers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filterPackages, setFilterPackages] = useState([]);
  const [status, setStatus] = useState("");
  const [types, setTypes] = useState([]);
  const [stateInitialized, setStateInitialized] = useState(false);

  const [filteredTableData, setFilteredTableData] = useState([]);

  const { packagesBySeason, packagesLoading } = usePackages();

  const defaultEndDate = Date.now();

  const [filters, setFilters] = useState({
    search: "",
    packageId: "",
    type: "",
    autoRenewal: null,
  });

  const resetPurchaseFilters = () => {
    setFilters({
      search: "",
      packageId: "",
      type: "",
      autoRenewal: null,
    });
  };

  const groupData = useSelector(selectCurrentGroupData);

  const onFilterChange = (type, selectedValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: selectedValue,
    }));
  };

  const fetchData = async (packages, orders, donations) => {
    let tableData = [];
    let queryFilters = [];

    queryFilters.push(where("timestamp", "<=", defaultEndDate));
    if (status) {
      queryFilters.push(where("status", "==", status));
    }

    let dbQuery = query(
      collection(db, "transaction_record", groupData.id, "records"),
      ...queryFilters
    );

    let allTransactions = await getDocs(dbQuery).then((snap) => {
      let transactions = [];
      for (let doc of snap.docs) {
        let data = doc.data();
        data.transactionId = doc.id;
        transactions.push(data);
      }
      return transactions;
    });

    let successfulTransactions = allTransactions?.filter(
      (txn) => txn.status === "success"
    );

    const uniqueTypes = [
      ...new Set(
        successfulTransactions.map((item) =>
          item.paymentType ? item.paymentType : getTransactionType(item)
        )
      ),
    ];

    const groupTypes = uniqueTypes
      .map((type) => ({ type, key: type }))
      .filter((item) => item.type);

    setTypes([...groupTypes]);

    let successfulTransactionsSorted = successfulTransactions.sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
    );

    const uniqueApiPackageIds = new Set(
      successfulTransactionsSorted
        .map((item) => item?.packageId)
        .filter((id) => id !== undefined && id !== "")
    );

    const filteredPackages = packages.filter((pkg) =>
      uniqueApiPackageIds.has(pkg.id)
    );

    setFilterPackages(filteredPackages);

    for (let transaction of successfulTransactionsSorted) {
      try {
        const [userData, storeData, donationData] = await Promise.all([
          fetchUserData(transaction.transactionId),
          fetchStoreData(transaction.transactionId, orders),
          fetchDonationData(transaction.transactionId, donations),
        ]);

        tableData.push(
          preparePurchaseData(
            transaction,
            userData,
            storeData,
            donationData,
            packagesBySeason
          )
        );
      } catch (error) {
        logger.error(
          `Failed to fetch data for transaction ${transaction.transactionId}: `,
          error
        );
      } finally {
        setLoading(false);
      }
    }

    try {
      const results = await Promise.all(
        successfulTransactionsSorted.map(async (transaction) => {
          const [userData, storeData, donationData] = await Promise.all([
            fetchUserData(transaction.transactionId),
            fetchStoreData(transaction.transactionId),
            fetchDonationData(transaction.transactionId, donations),
          ]);

          return preparePurchaseData(
            transaction,
            userData,
            storeData,
            donationData,
            packagesBySeason
          );
        })
      );

      tableData.push(...results);
    } catch (error) {
      logger.error("Error processing transactions: ", error);
    }

    setTableData(tableData);
    setLoading(false);
  };

  const fetchUserData = async (transactionId) => {
    let data = {};
    let requiredData = allMembers.filter(
      (data) => data.paymentIntentId === transactionId
    );
    if (requiredData.length > 0) {
      data = requiredData[0];
    }
    return data;
  };

  const fetchStoreData = async (transactionId, orders) => {
    let storeData = {};
    let requiredStoreOrder = orders.filter(
      (data) => data.paymentIntentId === transactionId
    );
    if (requiredStoreOrder.length > 0) {
      storeData.orderNo = requiredStoreOrder[0].orderNo;
      storeData.items = requiredStoreOrder[0].items || [];
    }
    return storeData;
  };

  const fetchDonationData = async (transactionId, donations) => {
    const requiredDonationData = donations.filter(
      (data) => data.paymentIntentId === transactionId
    );

    if (!requiredDonationData.length) {
      return { hasDonation: false, donationAmount: 0 };
    }

    const donationAmount = requiredDonationData.reduce(
      (total, data) => total + data.amount,
      0
    );

    return {
      hasDonation: true,
      donationAmount,
    };
  };

  const getUserDetailsFromMetadata = (type, transaction) => {
    const userDetails = {
      metaDataEmail: "",
      metaDataName: "",
      metaDataAmount: undefined,
      metaDataPartnerName: "",
      metaDataGiveawayName: "",
    };

    const params = {
      donation: "donationParams",
      giveaway: "giveawayParams",
      tickets: "ticketParams",
      "tickets-web": "ticketParams",
      registration: "subscriptionParams",
    };

    let data;
    try {
      data = JSON.parse(transaction?.[params?.[type]]);
    } catch (error) {
      return userDetails;
    }

    let items = [];

    if (data?.ticketsBuyingData) {
      Object.values(data?.ticketsBuyingData).forEach((item) =>
        items.push(item.name)
      );
    }

    if (type === "registration") {
      userDetails.metaDataEmail = data?.customerEmailId;
      userDetails.metaDataName = data?.customerName;
      userDetails.metaDataAmount = null;
      userDetails.metaDataPartnerName = data?.partnerName;
      userDetails.metaDataGiveawayName = data?.giveawayName;
    } else {
      userDetails.metaDataEmail = data?.userEmail;
      userDetails.metaDataName = data?.userName;
      userDetails.metaDataAmount = Number(data?.totalCost)?.toFixed(2);
      userDetails.metaDataPartnerName = data?.partnerName;
      userDetails.metaDataGiveawayName = data?.giveawayName;
      userDetails.metaDataTicketItems = items;
    }

    return userDetails;
  };

  const getSubscriptionMetaData = (data) => {
    const userDetails = {
      metaDataEmail: "",
      metaDataName: "",
    };

    const key = Object.keys(data.metadata).find((item) =>
      item.startsWith("filled")
    );

    let res;

    try {
      if (key) {
        res = parseString(data.metadata[key]);
        userDetails.metaDataEmail = res.email;
        userDetails.metaDataName = res.fullName;
      }
    } catch {
      return userDetails;
    }

    return userDetails;
  };

  const getRegistrationPackageName = (data, packages) => {
    const key = Object.keys(data)?.find((item) => item?.startsWith("package-"));

    const parsedObject = convertStringObjectToJson(data?.[key]);

    let packageName = packages?.find(
      (pack) => pack?.id === parsedObject?.id
    )?.name;

    return packageName;
  };

  const preparePurchaseData = (
    transaction,
    userData,
    storeData,
    donationData,
    packages
  ) => {
    let type = transaction.paymentType
      ? transaction.paymentType
      : getTransactionType(transaction);
    let items = [];

    let metaDataUserDetails;
    let subscriptionUserDetails;
    let autoRenewalFields = [];

    if (
      type === "donation" ||
      type === "giveaway" ||
      type === "tickets" ||
      type === "tickets-web" ||
      type === "registration"
    ) {
      metaDataUserDetails = getUserDetailsFromMetadata(type, transaction);
    }

    if (transaction?.subscription) {
      subscriptionUserDetails = getSubscriptionMetaData(transaction);
    }

    if (type === "registration" && transaction?.subscription) {
      autoRenewalFields.push(type);
    }

    if (transaction?.packageId) {
      if (
        transaction?.metadata?.packageSeason ||
        transaction?.data?.packageSeason
      ) {
        const packageSeason =
          transaction?.metadata?.packageSeason ||
          transaction?.data?.packageSeason;

        let selectedPackage = packagesBySeason?.find(
          (pack) => pack?.key === `${transaction?.packageId}|${packageSeason}`
        );
        const pkgName = selectedPackage?.name;

        const pkgValue = `${pkgName} (${packageSeason})`;
        pkgValue?.length > 0 && items.push(pkgValue);
      }
    } else if (
      transaction?.data?.["packageId-0"] &&
      transaction?.data?.["packageId-0"]?.length > 0
    ) {
      let selectedPackage = packagesBySeason?.find(
        (pack) => pack?.id === transaction?.data?.["packageId-0"]
      );

      items.push(selectedPackage?.name);
    }

    if (metaDataUserDetails?.metaDataGiveawayName) {
      items.push(metaDataUserDetails.metaDataGiveawayName);
    }

    if (metaDataUserDetails?.metaDataPartnerName) {
      items.push(metaDataUserDetails.metaDataPartnerName);
    }

    if (
      metaDataUserDetails?.metaDataTicketItems &&
      metaDataUserDetails?.metaDataTicketItems?.length > 0
    ) {
      items.push(metaDataUserDetails?.metaDataTicketItems);
    }

    if (donationData && donationData?.donationAmount > 0) {
      items.push("Donation");
    }

    let stripeTransactionUrl;
    let subscriptionUrl;

    if (transaction?.transactionId?.startsWith("pi_")) {
      stripeTransactionUrl = `https://dashboard.stripe.com/connect/accounts/${groupData?.paymentDetails?.stripeAccountId}/payments/${transaction?.transactionId}`;
    }

    if (transaction?.subscription?.startsWith("sub")) {
      subscriptionUrl = `https://dashboard.stripe.com/connect/accounts/${groupData?.paymentDetails?.stripeAccountId}/subscriptions/${transaction?.subscription}`;
    }

    let autoRenewals = "";

    if (transaction?.cancelSubscriptionTimestamp) {
      autoRenewals = `Cancelled: ${dayjs(transaction?.cancelSubscriptionTimestamp).format("MMM Do, YYYY")}`;
    } else {
      autoRenewals =
        transaction?.subscribedProduct?.length > 0
          ? transaction?.subscribedProduct?.join(", ")
          : autoRenewalFields?.length > 0
            ? autoRenewalFields?.join(", ")
            : "";
    }

    if (!type) {
      setTypes((prevTypes) => [
        ...prevTypes,
        { key: "unknown", type: "unknown" },
      ]);
    }

    return {
      name:
        transaction?.name ||
        userData?.name ||
        metaDataUserDetails?.metaDataName ||
        subscriptionUserDetails?.metaDataName ||
        "-",
      email:
        transaction?.email ||
        userData?.email ||
        metaDataUserDetails?.metaDataEmail ||
        subscriptionUserDetails?.metaDataEmail ||
        "-",
      package: transaction.packageId || "",
      item: items?.join(", "),
      type: type || "unknown",
      amount:
        transaction?.amount?.toFixed(2) || metaDataUserDetails?.metaDataAmount,
      donationIncluded: donationData?.donationAmount?.toFixed(2),
      date: transaction?.timestamp,
      receipt: {
        txnUrl: stripeTransactionUrl,
        receiptUrl: transaction?.receiptUrl,
        subscriptionUrl,
      },
      cancelSubscriptionTimestamp: transaction?.cancelSubscriptionTimestamp,
      orderNo: storeData?.orderNo,
      otherMembers: userData?.otherMembers,
      autoRenewal: autoRenewals,
      packageSeason: transaction?.metadata?.packageSeason,
    };
  };

  const { membersList } = useGlobalStore((state) => state.members);

  useEffect(() => {
    if (groupData?.id?.length > 0 && packagesBySeason?.length > 0) {
      initializeState(packagesBySeason);
      resetPurchaseFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData.id, packagesBySeason]);

  const applyFilters = (data) => {
    return data.filter((item) => {
      if (filters?.search) {
        const searchTermLower = filters.search.toLowerCase();
        const nameLower = item?.name?.toLowerCase();
        const emailLower = item?.email?.toLowerCase();

        if (
          !(
            nameLower?.includes(searchTermLower) ||
            emailLower?.includes(searchTermLower)
          )
        ) {
          return false;
        }
      }

      if (
        filters.type &&
        filters.type.length > 0 &&
        item.type !== filters.type
      ) {
        return false;
      }

      if (filters.packageId && filters.packageId.length > 0) {
        const pkgId = item.package + "|" + item.packageSeason;
        if (pkgId !== filters.packageId) {
          return false;
        }
      }

      if (filters.dateRange) {
        const dateRange = {};
        dateRange.startDate = filters.dateRange[0]?.valueOf();
        dateRange.endDate = filters.dateRange[1]?.valueOf();

        if (
          !(item.date >= dateRange.startDate && item.date <= dateRange.endDate)
        ) {
          return false;
        }
      }

      return true;
    });
  };

  useEffect(() => {
    if (tableData?.length > 0) {
      const filteredData = applyFilters(tableData);
      setFilteredTableData(filteredData);
    }
  }, [filters, tableData]);

  const initializeState = async (packages) => {
    try {
      setLoading(true);

      // Fetch members if not already cached
      if (!membersList?.[groupData?.id]) {
        await fetchMembers(groupData?.id);
      }

      // Fetch store items and orders
      const storeItems = await getStoreItems(groupData.id);
      const orders = await getOrders(groupData.id);

      const formattedOrders = orders.map((order) => {
        const items = order.items || [];
        const formattedItems = items.map((item) => {
          const requiredStoreItem = storeItems.find(
            (storeItem) => storeItem.id === item.id
          ) || { name: "" };

          const itemData = {
            itemName: requiredStoreItem.name,
            quantity: item.count,
            color: item.color,
            size: item.size,
            status: item.status || "unfulfilled",
            statusUpdateTimestamp: item.statusUpdateTimestamp || "",
            price: item.price,
            itemId: item.id,
          };

          // Buyer Info and Address Handling
          if (item.buyerInfo) {
            let addressParts = [];
            const buyerInfo = item.buyerInfo;

            if (buyerInfo.fullName) itemData.customerName = buyerInfo.fullName;
            if (buyerInfo.address1) {
              addressParts.push(buyerInfo.address1);
              itemData.address1 = buyerInfo.address1;
            }
            if (buyerInfo.address2) {
              addressParts.push(buyerInfo.address2);
              itemData.address2 = buyerInfo.address2;
            }
            if (buyerInfo.city) {
              addressParts.push(buyerInfo.city);
              itemData.city = buyerInfo.city;
            }
            if (buyerInfo.state) {
              addressParts.push(buyerInfo.state);
              itemData.state = buyerInfo.state;
            }
            if (buyerInfo.country) {
              addressParts.push(buyerInfo.country);
              itemData.country = buyerInfo.country;
            }
            if (buyerInfo.zip) {
              itemData.zip = buyerInfo.zip;
              addressParts.push(buyerInfo.zip);
            }

            // Join address parts with clean formatting
            itemData.address = addressParts.join(", ");
          }

          return itemData;
        });

        // Construct order object
        return {
          orderNo: order.id,
          items: formattedItems,
          paymentIntentId: order.paymentIntentId,
        };
      });

      // Fetch donations
      const donations = await fbGetDonationDataForGroup(groupData.id);

      // Set data to state
      // setAllStoreOrders(formattedOrders);
      // setAllDonations(donations);

      fetchData(packages, formattedOrders, donations);
    } catch (error) {
      setLoading(false);
      logger.error("Error initializing state:", error);
    }
  };

  const getTransactionType = (transaction) => {
    const selectedData = transaction?.data || transaction?.metadata;

    if (selectedData) {
      let typeKey = Object.keys(selectedData)?.filter((elem) =>
        elem?.startsWith("type")
      );
      if (typeKey.length > 0) {
        return selectedData?.[typeKey[0]];
      }
    }
    return transaction.type;
  };

  let groupCurrencyISO = groupData.paymentDetails
    ? groupData.paymentDetails.currency || "USD"
    : "USD";
  let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

  const totalRevenue = filteredTableData.reduce((acc, curr) => {
    acc = acc + (Number(curr?.amount) || 0);
    return acc;
  }, 0);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      className: "header-white",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "header-white",
      width: 200,
      render: (data) => data || "-",
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      width: 300,
      className: "header-white",
      render: (data) => data || "-",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      className: "header-white",
      width: 150,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      className: "header-white",
      width: 100,
      render: (data) => (data ? `${groupCurrency}${data}` : "-"),
    },
    {
      title: "Donation",
      dataIndex: "donationIncluded",
      key: "donationIncluded",
      className: "header-white",
      width: 100,
      render: (data) => (data > 0 ? `${groupCurrency}${data}` : "-"),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      className: "header-white",
      width: 150,
      sorter: (a, b) => dayjs(b.date).unix() - dayjs(a.date).unix(),
      render: (data) =>
        dayjs.unix(data ? Math.floor(data / 1000) : 0).format("MMM DD, YYYY"),
    },
    {
      title: "Stripe",
      dataIndex: "receipt",
      key: "receipt",
      className: "header-white",
      width: 150,
      render: (data, record) => {
        return (
          <ReceiptElement
            stripeTransactionUrl={data?.txnUrl}
            receiptUrl={data?.receiptUrl}
            subscriptionUrl={data?.subscriptionUrl}
            cancelSubscriptionTimestamp={record?.cancelSubscriptionTimestamp}
          />
        );
      },
    },
    {
      title: "Order #",
      dataIndex: "orderNo",
      key: "orderNo",
      width: 150,
      className: "header-white",
      render: (data) => data || "-",
    },
    {
      title: "Auto-renew",
      dataIndex: "autoRenewal",
      key: "autoRenew",
      width: 150,
      className: "header-white",
      render: (data) => data || "-",
    },
  ];

  return (
    <div className="purchases-container">
      <ScreenHeader
        title="Purchases"
        actions={
          <p style={{ marginBottom: 0 }} className="flex-center">
            <h5 style={{ marginBottom: 0 }} className="flex-center">
              Revenue:
            </h5>{" "}
            <h6
              style={{
                color: "#4e4e4e",
                marginBottom: 0,
                marginLeft: "4px",
                marginTop: "4px",
              }}
            >
              {totalRevenue
                ? `${groupCurrency}${totalRevenue.toFixed(2)}`
                : "0"}
            </h6>
          </p>
        }
      />

      <div className="purchases-description">
        <DescriptionText>
          The Purchases report provides an overview of transactions for
          membership packages, store, tickets, donations and giveaways.
          Historical data may be incomplete. The{" "}
          <a
            href="https://dashboard.stripe.com/dashboard"
            target="_blank"
            rel="noopener noreferrer"
          >
            Stripe dashboard
          </a>{" "}
          remains the primary resource to review and manage transactions
          (additional detail, refunds, invoices, fees, etc).
        </DescriptionText>
      </div>

      <PurchasesFilters
        filters={filters}
        onFilterChange={onFilterChange}
        types={types}
        filterPackages={filterPackages}
      />

      <div className="col mt-2 p-0">
        <Table
          bordered
          loading={loading}
          columns={columns}
          dataSource={filteredTableData}
          bodyStyle={{
            backgroundColor: "#ffffff",
          }}
          scroll={{ x: 1600 }}
          pagination={{
            position: ["bottomLeft"],
            defaultPageSize: 100,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          className="table-backdrop mb-3"
        />
      </div>
    </div>
  );
};

export default PurchasesReport;
