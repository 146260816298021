import React, { useEffect, useState } from "react";
import ScreenHeader from "../../../../CommonComponents/ScreenHeader";
import { useParams } from "react-router-dom";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../../../services/firebaseService/connection";
import { useSelector } from "react-redux";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import { Avatar, Popconfirm, Spin, Table } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import DescriptionText from "../../../../CommonComponents/DescriptionText";
import "./AdminPolls.css";
import { sortObjectByString } from "../../../../utils/helper";
import useMembers from "../../members/Members/useMembers";
import { DownloadIcon } from "../../../../utils/icons.jsx";
import { json2csv } from "json-2-csv";

const AdminPollVotes = () => {
  const { pollId } = useParams();

  const { membersList, membersLoading } = useMembers();

  const groupData = useSelector(selectCurrentGroupData);
  const [downloading, setDownloading] = useState(false);

  const [pollEntries, setPollEntries] = useState([]);
  const [selectedPollDetails, setSelectedPollDetails] = useState({});

  const [totals, setTotals] = useState({});

  const [entriesLoading, setEntriesLoading] = useState(false);
  const [pollDetailsLoading, setPollDetailsLoading] = useState(false);

  const fetchPollDetails = async () => {
    try {
      setPollDetailsLoading(true);

      const pollRef = doc(db, `polls/${groupData?.id}/poll/${pollId}`);

      const pollSnap = await getDoc(pollRef);

      if (pollSnap.exists()) {
        const pollData = pollSnap.data();
        setSelectedPollDetails(pollData);
      } else {
        setSelectedPollDetails({});
      }
    } catch (error) {
      setSelectedPollDetails({});
    } finally {
      setPollDetailsLoading(false);
    }
  };

  const fetchPollEntries = async () => {
    try {
      setEntriesLoading(true);

      const entriesRef = collection(
        db,
        `polls/${groupData?.id}/poll/${pollId}/entries`
      );
      const entriesSnapshot = await getDocs(entriesRef);

      const entries = entriesSnapshot.docs.map((entryDoc) => ({
        id: entryDoc.id,
        ...entryDoc.data(),
      }));

      setPollEntries(entries);
    } catch (error) {
      setPollEntries([]);
    } finally {
      setEntriesLoading(false);
    }
  };

  useEffect(() => {
    if (pollId && pollId?.length > 0 && groupData?.id) {
      fetchPollEntries();
      fetchPollDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData?.id, pollId]);

  const getColumns = () => {
    const columns = [
      {
        title: "Member",
        width: 300,
        dataIndex: "userName",
        key: "userName",
        sorter: (a, b) => sortObjectByString(a, b, "userName"),
        render: (name, record) => {
          const nameInitials =
            name
              ?.split(" ")
              ?.map((word) => word?.[0] || "")
              ?.join("")
              ?.toUpperCase() || "";

          const currentMember = membersList?.find(
            (item) => item?.id === record?.userId
          );

          return (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              {" "}
              <div className="flex-center">
                {currentMember?.profileImage ? (
                  <Avatar size="large" src={currentMember?.profileImage} />
                ) : (
                  <Avatar
                    size="large"
                    style={{ backgroundColor: "var(--primary-background)" }}
                  >
                    {nameInitials}
                  </Avatar>
                )}
              </div>
              <span className="flex-center">{name}</span>
            </div>
          );
        },
      },
    ];

    const possibleOptionKeys = ["op1", "op2", "op3", "op4", "op5"];

    possibleOptionKeys.forEach((property) => {
      if (
        selectedPollDetails.hasOwnProperty(property) &&
        selectedPollDetails?.[property]?.name
      ) {
        columns.push({
          title: selectedPollDetails?.[property]?.name,
          dataIndex: `option${selectedPollDetails?.[property]?.id}`,
          key: `option${selectedPollDetails?.[property]?.id}`,
          align: "center",
          sorter: (a, b) =>
            a[`option${selectedPollDetails?.[property]?.id}`] ? -1 : 1,
        });
      }
    });
    columns.push({
      title: "Additional Feedback",
      key: "feedback",
      width: 300,
      dataIndex: "feedback",
    });

    return columns;
  };

  const getTableData = () => {
    const finalData = [];

    const totals = { userName: "Total" };

    pollEntries?.forEach((entry) => {
      const optionKey = `option${entry?.option?.id}`;

      finalData.push({
        userName: entry?.userName,
        [optionKey]: <CheckOutlined />,
        feedback: entry?.optionalFeedback || "",
        userId: entry?.id,
      });

      totals[optionKey] = (totals?.[optionKey] || 0) + 1;
    });

    finalData?.sort((a, b) => a?.userName.localeCompare(b?.userName));

    return finalData;
  };

  useEffect(() => {
    const totals = { userName: "Total" };

    pollEntries?.forEach((entry) => {
      const optionKey = `option${entry?.option?.id}`;
      totals[optionKey] = (totals?.[optionKey] || 0) + 1;
    });

    // const accObj = {};

    // getColumns().forEach((col) => {
    //   if (col.dataIndex.includes("option")) {
    //     accObj[col.dataIndex] = 0;
    //   } else if (col.dataIndex === "userName") {
    //     accObj[col.dataIndex] = "Total";
    //   } else {
    //     accObj[col.dataIndex] = "Additional Feedback";
    //   }
    // });

    // const totals = pollEntries.reduce((acc, curr) => {
    //   const optionKey = `option${curr?.option?.id}`;

    //   acc[optionKey] = (acc[optionKey] || 0) + 1;

    //   return acc;
    // }, accObj);

    setTotals(totals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollEntries]);

  const handleDownloadCsv = () => {
    try {
      setDownloading(true);

      const tableData = getTableData();
      const columns = getColumns();

      let result = [];

      tableData.forEach((entry) => {
        const row = {};

        columns.forEach((col) => {
          row[col.title] =
            typeof entry[col.key] === "object" ? "Voted" : entry[col.key] || "";
        });

        result.push(row);
      });

      const csv = json2csv(result);

      const a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(
        new Blob([csv], { type: "text/csv" })
      );
      a.download = "votes.csv";

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      setDownloading(false);
    } catch (error) {
      setDownloading(false);
    }
  };

  return (
    <div style={{ padding: "12px" }}>
      <ScreenHeader
        title="Votes"
        elementCount={pollEntries?.length}
        actions={
          <div>
            <Popconfirm
              title="Download votes list?"
              onConfirm={handleDownloadCsv}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
              trigger="click"
            >
              <div className="icon-container" style={{ cursor: "pointer" }}>
                {downloading ? (
                  <Spin style={{ fontSize: "20px" }} />
                ) : (
                  <img
                    src={DownloadIcon}
                    alt="icon"
                    style={{ fontSize: "20px" }}
                  />
                )}
              </div>
            </Popconfirm>
          </div>
        }
      />

      <div className="admin-poll-description">
        <DescriptionText>
          <span style={{ fontWeight: "bold" }}>Question</span>:{" "}
          {selectedPollDetails?.desc}
        </DescriptionText>
      </div>

      <Table
        sticky
        rowClassName={(record) =>
          record.userName === "Total" ? "poll-totals-row" : ""
        }
        columns={getColumns()}
        loading={entriesLoading || pollDetailsLoading || membersLoading}
        dataSource={getTableData()}
        pagination={{
          position: ["bottomLeft"],
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
        summaryPosition="top"
        summary={() => (
          <Table.Summary fixed="top">
            <Table.Summary.Row
            // style={{ backgroundColor: "rgb(248, 248, 248)" }}
            >
              {Object.entries(totals).map(([key, value]) => {
                if (key.includes("option")) {
                  const idx = key.substring(6);

                  return (
                    <Table.Summary.Cell index={idx} align="center">
                      {value > 0 ? value : ""}
                    </Table.Summary.Cell>
                  );
                } else if (key.includes("userName")) {
                  return (
                    <Table.Summary.Cell align="left">Total</Table.Summary.Cell>
                  );
                }

                return (
                  <Table.Summary.Cell align="left"> ‎ </Table.Summary.Cell>
                );
              })}
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  );
};

export default AdminPollVotes;
