import React, { useEffect, useState } from "react";
import ScreenHeader from "../../../../CommonComponents/ScreenHeader";
import { Table, Tooltip } from "antd";
import dayjs from "dayjs";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../services/firebaseService/connection";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import { useSelector } from "react-redux";
import {
  getDayjsFromTimestamp,
  sortObjectByNumber,
} from "../../../../utils/helper";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./AdminPolls.css";
import DescriptionText from "../../../../CommonComponents/DescriptionText";
import ViewMoreText from "../../../../CommonComponents/ViewMore/ViewMore";
import ChantButton from "../../../../CommonComponents/ChantButton/ChantButton";

const AdminPolls = () => {
  const navigate = useNavigate();

  const handleViewPollDetails = (id) => {
    navigate(`/admin/content/polls/${id}`);
  };

  const columns = [
    {
      title: "Actions",
      width: 100,
      align: "center",
      render: (data) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data && data.id && (
              <i
                className="ml-3"
                style={{ cursor: "pointer" }}
                onClick={() => handleViewPollDetails(data?.id)}
              >
                <EyeOutlined />
              </i>
            )}
          </div>
        );
      },
    },
    {
      title: "Date Posted",
      key: "addedOn",
      dataIndex: "addedOn",
      className: "header-white",
      width: 150,
      render: (data) =>
        data ? getDayjsFromTimestamp(data).format("Do MMM, YYYY") : "",
      sorter: (a, b) => sortObjectByNumber(a, b, "addedOn"),
    },

    {
      title: "Time Remaining",
      key: "timeRemaining",
      dataIndex: "closesOn",
      className: "header-white",
      width: 150,
      render: (data) => {
        const parsedDate = getDayjsFromTimestamp(data);
        if (!parsedDate) {
          return "N/A";
        }
        const now = dayjs();
        let timeRemaining = parsedDate.diff(now, "seconds");

        if (timeRemaining <= 0) {
          return "Closed";
        }

        if (timeRemaining < 60) {
          return `${timeRemaining} sec`;
        } else if (timeRemaining < 3600) {
          return `${Math.round(timeRemaining / 60)} minutes`;
        } else if (timeRemaining < 86400) {
          const hours = Math.round(timeRemaining / 3600);
          return `${hours} ${hours === 1 ? "hour" : "hours"}`;
        } else {
          const days = Math.round(timeRemaining / 86400);
          return `${days} ${days === 1 ? "day" : "days"}`;
        }
      },
    },

    {
      title: "Question",
      key: "question",
      className: "header-white",
      dataIndex: "desc",
      width: 300,
      ellipsis: true,
      render: (data) => <ViewMoreText text={data} maxLength={100} />,
    },
    {
      title: "Responses(%)",
      key: "response",
      className: "header-white",
      width: 300,
      render: (data) => {
        if (!data) return null;
        const currentTime = dayjs().unix();

        const sortedStats = Object.entries(data?.stats || {}).sort(
          (a, b) => b[1] - a[1]
        );

        if (data.isActive || currentTime > data.closesOn) {
          return (
            <div>
              {data.stats
                ? sortedStats.map(([key, value], index) => (
                    <div key={index}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex", gap: "4px" }}>
                          <span>•</span>
                          <span>{key}</span>{" "}
                        </div>
                        <span
                          style={{ color: "grey" }}
                        >{`${Math.round(value)}%`}</span>{" "}
                      </div>
                    </div>
                  ))
                : null}
            </div>
          );
        } else {
          return (
            <div>
              {Array.from({ length: 5 }, (_, index) => index)
                .filter((index) => data[`op${index + 1}`])
                .map((index) => (
                  <div key={index}>
                    <div style={{ display: "flex", gap: "4px" }}>
                      <span>•</span>
                      <div key={index}>{data[`op${index + 1}`]?.name}</div>
                    </div>
                  </div>
                ))}
            </div>
          );
        }
      },
    },
    {
      title: "Total Responses",
      key: "entries",
      className: "header-white",
      dataIndex: "noOfPolls",
      width: 150,
      render: (entries) => entries || 0,
    },
    {
      title: "Feedback",
      key: "provideFeedBack",
      className: "header-white",
      dataIndex: "provideFeedBack",
      ellipsis: true,
      width: 150,
      render: (data) => (data ? "Yes" : "No"),
    },
    {
      title: (
        <Tooltip title="Show responses to all members?" placement="topLeft">
          Show Responses
        </Tooltip>
      ),
      key: "showEntriesToMembers",
      className: "header-white",
      dataIndex: "showEntriesToMembers",
      ellipsis: true,
      width: 150,
      render: (data) => (data ? "Yes" : "No"),
    },
    {
      title: "Allow Fan Vote?",
      key: "showForFans",
      className: "header-white",
      dataIndex: "showForFans",
      width: 150,
      render: (data) => (data ? "Yes" : "No"),
    },

    // {
    //   title: "Response Rate(%)",
    //   key: "resRate",
    //   className: "header-white",
    //   dataIndex: "noOfPolls",
    //   ellipsis: true,
    //   width: 150,
    //   render: (data) => {
    //     const totalMembers = membersList?.length || 0;
    //     const pollCount = Number(data) || 0;

    //     if (totalMembers === 0) {
    //       return "N/A";
    //     }

    //     const percentage = (pollCount * 100) / totalMembers;
    //     return `${Math.round(percentage)}%`;
    //   },
    // },
  ];

  const groupData = useSelector(selectCurrentGroupData);

  const [allPolls, setAllPolls] = useState([]);
  const [pollsLoading, setPollsLoading] = useState(false);

  const fetchPolls = async () => {
    try {
      setPollsLoading(true);
      const pollsRef = collection(db, `polls/${groupData?.id}/poll`);
      const pollsSnapshot = await getDocs(pollsRef);

      const polls = await Promise.all(
        pollsSnapshot.docs.map(async (pollDoc) => {
          const pollData = pollDoc.data();

          let stats = {};
          let optKeys = ["op1", "op2", "op3", "op4", "op5"];
          optKeys
            .filter((key) => pollData[key] && pollData[key] !== null)
            .forEach((key) => {
              stats[pollData[key].name] =
                (pollData[key].votes * 100) / pollData.noOfPolls;
              if (isNaN(stats[pollData[key].name])) {
                stats[pollData[key].name] = 0;
              }
            });

          return { id: pollDoc.id, ...pollData, stats };
        })
      );

      polls.sort((a, b) => b.addedOn - a.addedOn);

      setAllPolls(polls);
    } catch (error) {
      console.error("Error fetching polls with entries:", error);
      setAllPolls([]);
    } finally {
      setPollsLoading(false);
    }
  };

  useEffect(() => {
    if (groupData?.id) {
      fetchPolls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData?.id]);

  return (
    <div style={{ padding: "12px" }}>
      <ScreenHeader
        title="Polls"
        elementCount={allPolls?.length}
        actions={
          <ChantButton
            onClick={() => {
              navigate("/admin/content/polls/create");
            }}
          >
            Create Poll
          </ChantButton>
        }
      />
      <div className="admin-poll-description">
        <DescriptionText>
          Polls can be created by admin/leaders in the app (coming soon to the
          dashboard). Options exist to gather additional feedback (max 150
          characters) and to show individual responses to members. You can also
          include Fans (non-members) if that role is used by the group.
        </DescriptionText>
      </div>
      <Table
        bordered
        columns={columns}
        loading={pollsLoading}
        dataSource={allPolls}
        pagination={{
          position: ["bottomLeft"],
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
        rowKey="id"
        key="id"
        scroll={{ x: 1600 }}
      />
    </div>
  );
};

export default AdminPolls;
