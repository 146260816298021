import dayjs from "dayjs";
import { getCurrencyUnicode } from "../../../../helperFunctions/util";
import { Tooltip } from "antd";
import { json2csv } from "json-2-csv";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import {
  fbUpdateStatus,
  getOrders,
  getStoreItems,
} from "../../../../services/firebaseService/endPoints/admin/store";
import logger from "../../../../utils/logger";
import {
  FULFILLMENT_STATUS_ALL,
  FULFILLMENT_STATUS_FULFILLED,
  FULFILLMENT_STATUS_REFUNDED,
  FULFILLMENT_STATUS_UNCLAIMED,
  FULFILLMENT_STATUS_UNFULFILLED,
  SHIPPING_STATUS_ALL,
  SHIPPING_STATUS_PICKUP,
  SHIPPING_STATUS_SHIP,
} from "../../../../constants/orders";
import { getDayjsFromTimestamp } from "../../../../utils/helper";

const useOrders = () => {
  const groupData = useSelector(selectCurrentGroupData);

  const [tableData, setTableData] = useState([]);
  const [editStatus, setEditStatus] = useState(false);
  const [editableOrderId, setEditableOrderId] = useState([]);
  const [editedRecords, setEditedRecords] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState("select");

  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    fulfillmentStatus: FULFILLMENT_STATUS_ALL,
    shippingType: SHIPPING_STATUS_ALL,
  });
  const [downloading, setDownloading] = useState(false);

  let groupCurrencyISO = groupData.paymentDetails
    ? groupData.paymentDetails.currency || "USD"
    : "USD";
  let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (_, selectedRows) => {
      setSelectedRows(
        selectedRows.map((item) => `${item.id}|${item.itemIndex}`)
      );
    },
  };

  const handleFilterChange = (filterKey, filterValue) => {
    setFilters((prev) => ({ ...prev, [filterKey]: filterValue }));
  };

  const handleUpdateOrder = () => {
    setOpenUpdateModal(true);
  };

  const handleRecordStatusChange = (updatedRecords) => {
    setEditedRecords((prevRecords) => ({ ...prevRecords, ...updatedRecords }));
  };

  const handleUpdateStatus = async () => {
    try {
      setStatusUpdateLoading(true);
      await fbUpdateStatus(groupData.id, editedRecords);
      fetchData();
      setEditStatus(false);
      setSelectedRows([]);
      setEditableOrderId([]);
      setEditedRecords({});
      setOpenUpdateModal(false);
      setOrderStatus("select");
    } catch (error) {
      logger.error("Error updating status:", error);
    } finally {
      setStatusUpdateLoading(false);
    }
  };

  const processTableData = useCallback((tableData) => {
    // First, create a map of id -> itemIds
    const itemGroups = tableData.reduce((acc, rec) => {
      if (!acc[rec.id]) {
        acc[rec.id] = [];
      }
      acc[rec.id].push(rec.itemId);
      return acc;
    }, {});

    let currentId = null;
    let usedIndices = [];

    return tableData.map((record) => {
      const items = itemGroups[record.id] || [];

      // Reset tracking for new group
      if (record.id !== currentId) {
        currentId = record.id;
        usedIndices = [];
      }

      const itemIndex = items.findIndex(
        (item, index) => item === record.itemId && !usedIndices.includes(index)
      );

      usedIndices.push(itemIndex);

      // Return new object instead of mutating
      return {
        ...record,
        itemIndex,
        rowSpan: itemIndex === 0 ? items.length : 0,
      };
    });
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const storeItems = await getStoreItems(groupData.id);
      const orders = await getOrders(groupData.id);

      let newTableData = [];

      orders.forEach((order) => {
        let items = order.items || [];
        let orderAmount = items.reduce(
          (acc, value) => acc + value.price * value.count,
          0
        );
        orderAmount += parseFloat(order.shippingAmount || 0);

        items.forEach((item) => {
          let itemData = { ...order };
          let requiredStoreItem = storeItems.find(
            (storeItem) => storeItem.id === item.id
          ) || { name: "" };

          itemData.itemName = requiredStoreItem.name;
          itemData.quantity = item.count;
          itemData.color = item.color;
          itemData.size = item.size;
          itemData.status = item.status || "unfulfilled";
          itemData.statusUpdateTimestamp = item.statusUpdateTimestamp || "";
          itemData.orderAmount = orderAmount;
          itemData.price = item.price;
          itemData.itemId = item.id;

          if (item.buyerInfo) {
            let addressParts = [];

            if (itemData.shippingOption === "shipping") {
              if (item.buyerInfo.fullName)
                itemData.customerName = item.buyerInfo.fullName;
              if (item.buyerInfo.address1)
                addressParts.push(item.buyerInfo.address1);
              if (item.buyerInfo.address2)
                addressParts.push(item.buyerInfo.address2);
              if (item.buyerInfo.city) addressParts.push(item.buyerInfo.city);
              if (item.buyerInfo.state) addressParts.push(item.buyerInfo.state);
              if (item.buyerInfo.country)
                addressParts.push(item.buyerInfo.country);
              if (item.buyerInfo.zip) addressParts.push(item.buyerInfo.zip);

              itemData.address = addressParts.join(", ").trim();
            }
          }

          newTableData.push(itemData);
        });
      });

      newTableData.sort((row1, row2) => {
        let timestamp1 = row1.timestamp || 0;
        let timestamp2 = row2.timestamp || 0;

        if (
          typeof row1.timestamp === "object" &&
          typeof row2.timestamp === "object"
        ) {
          return row1.id < row2.id ? 1 : -1;
        }
        if (typeof row1.timestamp === "object") return 1;
        if (typeof row2.timestamp === "object") return -1;

        return timestamp2 - timestamp1;
      });

      const finalData = processTableData(newTableData);

      setTableData(finalData);
    } catch (error) {
      logger.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = () => {
    const filteredData = tableData.filter((item) => {
      if (filters.search) {
        const searchTerm = filters.search.toLowerCase();
        const matchSearch =
          (item.itemName || "").toLowerCase().includes(searchTerm) ||
          (item.customerName || "").toLowerCase().includes(searchTerm) ||
          (item.email || "").toLowerCase().includes(searchTerm);

        if (!matchSearch) {
          return false;
        }
      }

      if (
        filters.fulfillmentStatus === FULFILLMENT_STATUS_FULFILLED &&
        (item.status === "unclaimed" ||
          item.status === "refunded" ||
          item.status === "unfulfilled")
      ) {
        return false;
      }

      if (
        filters.fulfillmentStatus === FULFILLMENT_STATUS_REFUNDED &&
        item.status !== "refunded"
      ) {
        return false;
      }

      if (
        filters.fulfillmentStatus === FULFILLMENT_STATUS_UNCLAIMED &&
        item.status !== "unclaimed"
      ) {
        return false;
      }

      if (
        filters.fulfillmentStatus === FULFILLMENT_STATUS_UNFULFILLED &&
        item.status !== "unfulfilled"
      ) {
        return false;
      }

      if (
        filters.shippingType === SHIPPING_STATUS_SHIP &&
        item.shippingOption !== "shipping"
      ) {
        return false;
      }

      if (
        filters.shippingType === SHIPPING_STATUS_PICKUP &&
        item.shippingOption !== "pickup"
      ) {
        return false;
      }

      // If all filters pass, keep the item
      return true;
    });

    const finalData = processTableData(filteredData);

    setFilteredTableData(finalData);
  };

  const handleDownloadCsv = (tableData) => {
    try {
      setDownloading(true);

      let result = [];
      let recIds = [];
      tableData.forEach((record) => {
        let date = dayjs.unix(Math.floor(record.timestamp / 1000));
        let shippingAmount = record.shippingAmount;
        if (recIds.includes(record.id)) {
          shippingAmount = "";
        } else {
          recIds.push(record.id);
        }

        const statusUpdateTime = getDayjsFromTimestamp(
          record?.statusUpdateTimestamp
        );

        let row = {
          "Order#": record.id,
          "Fulfilled?": record.status,
          Shipping: record.shippingOption,
          Date: date.isValid() ? date.format("MMM D") : "",
          Name: record.customerName,
          Item: record.itemName,
          Quantity: record.quantity,
          Size: record.size || "",
          Color: record.color || "",
          "Order Amount": record.price,
          "Shipping $": shippingAmount,
          // "Address": record.address || '',
          Address1: record.address1 || "",
          Address2: record.address2 || "",
          City: record.city || "",
          State: record.state || "",
          Country: record.country || "",
          Zip: record.zip || "",
          Email: record.email || "",
          Phone: record.phone || "",
          "Status Update Time": statusUpdateTime?.isValid()
            ? statusUpdateTime?.format("Do MMM, YYYY")
            : "",
        };
        result.push(row);
      });
      const csv = json2csv(result);

      const a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(
        new Blob([csv], { type: "text/csv" })
      );
      a.download = "orders.csv";

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      setDownloading(false);
    } catch (error) {
      setDownloading(false);
    }
  };

  let columns = [
    {
      width: 50,
      title: "Actions",
      className: "header-white",
      onCell: (record) => {
        if (record.itemIndex > 0) return { colspan: 1 };
      },
      render: (data, record) => {
        let obj = {
          children: (
            <div className="flex-center">
              {data && data.id && (
                <i
                  className="fa fa-pencil cursor-pointer ml-3"
                  onClick={() => {
                    setEditStatus(true);
                    setEditableOrderId([`${record.id}|${record.itemIndex}`]);
                  }}
                ></i>
              )}
            </div>
          ),
          props: {},
        };
        // obj.props.rowSpan = record.rowSpan;
        return obj;
      },
    },
    {
      title: "Order#",
      width: 150,
      dataIndex: "id",
      className: "header-white",
      render: (data, record) => {
        let obj = {
          children: <span>{data}</span>,
          props: {},
        };
        obj.props.rowSpan = record.rowSpan;
        return obj;
      },
      sorter: (a, b) => {
        return a.id.localeCompare(b.id);
      },
    },
    {
      title: "Fulfilled?",
      dataIndex: "status",
      className: "header-white",
      render: (data) => {
        let color =
          data === "unfulfilled" || data === "unclaimed" || data === "refunded"
            ? "red"
            : "green";
        let value = "Unfulfilled";
        switch (data) {
          case "pickedup":
            value = "Picked up";
            break;
          case "shipped":
            value = "Shipped";
            break;
          case "refunded":
            value = "Refunded";
            break;
          case "unclaimed":
            value = "Unclaimed";
            break;
          default:
        }
        return <span style={{ color: color }}>{value}</span>;
      },
      // sorter: (a, b) => a.status.localeCompare(b.status),
      onCell: (record) => {
        if (record.itemIndex > 0) {
          return { colspan: 1 };
        }
      },
    },
    {
      title: "Shipping",
      dataIndex: "shippingOption",
      className: "header-white",
      render: (data, record) => {
        let value = "Shipping";
        if (data === "pickup") {
          value = "Pick up";
        }

        let obj = {
          children: <span>{value}</span>,
          props: {},
        };
        obj.props.rowSpan = record.rowSpan;
        return obj;
      },
      // sorter: (a, b) => a.shippingOption.localeCompare(b.shippingOption),
    },
    {
      title: "Date",
      dataIndex: "timestamp",
      width: 150,
      className: "header-white",
      render: (data, record) => {
        let date = dayjs.unix(data / 1000);
        if (date.isValid()) {
          let obj = {
            children: (
              <span>
                {dayjs.unix(Math.floor(data / 1000)).format("MMM D, YYYY")}
              </span>
            ),
            props: {},
          };
          obj.props.rowSpan = record.rowSpan;

          return obj;
        }
        return "";
      },
      sorter: (a, b) => a.timestamp - b.timestamp,
    },
    {
      title: "Name",
      dataIndex: "customerName",
      className: "header-white",
      width: 200,
      render: (data, record) => {
        let obj = {
          children: (
            <div
              style={{
                whiteSpace: "nowrap",
                lineBreak: "unset",
                wordBreak: "keep-all",
                paddingRight: 20,
              }}
            >
              <span>{data}</span>
            </div>
          ),
          props: {},
        };
        // obj.props.rowSpan = record.rowSpan;
        return obj;
      },
      sorter: (a, b) => a.customerName.localeCompare(b.customerName),
    },
    {
      title: "Item",
      width: 200,
      dataIndex: "itemName",
      className: "header-white",
      render: (data) => <span>{data}</span>,
      onCell: (record) => {
        if (record.itemIndex > 0) return { colspan: 1 };
      },
      // sorter: (a, b) => a.itemName.localeCompare(b.itemName),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: 50,
      className: "header-white",
      render: (data) => <span>{data}</span>,
      onCell: (record) => {
        if (record.itemIndex > 0) return { colspan: 1 };
      },
    },
    {
      title: "Size",
      width: 200,
      dataIndex: "size",
      className: "header-white",
      render: (data) => <span>{data}</span>,
      onCell: (record) => {
        if (record.itemIndex > 0) return { colspan: 1 };
      },
    },
    {
      title: "Color",
      width: 50,
      dataIndex: "color",
      className: "header-white",
      render: (data) => <span>{data}</span>,
      onCell: (record) => {
        if (record.itemIndex > 0) return { colspan: 1 };
      },
    },
    {
      title: (
        <span>
          Order Amount
          <Tooltip
            title={
              <div style={{ maxWidth: 400 }}>
                <span>Amount does not include shipping.</span>
              </div>
            }
            placement="topLeft"
          >
            <span className="border-0">
              &nbsp;<i className="fa fa-question-circle-o"></i>
            </span>
          </Tooltip>
        </span>
      ),
      width: 140,
      className: "header-white",
      render: (record) => {
        let price = record.price;
        if (price === "included") {
          return <span>{price}</span>;
        }
        let total = parseFloat(record.price) * parseInt(record.quantity);
        total = total.toFixed(Math.round(total) === total ? 0 : 2);
        return (
          <span>
            {groupCurrency}
            {total}
          </span>
        );
      },
      onCell: (record) => {
        if (record.itemIndex > 0) return { colspan: 1 };
      },
    },

    {
      title: `Shipping ${groupCurrency}`,
      dataIndex: "shippingAmount",
      width: 110,
      className: "header-white",
      render: (data, record) => {
        let obj = {
          children: (
            <span>
              {groupCurrency}
              {data}
            </span>
          ),
          props: {},
        };
        obj.props.rowSpan = record.rowSpan;
        return obj;
      },
    },
    {
      title: "Address",
      width: 450,
      dataIndex: "address",
      className: "header-white",
      render: (data, record) => {
        let obj = {
          children: <span>{data}</span>,
          props: {},
        };
        // obj.props.rowSpan = record.rowSpan;
        return obj;
      },
    },
    {
      width: 150,
      title: "Email",
      dataIndex: "email",
      className: "header-white",
      render: (data, record) => {
        let obj = {
          children: <span>{data}</span>,
          props: {},
        };
        obj.props.rowSpan = record.rowSpan;
        return obj;
      },
    },
    {
      width: 300,
      title: "Phone",
      dataIndex: "phone",
      className: "header-white",
      render: (data, record) => {
        let obj = {
          children: <span>{data}</span>,
          props: {},
        };
        obj.props.rowSpan = record.rowSpan;
        return obj;
      },
    },
  ];

  const columnsWidth = columns.reduce((agr, item) => {
    return agr + (item.width || 0);
  }, 0);

  useEffect(() => {
    if (tableData?.length > 0) {
      applyFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, tableData]);

  useEffect(() => {
    if (groupData?.id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData?.id]);

  return {
    columns,
    columnsWidth,
    handleDownloadCsv,
    downloading,
    filteredTableData,
    selectedRows,
    handleUpdateOrder,
    filters,
    handleFilterChange,
    rowSelection,
    loading,
    editableOrderId,
    handleUpdateStatus,
    editedRecords,
    editStatus,
    setEditStatus,
    handleRecordStatusChange,
    openUpdateModal,
    setOpenUpdateModal,
    statusUpdateLoading,
    orderStatus,
    setOrderStatus,
  };
};

export default useOrders;
