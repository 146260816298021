import { Table } from "antd";
import dayjs from "dayjs";
import React, { useMemo } from "react";

const EventsByUser = ({ eventDetails, loading }) => {
  const columns = useMemo(
    () => [
      {
        title: "Event",
        width: 300,
        dataIndex: "eventDetail",
        render: (data) => data?.title,
      },
      {
        title: "Event Date",
        width: 300,
        dataIndex: "eventDetail",
        render: (data) =>
          dayjs.unix(data?.eventStartTime).format("Do MMM, YYYY"),
      },
      {
        title: "Total",
        width: 150,
        render: (_, record) =>
          (Number(record?.checkInPoint) || 0) +
          (Number(record?.volunteerPoints) || 0),
      },
      {
        title: "Attending",
        width: 150,
        dataIndex: "checkInPoint",
        render: (data) => data || 0,
      },
      {
        title: "Volunteering",
        width: 150,
        dataIndex: "volunteerPoints",
        render: (data) => data || 0,
      },
    ],
    []
  );

  const tableData = useMemo(() => {
    const data = Object?.values(eventDetails || {});
    data.sort((a, b) => {
      return b?.eventStartTime - a?.eventStartTime;
    });

    return data;
  }, [eventDetails]);

  return (
    <Table
      bordered
      columns={columns}
      headerBorderRadius={0}
      pagination={false}
      loading={loading}
      dataSource={tableData}
      rowKey="id"
      bodyStyle={{
        fontSize: window.screen.width > 500 ? "18px" : "12px",
        backgroundColor: "#ffffff",
      }}
    />
  );
};

export default EventsByUser;
