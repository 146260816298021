import { useState } from "react";

import { json2csv } from "json-2-csv";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectCurrentGroupData } from "../../../../../redux/selectors/adminData";
import { fetchMembers } from "../../../../../services/api/members";
import {
  fetchFormLayout,
  fetchPackages,
} from "../../../../../services/api/packages";
import useGlobalStore from "../../../../../store/store";
import logger from "../../../../../utils/logger";
import usePackages from "../../../../hooks/usePackages";
import { getMinPackageSeasonFromTimeline } from "../../../../../helperFunctions/util";
import dayjs from "dayjs";

const useMemberActions = (tableData) => {
  const groupData = useSelector(selectCurrentGroupData);

  const { showAddMember } = useGlobalStore((state) => state.members);
  const { formLayout } = useGlobalStore((state) => state.formLayout);

  const [downloading, setDownloading] = useState(false);

  const { currentPackages, packagesBySeason } = usePackages();

  const handleMemberRefresh = () => {
    fetchMembers(groupData.id);
    fetchPackages(groupData.id);
    fetchFormLayout(groupData.id);
  };

  const handleDownloadCsv = async () => {
    try {
      setDownloading(true);

      // Ensure customFields are correctly generated
      let customFields = Object.keys(
        formLayout?.[groupData.id]?.customFieldDetails || {}
      ).map((key) => {
        let fieldTitle =
          (formLayout?.[groupData.id]?.customFieldDetails[key] || {})
            .fieldTitle || "";
        return {
          fieldName: key,
          fieldTitle,
        };
      });

      const csv = await getFilteredMembersAsCSV(packagesBySeason, customFields);

      // Create download link
      const a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(
        new Blob([csv], { type: "text/csv" })
      );
      a.download = "members.csv";

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      logger.error("Failed to download members list: ", error);
    } finally {
      setDownloading(false); // Ensure downloading state is updated
    }
  };

  const checkTimestampFormat = (timestamp) => {
    if (
      !timestamp ||
      (typeof timestamp !== "number" && typeof timestamp !== "string")
    ) {
      return "";
    }

    const isUnix = timestamp.toString().length === 10;
    let updatedTimestamp = isUnix ? dayjs.unix(timestamp) : dayjs(timestamp);

    if (updatedTimestamp.isValid()) {
      updatedTimestamp = updatedTimestamp.format("MM-DD-YY");
    } else {
      updatedTimestamp = "";
    }

    return updatedTimestamp;
  };

  const getFilteredMembersAsCSV = async (packages, customFields) => {
    let result = [];
    let longestLengthOfChildren = 0;

    tableData.forEach((value) => {
      // Check if value.registrationDate exists, else assign an empty string

      let registrationDate = value?.registrationDate;
      let registeredOnChantDate = value?.registeredOnChantDate;

      let packageName = "";
      let packageObj = null;

      let packageSeason = null;

      if (value?.packageSeason) {
        packageSeason = value?.packageSeason || null;
      } else {
        const pkgDetails = currentPackages?.find(
          (pkg) => pkg?.id === value?.package
        );

        if (pkgDetails) {
          packageSeason = getMinPackageSeasonFromTimeline(
            pkgDetails?.packageTimeline
          );
        }
      }

      packageObj = value?.package
        ? packages.find(
            (pack) => pack?.key === `${value?.package}|${packageSeason}`
          )
        : null;

      if (packageObj) {
        packageName = packageObj?.value || "";
      }

      let name = value?.name || "";
      let lastName = name.split(" ").pop() || "";
      let firstName = name.substring(0, name.indexOf(lastName)).trim() || "";

      let birthday = "";

      if (value.birthday && Object.keys(value.birthday).length > 0) {
        birthday = `${value.birthday.month} ${value.birthday.date}`;
        if (value.birthday.year) {
          birthday = `${birthday} ${value.birthday.year}`;
        }
      }

      // const getShippingAmount = (value, groupCurrency) => {
      //   const shippingCharge =
      //     value?.shippingCharge?.value ??
      //     value?.shippingCharge?.replace?.("$", "") ??
      //     "";
      //   const shippingValue = value?.shipping?.value;

      //   if (shippingCharge) {
      //     return `${groupCurrency}${shippingCharge}`;
      //   } else if (shippingValue && shippingValue !== "0") {
      //     return `${groupCurrency}${shippingValue}`;
      //   } else {
      //     return `${groupCurrency}0`;
      //   }
      // };

      let row = {
        Name: value?.name || "",
        "First Name": firstName,
        "Last Name": lastName,
        Email: value?.email || "",
        Phone: value?.phone || "",
        Birthday: birthday || "",
        "Street Name": value?.address || "",
        Address2: value?.address2 || "",
        City: value?.city || "",
        State: value?.state || "",
        Zip: value?.zip || "",
        Country: value?.country || "",
        Package: packageName,
        "Membership Number": value?.membershipNumber || "",
        "Member Since": value?.memberSince || "",
        "Joined Chant": value?.joinedChant || "",
        "Registration Date(MM-DD-YY)": checkTimestampFormat(registrationDate),
        "Registered Date for Chant(MM-DD-YY)": checkTimestampFormat(
          registeredOnChantDate
        ),
        "Official Club Member": value?.officialClubMember || "",
        "Joined Email List?": value?.joinedEmailList ? "Yes" : "No",
        "T-shirt Size": value?.tshirtSize || "",
        "Season Ticket Section": value?.seasonTicketHolder?.season || "",
        "Section Ticket Count": value?.seasonTicketHolder?.seats || "",
      };

      if (value.childMembers && Array.isArray(value.childMembers)) {
        if (value.childMembers.length > longestLengthOfChildren) {
          longestLengthOfChildren = value.childMembers.length;
        }
        value.childMembers.forEach((child, index) => {
          const childKey = `child${index + 1}`;

          if (child && typeof child === "object") {
            row[`${childKey} Name`] = child.name ?? "";
            row[`${childKey} T-shirt Size`] = child.tshirtSize ?? "";
            row[`${childKey} Membership Number`] = child.membershipNumber ?? "";
          } else {
            row[`${childKey} Name`] = "";
            row[`${childKey} T-shirt Size`] = "";
            row[`${childKey} Membership Number`] = "";
          }
        });
      }

      row = {
        ...row,
        "Family Membership ID": value?.familyMembershipId || "",
        "Notify for Volunteer Opportunities": value?.shareVolunteerOpportunities
          ? "Yes"
          : "No",
        "Watch Party": value?.activityCount?.watchParty || "",
        "Lock Status": value?.lockStatus,
        "Referred By": value?.referredBy || "",
        Chapter: value?.chapter || "",
      };

      (customFields || []).forEach((field) => {
        row[field?.fieldTitle] = value?.[field?.fieldName] || "";
      });

      result.push(row);
    });

    // We need to add empty fields for children otherwise the csv will show undefined for those fields
    result.forEach((row) => {
      for (let i = 1; i <= longestLengthOfChildren; i++) {
        row[`child${i} Name`] = row[`child${i} Name`] || "";
        row[`child${i} T-shirt Size`] = row[`child${i} T-shirt Size`] || "";
        row[`child${i} Membership Number`] =
          row[`child${i} Membership Number`] || "";
      }
    });

    return json2csv(result);
  };

  return {
    currentPackages,
    groupData,
    showAddMember,
    downloading,
    handleMemberRefresh,
    handleDownloadCsv,
  };
};

export default useMemberActions;
