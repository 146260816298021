import React from "react";
import { Checkbox } from "antd";
import "./ChantCheckbox.css";

const ChantCheckbox = ({
  checked,
  defaultChecked,
  disabled,
  indeterminate,
  onChange,
  children,
  className,
  style,
  ...props
}) => {
  return (
    <Checkbox
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      indeterminate={indeterminate}
      onChange={onChange}
      className={`chant-checkbox ${className || ""}`}
      style={style}
      {...props}
    >
      {children}
    </Checkbox>
  );
};

export default ChantCheckbox;
