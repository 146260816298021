const createGiveawaysStore = (set) => ({
  selectedGiveawayDetails: {},

  setSelectedGiveawayDetails: (data) =>
    set((state) => ({
      giveaways: {
        ...state.giveaways,
        selectedGiveawayDetails: data,
      },
    })),

  reset: () =>
    set((state) => ({
      giveaways: {
        ...state.giveaways,
        selectedGiveawayDetails: {},
      },
    })),
});

export default createGiveawaysStore;
