import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import {
  fbSaveLocationDetails,
  getLocationDetails,
} from "../../../../services/firebaseService/endPoints/admin/content";

import { getPrimaryColor } from "../../../../utils/helper";
import { useNavigate, useParams } from "react-router-dom";
import { GOOGLE_MAPS_URL } from "../../../../config/maps";
import { toast } from "react-toastify";
import logger from "../../../../utils/logger";

const useCreateLocation = (onCloseModal) => {
  const navigate = useNavigate();
  const childRef = useRef(null);
  const { id: locationId } = useParams();

  const adminData = useSelector(selectCurrentGroupData);

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const [formData, setFormData] = useState({});
  const [errorInForm, setErrorInForm] = useState({});
  const [locationData, setLocationData] = useState({});
  const [locationImage, setLocationImage] = useState("");

  useEffect(() => {
    if (locationId) {
      handleEditMode();
    }
  }, [locationId, adminData.id]);

  let primaryColor = "";
  if (adminData.configuration && adminData.configuration.primaryColor) {
    primaryColor = getPrimaryColor(adminData.configuration.primaryColor);
  }

  const handleEditMode = async () => {
    try {
      setLoading(true);
      const data = await getLocationDetails(adminData?.id, locationId);
      const { locationImage, locationData, ...formData } = data;

      setLocationData(locationData);
      setLocationImage(locationImage);
      setFormData({ ...formData });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const mapUpdate = (e) => {
    setLocationData((prevState) => {
      const locationData = {
        ...prevState.locationData,
      };
      // placeId: e.placeId,
      locationData.address = e.address;
      locationData.placeId = e.placeId;
      locationData.longitude = e.long;
      locationData.latitude = e.lat;
      return { ...locationData };
    });
  };

  const handleMapOK = () => {
    if (childRef.current) {
      childRef.current.updateFormMapData();
    }
    setShowModal(false);
  };

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const handleModalChange = () => {
    if (!scriptLoaded) {
      const script = document.createElement("script");
      script.src = GOOGLE_MAPS_URL;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        logger.log("Google Maps script loaded successfully");
        setScriptLoaded(true);
        toggleModal();
      };
      script.onerror = (error) => {
        logger.error("Error loading Google Maps:", error);
        toast.error("Failed to load Google Maps. Please try again.");
      };
      document.head.appendChild(script);
    } else {
      toggleModal();
    }
  };

  const handleInputChange = (e, customName, customValue) => {
    const name = e?.target?.name || customName;
    const value = e?.target?.value || customValue;

    if (name) {
      setFormData((prev) => ({
        ...prev,
        [name]: value || "",
      }));
    }
  };

  const handleSubmit = () => {
    setSaving(true);

    if (!formData?.type) {
      setErrorInForm({
        isError: true,
        visible: true,
        message: "Please select a type before proceeding.",
      });

      setSaving(false);
      window.scrollTo(0, 0);
      return;
    }

    if (!formData?.name) {
      setErrorInForm({
        isError: true,
        visible: true,
        message: "Please fill in the name before proceeding.",
      });

      setSaving(false);
      window.scrollTo(0, 0);
      return;
    }

    if (!locationImage) {
      setErrorInForm({
        isError: true,
        visible: true,
        message: "Please upload a location image before proceeding.",
      });

      setSaving(false);
      window.scrollTo(0, 0);
      return;
    }

    const data = {
      ...formData,
      locationData,
      locationImage,
    };

    let promise = locationId
      ? fbSaveLocationDetails(data, adminData.id, locationId)
      : fbSaveLocationDetails(data, adminData.id);

    promise.then(() => {
      setSaving(false);

      if (locationId) {
        let result = window.confirm(
          "Please update related future events to reflect location changes. The Directory and Group Website are updated automatically."
        );
        if (result) {
          navigate("/admin/content/locations");
        }
      } else {
        navigate("/admin/content/locations");
      }

      onCloseModal && onCloseModal();
    });
  };

  const locationClear = () => {
    setLocationData({ address: "" });
  };

  const updateAddress = (address) => {
    setLocationData({ address: address });
  };
  const handleErrorMessageClose = () => {
    setErrorInForm((prev) => ({ ...prev, visible: false }));
  };

  return {
    saving,
    loading,
    childRef,
    formData,
    adminData,
    showModal,
    locationId,
    errorInForm,
    locationData,
    primaryColor,
    locationImage,
    mapUpdate,
    handleMapOK,
    toggleModal,
    handleSubmit,
    updateAddress,
    locationClear,
    setLocationImage,
    handleInputChange,
    handleModalChange,
    handleErrorMessageClose,
  };
};

export default useCreateLocation;
