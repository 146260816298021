import React, { useEffect, useRef, useState } from "react";
import ScreenHeader from "../../../CommonComponents/ScreenHeader";
import ChantButton from "../../../CommonComponents/ChantButton/ChantButton";
import DescriptionText from "../../../CommonComponents/DescriptionText";
import { Spin, DatePicker } from "antd";
import UploadConfirmationModal from "./UploadConfirmationModal";
import { deleteGalleryImage } from "../../../services/firebaseService/endPoints/clubAdmin/gallery";
import {
  selectCurrentGroupData,
  selectUserDetails,
} from "../../../redux/selectors/adminData";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import ImageGallery from "../../../CommonComponents/ImageGallery";
import imageCompression from "browser-image-compression";
import {
  getImages,
  uploadImageSizes,
} from "../../../services/firebaseService/endPoints/admin/gallery";
import { collection, doc, writeBatch } from "firebase/firestore";
import { db } from "../../../services/firebaseService/connection";
import logger from "../../../utils/logger";
import { setGettingStartedItemStatus } from "../../../redux/actions/adminData";
import {
  GUIDE_SETUP,
  TOPIC_UPLOAD_PHOTOS,
} from "../../../constants/gettingStarted";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import "./Gallery.css";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const MAX_FILE_COUNT = 100;

const AdminGallery = () => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;

  const [allImages, setAllImages] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [files, setFiles] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imgDeleteLoading, setImgDeleteLoading] = useState(false);
  const inputRef = useRef(null);

  const groupData = useSelector(selectCurrentGroupData);
  const userData = useSelector(selectUserDetails);

  const extractPathFromUrl = (url) => {
    try {
      const decodedUrl = decodeURIComponent(url);
      const startIndex = decodedUrl.indexOf("/o/") + 3;
      const endIndex = decodedUrl.indexOf("?");

      return decodedUrl.substring(startIndex, endIndex);
    } catch (error) {
      console.error("Error extracting path:", error);
      return null;
    }
  };

  const deleteSelectedImage = async (imageId, urlLarge, urlSmall) => {
    try {
      setImgDeleteLoading(true);

      let pathLarge = "";
      let pathSmall = "";

      if (urlLarge && urlLarge?.length > 0) {
        pathLarge = extractPathFromUrl(urlLarge);
        await deleteGalleryImage(pathLarge, groupData?.id, imageId);
      }

      if (urlSmall && urlSmall?.length > 0) {
        pathSmall = extractPathFromUrl(urlSmall);
        await deleteGalleryImage(pathSmall, groupData?.id, imageId);
      }

      setImgDeleteLoading(false);

      fetchImages();
    } catch (error) {
      setImgDeleteLoading(false);
    }
  };

  const renderImages = () => {
    const images = filteredImages.sort((data1, data2) => {
      const date1 = data1.uploadDate || 0;
      const date2 = data2.uploadDate || 0;

      return date2 - date1;
    });

    const imagesFinal = images.map((img) => ({
      id: img.id,
      src: img.imageUrl,
      srcLg: img.imageUrlLg,
      width: img.width,
      height: img.height,
      tags: [
        {
          value: dayjs.unix(img.uploadDate).format("Do MMM, YYYY"),
          title: dayjs.unix(img.uploadDate).format("Do MMM, YYYY"),
        },
      ],
      onDelete: () => deleteSelectedImage(img.id, img.imageUrlLg, img.imageUrl),
    }));

    return (
      <ImageGallery images={imagesFinal} imgDeleteLoading={imgDeleteLoading} />
    );
  };

  const handleUpload = async (e) => {
    let { files } = e.target;
    let readFiles = [];

    files = Array.from(files);

    if (files.length > MAX_FILE_COUNT) {
      files = files.slice(0, MAX_FILE_COUNT);
    }

    let event = new Event("loaddone");
    for (let file of files) {
      let reader = new FileReader();

      reader.addEventListener("loadend", (e) => {
        readFiles.push({ file: file, data: reader.result });
        if (readFiles.length === files.length) {
          document.dispatchEvent(event);
        }
      });
      reader.readAsDataURL(file);
    }

    document.addEventListener("loaddone", () => {
      setFiles(readFiles);
      setShowUploadModal(true);
    });
  };

  const handleUploadImages = async () => {
    setIsUploading(true);

    let path = `/${groupData?.groupName}/gallery`;
    let uploadPromises = [];

    const largeImgOptions = {
      maxSizeMB: 2.5,
      maxWidthOrHeight: 2560,
      useWebWorker: true,
      alwaysKeepResolution: true,
      preserveExif: true,
    };

    const smallImgOptions = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 2560,
      useWebWorker: true,
      alwaysKeepResolution: true,
      preserveExif: true,
    };

    for (var i = 0; i < files.length; i++) {
      await new Promise((done) => setTimeout(() => done(), 10));
      let file = files[i];
      let originalImage = file.file;

      let lgCompressedImage = await imageCompression(
        originalImage,
        largeImgOptions
      );
      let smCompressedImage = await imageCompression(
        originalImage,
        smallImgOptions
      );

      uploadPromises.push(
        uploadImageSizes(
          smCompressedImage,
          lgCompressedImage,
          `${path}/${dayjs().valueOf()}`,
          dayjs().unix(),
          file.file.lastModified || dayjs().unix()
        )
      );

      let progressPercent = ((i + 1) * 100) / files.length;

      setProgress(progressPercent);
    }

    let batch = writeBatch(db);

    let res = await Promise.all(uploadPromises);

    let imagesDataPromises = res.map(({ downloadUrlSmall }) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          resolve({
            [downloadUrlSmall]: {
              width: img.width,
              height: img.height,
            },
          });
        };
        img.onerror = reject;
        img.src = downloadUrlSmall;
      });
    });

    let imagesData = (await Promise.all(imagesDataPromises)).reduce(
      (acc, val) => {
        return {
          ...acc,
          ...val,
        };
      },
      {}
    );

    res.forEach(
      ({ downloadUrlSmall, downloadUrlLarge, imageDate, timestamp }) => {
        let dimensions = imagesData[downloadUrlSmall];

        let data = {
          favBy: [],
          imageUrl: downloadUrlSmall,
          imageUrlLg: downloadUrlLarge,
          updatedOn: dayjs().unix(),
          uploadDate: timestamp,
          uploaderName: userData?.profile?.displayName,
          userDocId: userData?.profile?.uid,
          dimensions: dimensions || {},
        };

        if (imageDate) {
          data.imageDate = dayjs(imageDate).unix();
        }

        let docRef = doc(collection(db, "gallery", groupData?.id, "images"));

        batch.set(docRef, data);
      }
    );

    batch
      .commit()
      .then(() => {
        fetchImages();
      })
      .catch((err) => {
        logger.error(err);
      });
  };

  const fetchImages = async () => {
    setLoading(true);

    try {
      const images = await getImages(groupData?.id);

      if (images?.length > 0) {
        dispatch(
          setGettingStartedItemStatus(
            groupData?.id,
            GUIDE_SETUP,
            TOPIC_UPLOAD_PHOTOS,
            true
          )
        );
      }

      setAllImages(images);
      setFilteredImages(images);
      setFiles([]);
      setShowUploadModal(false);
      setLoading(false);
      setIsUploading(false);
    } catch (error) {
      logger.error("Error fetching images:", error);
      setLoading(false);
    }
  };

  const filterImagesByDate = (value) => {
    if (!value || value.length !== 2) {
      setFilteredImages([]);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setFilteredImages(allImages);
      }, 200);
      setFilteredImages(allImages);
      return;
    }

    const [start, end] = value;

    const filteredData = allImages.filter((data) => {
      const uploadDate = dayjs.unix(data.uploadDate);

      return (
        uploadDate.isSameOrAfter(start, "month") &&
        uploadDate.isSameOrBefore(end, "month")
      );
    });

    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setFilteredImages(filteredData);
    }, 200);
  };

  useEffect(() => {
    if (groupData?.id) {
      fetchImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData?.id]);

  return (
    <div className="col">
      <div style={{ padding: "12px" }}>
        <ScreenHeader
          title="Gallery"
          actions={
            <div>
              <ChantButton onClick={() => inputRef?.current.click()}>
                Upload
              </ChantButton>
            </div>
          }
        />

        <div className="gallery-description">
          <DescriptionText>
            Upload up to 100 photos at a time (try fewer if slow connection).
            Images compressed to max 2.5MB. Video support coming soon.
          </DescriptionText>
        </div>

        <div className="gallery-filters-header">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <RangePicker
              picker="month"
              value={dateRange}
              onChange={(value) => {
                setDateRange(value);
                filterImagesByDate(value);
              }}
              disabledDate={(current) => {
                return current && current > dayjs().endOf("month");
              }}
            />
            <div
              style={{
                visibility: "hidden",
                position: "absolute",
                pointerEvents: "none",
              }}
            >
              <input
                ref={(ref) => {
                  inputRef.current = ref;
                }}
                type="file"
                multiple
                accept="image/*"
                style={{ height: 0, width: 0 }}
                onChange={handleUpload}
                onBlur={() => {
                  inputRef?.current.blur();
                }}
              />
            </div>
          </div>
        </div>

        <div>
          {loading ? (
            <div
              className="flex-center"
              style={{ width: "100%", minHeight: "300px" }}
            >
              {loading && <Spin />}
            </div>
          ) : (
            renderImages()
          )}
        </div>

        <UploadConfirmationModal
          showUploadModal={showUploadModal}
          files={files}
          isUploading={isUploading}
          progress={progress}
          removeImage={(index) => {
            setFiles(files.splice(index, 1));
          }}
          onCancel={() => {
            setFiles([]);
            setShowUploadModal(false);
          }}
          onUploadImages={isUploading ? () => {} : handleUploadImages}
        />
      </div>
    </div>
  );
};

export default AdminGallery;
