import dayjs from "dayjs";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import logger from "./logger";

export const isMobile = () => {
  const userAgent = window?.navigator?.userAgent?.toLowerCase();
  const mobileRegex =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;

  const isNarrowScreen = window?.innerWidth <= 768;

  return mobileRegex?.test(userAgent) || isNarrowScreen;
};

export const convertStringObjectToJson = (invalidJsonString) => {
  if (invalidJsonString && invalidJsonString?.length > 0) {
    const jsonString = invalidJsonString.replace(
      /([{,]\s*)([a-zA-Z0-9_]+)(\s*:)/g,
      '$1"$2"$3'
    );

    const validJsonString = jsonString?.replace(
      /:\s*([a-zA-Z0-9_]+)/g,
      ': "$1"'
    );

    return validJsonString ? JSON.parse(validJsonString) : {};
  }
  return {};
};

export const getPrimaryColor = (data) => {
  let { r, g, b } = data;
  return `rgb(${r}, ${g}, ${b})`;
};

export const sortObjectByString = (a, b, property) => {
  const itemA = a?.[property] ? String(a?.[property]) : "";
  const itemB = b?.[property] ? String(b?.[property]) : "";
  return itemA.localeCompare(itemB);
};

export const sortObjectByNumber = (a, b, property) => {
  const itemA = a?.[property] || 0;
  const itemB = b?.[property] || 0;
  return itemA - itemB;
};

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component
        {...props}
        params={params}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export const toDayjs = (value) => {
  if (dayjs.isDayjs(value)) return value;

  if (value == null) return null;

  try {
    return dayjs(value);
  } catch (error) {
    logger.warn("Failed to convert to dayjs object:", error);
    return null;
  }
};

export const getDayjsFromTimestamp = (timestamp) => {
  if (
    !timestamp ||
    (typeof timestamp !== "number" && typeof timestamp !== "string")
  ) {
    return null;
  }

  const isUnix = timestamp.toString().length === 10;
  const parsedDate = isUnix ? dayjs.unix(timestamp) : dayjs(timestamp);

  return parsedDate.isValid() ? parsedDate : null;
};
