import DeleteIcon from "../images/nav-icons/baseline_delete_black_24dp.png";
import CopyIcon from "../images/copy-solid.svg";
import ChantLogoIcon from "../images/chantLogo.svg";
import MemberRoleTooltipIcon from "../images/member-role-tooltip.png";
import StadiumIcon from "../images/nav-icons/stadium_black_24dp.svg";
import NewFeatureIcon from "../images/new-feat.png";
import BetaFeatureIcon from "../images/beta-feat.png";
import AutoRenewalIcon from "../images/auto_renewal_stripe_tooltip.png";
import BulkIcon from "../images/bulk.png";
import PromoteBorder from "../images/border-background.png";
import PromoteBorderless from "../images/promote-borderless.png";
import ChantBrandmark from "../images/Chant_Brandmark_RGB_OffWhite_Registration.png";
import TextSnippetBlack from "../images/text_snippet_black_24dp.svg";
import ArchiveIcon from "../images/nav-icons/baseline_archive_black_24dp.png";
import UnarchiveIcon from "../images/nav-icons/baseline_unarchive_black_24dp.png";
import DownloadIcon from "../images/DownloadIcon.svg";
import CloseBorderIcon from "../images/close-borderless.svg";
import CloseBorderlessIcon from "../images/close-borderless.svg";
import TickBorderlessIcon from "../images/tick-borderless.svg";
import AppStoreBadgeIcon from "../images/appstore-badge.png";
import GooglePlayIcon from "../images/google-play-badge.png";
import ChantBrandmarkTaglineIcon from "../images/Chant_Brandmark_Tagline_RGB_Black.png";
import NodeAddIcon from "../images/node-add.svg";
import GripVertical from "../images/grip-vertical.svg";
import ReloadIcon from "../images/Reload.svg";
import EditIcon from "../images/Edit.svg";

import MembersIcon from "../images/nav-icons/baseline_people_white_48dp.png";
import ContentIcon from "../images/nav-icons/baseline_post_add_white_48dp.png";
import StoreIcon from "../images/nav-icons/outline_shopping_cart_white_48dp.png";
import TicketsIcon from "../images/nav-icons/outline_local_activity_white_24dp.png";
import WebsiteIcon from "../images/nav-icons/baseline_web_white_48dp.png";
import DirectoryIcon from "../images/nav-icons/baseline_travel_explore_white_24dp.png";
import HelpIcon from "../images/nav-icons/outline_info_white_48dp.png";
import SettingsIcon from "../images/nav-icons/baseline_settings_white_48dp.png";
import GalleryIcon from "../images/nav-icons/photo_library_FILL0_wght400_GRAD0_opsz48.svg";
import GiveawayIcon from "../images/nav-icons/redeem_FILL0_wght400_GRAD0_opsz48.svg";
import LogoutIcon from "../images/nav-icons/baseline_logout_white_24dp.png";
import ReceiptIcon from "../images/receipt.svg";
import TransactionIcon from "../images/link.svg";
import AddIcon from "../images/Add.svg";
import NoteIcon from "../images/Comment-Note.svg";
import FilterIcon from "../images/Filter.svg";
import PollsIcon from "../images/nav-icons/insert_chart_FILL0_wght400_GRAD0_opsz48.svg";
import AutoRenewIcon from "../images/autorenew.svg";
import AppIcon1 from "../images/app-icons/1.png";
import AutoRenewLink from "../images/AutoRenewLink.svg";
import AutoRenewCancelled from "../images/autorenew-cancelled.svg";

const getIcon = (width = 22, src, style) => (
  <img height={width} width={width} src={src} alt="icon" style={{ ...style }} />
);

export {
  getIcon,
  AutoRenewLink,
  AutoRenewIcon,
  PollsIcon,
  NoteIcon,
  FilterIcon,
  EditIcon,
  AddIcon,
  DeleteIcon,
  CopyIcon,
  ChantLogoIcon,
  MemberRoleTooltipIcon,
  StadiumIcon,
  NewFeatureIcon,
  BetaFeatureIcon,
  AutoRenewalIcon,
  BulkIcon,
  PromoteBorder,
  PromoteBorderless,
  ChantBrandmark,
  TextSnippetBlack,
  ArchiveIcon,
  UnarchiveIcon,
  CloseBorderIcon,
  CloseBorderlessIcon,
  AppStoreBadgeIcon,
  GooglePlayIcon,
  ChantBrandmarkTaglineIcon,
  TickBorderlessIcon,
  NodeAddIcon,
  MembersIcon,
  ContentIcon,
  StoreIcon,
  TicketsIcon,
  WebsiteIcon,
  DirectoryIcon,
  HelpIcon,
  SettingsIcon,
  GalleryIcon,
  GiveawayIcon,
  LogoutIcon,
  GripVertical,
  ReceiptIcon,
  TransactionIcon,
  DownloadIcon,
  ReloadIcon,
  AppIcon1,
  AutoRenewCancelled,
};
