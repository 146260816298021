import React from "react";
import { Modal, Form, Input, Select } from "antd";
import moment from "moment";
import "./AddMemberForm.css";
import ChantButton from "../../../../../../CommonComponents/ChantButton/ChantButton";
import useAddMemberForm from "./useAddMemberForm";

const AddMemberForm = ({ showForm, handleAddMemberFormModal }) => {
  const {
    form,
    years,
    Months,
    userRoles,
    formLayout,
    countryNames,
    selectedMonth,
    submitLoading,
    packagesBySeason,
    handleSubmit,
    handleCancel,
    getCustomField,
    setSelectedMonth,
  } = useAddMemberForm(handleAddMemberFormModal);

  return (
    <Modal
      title="Add Member"
      open={showForm}
      onCancel={handleCancel}
      width={1100}
      footer={null}
      className="add-member-form"
    >
      <div className="add-member-container">
        <div className="form-section">
          <Form
            form={form}
            layout="vertical"
            className="member-form"
            disabled={submitLoading}
          >
            <div className="form-columns">
              {/* Left Column */}
              <div className="form-column">
                <Form.Item
                  label={<span className="form-label">Full Name</span>}
                  name="fullName"
                  rules={[
                    { required: true, message: "Please enter full name" },
                  ]}
                >
                  <Input placeholder="Enter full name" />
                </Form.Item>

                <Form.Item
                  label={<span className="form-label">Email</span>}
                  name="email"
                  rules={[
                    { required: true, message: "Please enter email" },
                    { type: "email", message: "Please enter a valid email" },
                  ]}
                >
                  <Input placeholder="Enter email" />
                </Form.Item>

                <Form.Item
                  label={<span className="form-label">Phone</span>}
                  name="phone"
                >
                  <Input placeholder="Enter phone number" maxLength={10} />
                </Form.Item>

                <Form.Item
                  label={<span className="form-label">Birthday</span>}
                  name="birthday"
                >
                  <div className="birthday-inputs">
                    <Select
                      placeholder="Month"
                      style={{ width: 120 }}
                      onChange={(value) => {
                        setSelectedMonth(value);
                        form.setFieldsValue({
                          birthday: {
                            ...form.getFieldValue("birthday"),
                            month: value.toString(),
                          },
                        });
                      }}
                    >
                      {Object.keys(Months).map((month) => (
                        <Select.Option key={month} value={month}>
                          {month}
                        </Select.Option>
                      ))}
                    </Select>
                    <Select
                      placeholder="Date"
                      style={{ width: 80 }}
                      onChange={(value) => {
                        form.setFieldsValue({
                          birthday: {
                            ...form.getFieldValue("birthday"),
                            date: value.toString(),
                          },
                        });
                      }}
                    >
                      {Array.from(
                        { length: Months[selectedMonth] || 31 },
                        (_, i) => (
                          <Select.Option key={i + 1} value={i + 1}>
                            {i + 1}
                          </Select.Option>
                        )
                      )}
                    </Select>
                    <Select
                      placeholder="Year"
                      style={{ width: 100 }}
                      onChange={(value) => {
                        form.setFieldsValue({
                          birthday: {
                            ...form.getFieldValue("birthday"),
                            year: value.toString(),
                          },
                        });
                      }}
                    >
                      {Array.from(
                        { length: moment().year() - 1922 },
                        (_, i) => (
                          <Select.Option
                            key={1922 + i + 1}
                            value={1922 + i + 1}
                          >
                            {1922 + i + 1}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </div>
                </Form.Item>

                <Form.Item
                  label={<span className="form-label">User Role</span>}
                  name="userRole"
                >
                  <Select>
                    {userRoles.map((role) => (
                      <Select.Option key={role} value={role}>
                        {role}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={<span className="form-label">Notes</span>}
                  name="notes"
                >
                  <Input.TextArea placeholder="Enter notes" rows={4} />
                </Form.Item>
              </div>

              {/* Right Column - Main Form */}
              <div className="form-column">
                <Form.Item
                  label={<span className="form-label">Street Name</span>}
                  name="address1"
                >
                  <Input placeholder="Enter street name" />
                </Form.Item>

                <Form.Item
                  label={<span className="form-label">Address 2</span>}
                  name="address2"
                >
                  <Input placeholder="Enter address 2" />
                </Form.Item>
                <Form.Item
                  label={<span className="form-label">Hometown</span>}
                  name="hometown"
                >
                  <Input placeholder="Enter hometown" />
                </Form.Item>
                <Form.Item
                  label={<span className="form-label">City</span>}
                  name="city"
                >
                  <Input placeholder="Enter city" />
                </Form.Item>

                <Form.Item
                  label={
                    <span className="form-label">State/Province/Region</span>
                  }
                  name="state"
                >
                  <Input placeholder="Enter state" />
                </Form.Item>

                <Form.Item
                  label={<span className="form-label">Country</span>}
                  name="country"
                >
                  <Select>
                    {countryNames.map((country) => (
                      <Select.Option key={country.name} value={country.name}>
                        {country.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={<span className="form-label">Zip/Postal</span>}
                  name="zip"
                >
                  <Input placeholder="Enter zip code" />
                </Form.Item>
              </div>

              <div className="form-column">
                <Form.Item
                  label={<span className="form-label">Package</span>}
                  name="package"
                >
                  <Select>
                    {packagesBySeason?.map((pkg) => (
                      <Select.Option key={pkg.key} value={pkg.key}>
                        {pkg.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={<span className="form-label">Member Since</span>}
                  name="memberSince"
                >
                  <Select>
                    {years.map((year) => (
                      <Select.Option key={year.year} value={year.year}>
                        {year.year}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={
                    <span className="form-label">Official Club Member</span>
                  }
                  name="officialClubMember"
                >
                  <Input placeholder="Official Club Member #" />
                </Form.Item>

                <Form.Item
                  label={<span className="form-label">Referrer</span>}
                  name="referrer"
                >
                  <Input placeholder="Referrer #" />
                </Form.Item>

                <Form.Item
                  label={
                    <span className="form-label">Season Ticket Holder</span>
                  }
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Form.Item name={["seasonTicketHolder", "section"]} noStyle>
                      <Input placeholder="Section" />
                    </Form.Item>
                    <Form.Item name={["seasonTicketHolder", "seats"]} noStyle>
                      <Input placeholder="No. of seats" />
                    </Form.Item>
                  </div>
                </Form.Item>

                <Form.Item
                  label={<span className="form-label">Chapters</span>}
                  name="chapter"
                >
                  <Select>
                    {formLayout?.chapters.map((item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            {/* Custom Fields Section */}
            {formLayout?.customFieldDetails && (
              <div className="custom-fields-section">
                <h3 className="section-title">Additional Information</h3>
                <div className="custom-fields-grid">
                  {Object.keys(formLayout?.customFieldDetails).map((key) => {
                    let value = formLayout?.customFieldDetails[key];
                    return getCustomField(value, key, form);
                  })}
                </div>
              </div>
            )}

            <div className="form-footer">
              <ChantButton type="secondary" onClick={handleCancel}>
                Cancel
              </ChantButton>
              <ChantButton
                type="primary"
                onClick={handleSubmit}
                loading={submitLoading}
                disabled={submitLoading}
              >
                Save
              </ChantButton>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default AddMemberForm;
