import React from "react";
import { Modal } from "antd";
import ChantButton from "../../../../../../CommonComponents/ChantButton/ChantButton";
import "./AddMemberOptions.css";
import { selectCurrentGroupData } from "../../../../../../redux/selectors/adminData";
import { useSelector } from "react-redux";
import { getMyId } from "../../../../../../services/firebaseService/endPoints/admin/members";

const AddMemberOptions = ({
  showModal,
  handleAddMemberOptionsModal,
  handleAddMemberFormModal,
}) => {
  const handleCancel = () => {
    handleAddMemberOptionsModal(false);
  };
  const groupData = useSelector(selectCurrentGroupData);

  const handleFullAdd = () => {
    let url = `https://group.chant.fan/${groupData?.groupName.replace(/ /g, "").replaceAll("#", "%23").toLowerCase()}/join/addMember?id=${getMyId()}`;
    window.open(url);
    handleAddMemberOptionsModal(false);
  };

  const handleQuickAdd = () => {
    handleAddMemberFormModal(true);
    handleAddMemberOptionsModal(false);
  };

  return (
    <Modal
      title="Add Member"
      open={showModal}
      onCancel={handleCancel}
      width={600}
      footer={null}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <div className="add-member-option-container">
          <div style={{ display: "flex", alignItems: "center" }}>
            <ChantButton
              type="primary"
              onClick={handleQuickAdd}
              style={{ width: "7rem" }}
            >
              Quick Add
            </ChantButton>
          </div>
          <p style={{ marginBottom: 0 }}>
            Only name and email required. Will not create an entry in Orders or
            Purchases.
          </p>
        </div>

        <div className="add-member-option-container">
          <div style={{ display: "flex", alignItems: "center" }}>
            <ChantButton
              style={{ width: "7rem" }}
              type="primary"
              onClick={handleFullAdd}
            >
              Full Add
            </ChantButton>
          </div>

          <p style={{ marginBottom: 0 }}>
            Uses the full web member package flow, bypassing Stripe. Creates an
            entry in Orders (when relevant) and Purchases.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default AddMemberOptions;
