import React, { useState } from "react";
import { Modal, Spin } from "antd";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../services/firebaseService/connection";

const CreateColorModal = ({
  currentGroup,
  visible,
  hideModal,
  colors,
  setColors,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const updateObj = { colors };
      await setDoc(doc(db, "store", currentGroup), updateObj, { merge: true });
      hideModal();
    } catch (error) {
      console.error("Error updating document:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleColorChange = (index, value) => {
    const updatedColors = [...colors];
    updatedColors[index] = value;
    setColors(updatedColors);
  };

  const handleAddColor = () => {
    setColors([...colors, ""]);
  };

  const handleRemoveColor = (index) => {
    const updatedColors = colors.filter((_, i) => i !== index);
    setColors(updatedColors);
  };

  return (
    <Modal width={700} onCancel={hideModal} open={visible} footer={null}>
      <div style={{ width: "100%", padding: 30 }}>
        <div className="my-row">
          <span style={{ fontSize: 22 }}>Set Colors</span>
        </div>
        <div className="my-row mt-3">
          <p>Create colors that can be applied to any product in inventory.</p>
        </div>
        <div className="my-row mt-5">
          <span style={{ fontSize: 22 }}>Color</span>
        </div>
        <div className="col-md-5 mt-2">
          <div className="my-row">
            <ul
              style={{ listStyle: "none", paddingLeft: "0px", width: "100%" }}
            >
              {colors.map((color, index) => (
                <li key={index} style={{ marginTop: "5px" }}>
                  <div
                    className="my-row"
                    style={{ justifyContent: "space-between" }}
                  >
                    <input
                      type="text"
                      value={color}
                      onChange={(e) => handleColorChange(index, e.target.value)}
                      style={{
                        border: "1px solid",
                        boxShadow: "0px 0px",
                        width: "90%",
                      }}
                    />
                    <span
                      style={{
                        cursor: "pointer",
                        height: 20,
                        width: 20,
                        textAlign: "center",
                      }}
                      onClick={() => handleRemoveColor(index)}
                    >
                      x
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <span
            style={{ textDecoration: "underline" }}
            onClick={handleAddColor}
          >
            +Add color
          </span>
        </div>
        <div className="my-row justify-content-center mt-5 mb-2">
          <button
            className="btn btn-success"
            tabIndex="14"
            type="success"
            onClick={handleSubmit}
          >
            {loading ? <Spin size="small" spinning={true} /> : "Submit"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateColorModal;
