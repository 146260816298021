import React from "react";
import { downloadImage } from "../../../services/firebaseService/endPoints/clubAdmin/gallery";
import { DeleteIcon, DownloadIcon } from "../../../utils/icons.jsx";
import { Spin } from "antd";

class ImageViewer extends React.Component {
  constructor(props) {
    super(props);
  }

  handleDownload = () => {
    downloadImage(this.props.image.srcLg || this.props.image.src);
  };

  handleDelete = () => {
    this.props.deleteSelectedImage(
      this.props.image.id,
      this.props.image.srcLg,
      this.props.image.src
    );
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "90vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={this.props.onReturn}
          >
            <div
              style={{
                height: 12,
                width: 12,
                borderLeft: "3px solid #000000",
                borderBottom: "3px solid #000000",
                transform: "rotate( 45deg )",
                marginRight: 20,
              }}
            ></div>
            <span>Back</span>
          </div>
          <div
            style={{
              display: "flex",
              gap: "12px",
            }}
          >
            <div className="flex-center">
              <img
                style={{ cursor: "pointer" }}
                alt="download-image"
                height={20}
                width={20}
                src={DownloadIcon}
                onClick={this.handleDownload}
              />
            </div>
            <div className="flex-center">
              {this.props.deleteLoading ? (
                <Spin />
              ) : (
                <img
                  style={{ cursor: "pointer" }}
                  alt="delete-image"
                  height={25}
                  width={25}
                  src={DeleteIcon}
                  onClick={this.handleDelete}
                />
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "70vw",
              height: "80vh",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={this.props.image.srcLg || this.props.image.src}
              alt="large-img"
              style={{
                maxWidth: "70vw",
                maxHeight: "80vh",
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ImageViewer;
