import React from "react";
import "./Orders.css";
import ChantButton from "../../../../CommonComponents/ChantButton/ChantButton";
import { Popconfirm, Spin } from "antd";
import { DownloadIcon } from "../../../../utils/icons.jsx";
import { Button } from "antd";

const OrderActions = ({
  tableData,
  handleUpdateOrder,
  handleDownloadCsv,
  downloading,
  showUpdateOrders,
}) => {
  return (
    <div className="orders-actions-container">
      <div>
        <h3
          style={{
            marginBottom: "0px",
            display: "flex",
            gap: "6px",
          }}
        >
          <span>Orders </span>
          {tableData && tableData.length >= 0 && (
            <div>
              <span
                style={{ fontSize: "18px", color: "gray", fontWeight: "400" }}
              >{`(${tableData.length})`}</span>
            </div>
          )}
        </h3>
      </div>

      <div className="orders-action-buttons">
        <Popconfirm
          title="Download all orders list?"
          onConfirm={() => handleDownloadCsv(tableData)}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
          trigger="click" // Explicitly set trigger type
        >
          <div className="icon-container" style={{ cursor: "pointer" }}>
            {downloading ? (
              <Spin style={{ fontSize: "20px" }} />
            ) : (
              <img src={DownloadIcon} alt="icon" style={{ fontSize: "20px" }} />
            )}
          </div>
        </Popconfirm>

        {showUpdateOrders && (
          <ChantButton onClick={handleUpdateOrder}>Update Orders</ChantButton>
        )}
      </div>
    </div>
  );
};

export default OrderActions;
