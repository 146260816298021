import React, { useState, useEffect, useRef } from "react";
import "./DescriptionText.css";

const DescriptionText = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      const { scrollHeight } = textRef.current;
      const lineHeight = parseFloat(
        getComputedStyle(textRef.current).lineHeight
      );
      const maxLines = 2; // Adjust this if you want to change the line limit
      if (scrollHeight > lineHeight * maxLines) {
        setShowToggle(true);
      }
    }
  }, [children]);

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="description-text-container">
      <p
        ref={textRef}
        className={`description-text ${isExpanded ? "expanded" : "collapsed"}`}
      >
        {children}
      </p>
      {showToggle && (
        <span className="toggle-view" onClick={toggleExpanded}>
          {isExpanded ? "Show less" : "View more"}
        </span>
      )}
    </div>
  );
};

export default DescriptionText;
