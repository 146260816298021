import React, { useState } from "react";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import Lightbox from "./Lightbox";
import "./ImageGallery.css";
import { Popconfirm, Spin } from "antd";
import { downloadImage } from "../../services/firebaseService/endPoints/clubAdmin/gallery";

const ImageGallery = ({ images, imgDeleteLoading }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  const handleDownload = (imgsrc) => {
    downloadImage(imgsrc);
  };

  return (
    <div className="gallery-container">
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div
            key={index}
            className="gallery-item"
            onClick={() => openLightbox(index)}
          >
            <img
              src={image.src}
              alt={`gallery img ${index + 1}`}
              className="gallery-image"
            />
            <div className="image-overlay">
              <p className="upload-date">{image?.tags?.[0]?.title}</p>
            </div>
            {imgDeleteLoading ? (
              <Spin />
            ) : (
              <div className="img-overlay-actions">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownload(image.srcLg || image.src);
                  }}
                  className="download-button"
                >
                  <DownloadOutlined />
                </button>

                <Popconfirm
                  title="Delete image?"
                  onConfirm={(e) => {
                    e.stopPropagation();
                    if (image.onDelete) image.onDelete();
                  }}
                  onCancel={(e) => e.stopPropagation()}
                  okButtonProps={{
                    loading: imgDeleteLoading,
                    disabled: imgDeleteLoading,
                  }}
                  cancelButtonProps={{ disabled: imgDeleteLoading }}
                  okText="Yes"
                  cancelText="No"
                >
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="delete-button"
                  >
                    <DeleteOutlined />
                  </button>
                </Popconfirm>
              </div>
            )}
          </div>
        ))}
      </div>

      {lightboxOpen && (
        <Lightbox
          images={images}
          lightboxOpen={lightboxOpen}
          setLightboxOpen={setLightboxOpen}
          currentImageIndex={currentImageIndex}
          imgDeleteLoading={imgDeleteLoading}
          setCurrentImageIndex={setCurrentImageIndex}
        />
      )}
    </div>
  );
};

export default ImageGallery;
