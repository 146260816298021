import React, { useState, useEffect } from "react";
import { doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
import { Tooltip, Spin } from "antd";
import { useSelector } from "react-redux";
import CreateColorModal from "./CreateColorModal";
import StoreSettingsSelect from "./StoreSettingsSelect";
import "./StoreSettings.css";
import { db } from "../../../../services/firebaseService/connection";
import { FSelect } from "../../../commons/formFields/FSelect";
import { FInput } from "../../../commons/formFields/FInput";
import { postUpdateCDNUtil } from "../../../../helperFunctions/util";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import ResponsiveMobileTab from "../../../../CommonComponents/ResponsiveMobileTab/ResponsiveMobileTab";
import logger from "../../../../utils/logger";

const shippingOptions = [
  { value: "pickup", label: "Pick up only" },
  { value: "shipping", label: "Shipping only" },
  { value: "both", label: "Option of shipping or pickup" },
];

const countries = [
  "United States",
  "Canada",
  "United Kingdom",
  "France",
  "Germany",
  "Italy",
  "Spain",
];

const ProcessingFeeTypes = [
  "Group pays (Fees absorbed by group)",
  "Member pays (Fees added to total)",
  "Ask Member to pay (Request member pay fee but not required)",
];

const StoreSettings = () => {
  const [state, setState] = useState({
    deliveryOption: "",
    shippingNote: "",
    pickupNote: "",
    publishStandalone: false,
    storeFeeOption: "",
    storeIntroText: "",
    shippingCountries: [],
    loading: false,
    showModal: false,
    navigationLabel: "",
    storeUrl: "",
  });
  const [colors, setColors] = useState([]);

  const groupData = useSelector(selectCurrentGroupData);

  const changeSelect = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const fetchData = async () => {
    const storeDoc = await getDoc(doc(db, "store", groupData.id));
    if (storeDoc.exists()) {
      const data = storeDoc.data();
      const storeConf = groupData.store || {};
      const publishStandalone = data.publishStandalone || false;
      setState({
        deliveryOption: data.deliveryOption || "",
        shippingNote: data.shippingNote || "",
        pickupNote: data.pickupNote || "",
        publishStandalone,
        storeFeeOption: data.storeFeeOption || "",
        processingFee: data.processingFee || "",
        storeIntroText: data.storeIntroText || "",
        shippingCountries: data.shippingCountries || [],
        navigationLabel: storeConf.name || (publishStandalone ? "Store" : ""),
        storeUrl:
          storeConf.link ||
          (publishStandalone
            ? `https://group.chant.fan/${groupData.groupName
                .toLowerCase()
                .replace(/ /g, "")}/store`
            : ""),
        loading: false,
        showModal: false,
      });
    }
  };

  const handleSubmit = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const updateObj = { ...state };
    let { storeUrl, navigationLabel } = updateObj;

    if (storeUrl && !storeUrl.startsWith("https://")) {
      storeUrl = `https://${storeUrl}`;
    }

    const store = { name: navigationLabel, link: storeUrl };

    delete updateObj.loading;
    delete updateObj.showModal;
    delete updateObj.navigationLabel;
    delete updateObj.storeUrl;

    try {
      await setDoc(doc(db, "store", groupData?.id), updateObj, { merge: true });
      await updateDoc(doc(db, "group", groupData?.id), { store });
      await fetchData();
    } catch (error) {
      logger.error(error);
    } finally {
      setState((prevState) => ({ ...prevState, loading: false }));
    }

    postUpdateCDNUtil(groupData?.id);
  };

  useEffect(() => {
    if (groupData?.id) {
      fetchData();
      fetchColors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData?.id]);

  const primaryColor = groupData.configuration?.primaryColor
    ? `rgb(${groupData.configuration.primaryColor.r}, ${groupData.configuration.primaryColor.g}, ${groupData.configuration.primaryColor.b})`
    : "";

  const fetchColors = async () => {
    try {
      const docSnap = await getDoc(doc(db, "store", groupData.id));
      if (docSnap.exists()) {
        const data = docSnap.data();
        setColors(data.colors || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="store-settings-container" style={{ padding: "1rem" }}>
      <nav className="onboard-nav">
        <ul
          style={{
            listStyle: "none",
            // padding: "2rem 3rem 0rem",
            padding: "0",
            margin: 0,
            display: "flex",
          }}
        >
          <ResponsiveMobileTab
            menuItems={[
              {
                key: "settings",
                label: "Settings",
              },
            ]}
            param={"settings"}
            primaryColor="var(--primary-background)"
            tabIndex={0}
          />
        </ul>
      </nav>

      {state.showModal && (
        <CreateColorModal
          visible={state.showModal}
          hideModal={() =>
            setState((prevState) => ({ ...prevState, showModal: false }))
          }
          currentGroup={groupData?.id}
          colors={colors}
          setColors={setColors}
        />
      )}

      <div className="mx-auto mt-3" style={{ width: "100%" }}>
        <p style={{ fontSize: 17 }}>
          Complete the required fields in settings before publishing items in
          the store. If items will be offered in varying colors establish a
          master color list here first.
        </p>
      </div>
      <div
        className="mx-auto mt-3"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#ffffff",
          // boxShadow: "0px 3px 10px 1px",
        }}
      >
        <div className="my-row mt-2 p-3">
          <div className="col-md-8">
            <div className="my-row">
              <div className="col-md-4">
                <span>Delivery Options</span>
                <span style={{ color: "#ff0000" }}>*</span>
                <Tooltip
                  placement="topLeft"
                  title={
                    <div style={{ width: 400 }}>
                      <span>
                        Applied to all products in the Store. Add flat rate
                        shipping cost when creating products. Split orders (part
                        pickup, part shipping) are not allowed.
                      </span>
                    </div>
                  }
                >
                  <span className="border-0">
                    &nbsp;<i className="fa fa-question-circle-o"></i>
                  </span>
                </Tooltip>
              </div>
              <div className="col-md-8">
                <StoreSettingsSelect
                  placeholder="Select option"
                  dataList={shippingOptions}
                  value={state.deliveryOption}
                  onChange={(e) => {
                    changeSelect("deliveryOption", e.target.value);
                  }}
                  dataKey="value"
                  dataValue="label"
                />
              </div>
            </div>

            <div className="my-row mt-4">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <span>Shipping note</span>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  onChange={handleInputChange}
                  name="shippingNote"
                  value={state.shippingNote}
                  style={{
                    border: "1px solid #000000",
                    borderRadius: 3,
                    width: "100%",
                    boxShadow: "0px 0px 1px 1px #cccccc",
                  }}
                />
              </div>
            </div>

            <div className="my-row mt-4">
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <span>Pickup note</span>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  onChange={handleInputChange}
                  value={state.pickupNote}
                  name="pickupNote"
                  style={{
                    border: "1px solid #000000",
                    borderRadius: 3,
                    width: "100%",
                    boxShadow: "0px 0px 1px 1px #cccccc",
                  }}
                />
              </div>
            </div>

            <div className="my-row mt-4">
              <div className="col-md-4">
                <span>Country Shipping Options</span>
                <span style={{ color: "#ff0000" }}>*</span>
                <Tooltip
                  placement="topLeft"
                  title={
                    <div style={{ width: 400 }}>
                      <span>
                        Select countries where you offer shipping. Note:
                        Shipping is set as flat rate by product.
                      </span>
                    </div>
                  }
                >
                  <span className="border-0">
                    &nbsp;<i className="fa fa-question-circle-o"></i>
                  </span>
                </Tooltip>
              </div>
              <div className="col-md-8">
                <ul style={{ listStyle: "none" }}>
                  {countries.map((country) => {
                    return (
                      <li>
                        <div className="my-row align-items-center">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              let shippingCountries =
                                state.shippingCountries || [];

                              if (e.target.checked) {
                                shippingCountries.push(country);
                              } else {
                                shippingCountries.splice(
                                  shippingCountries.indexOf(country),
                                  1
                                );
                              }

                              setState((prevState) => ({
                                ...prevState,
                                shippingCountries: shippingCountries,
                              }));
                            }}
                            checked={
                              state.shippingCountries &&
                              state.shippingCountries.includes(country)
                            }
                          />
                          <span className="ml-3">{country}</span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="my-row mt-4">
              <div className="col-md-4">
                <span>Create Product Colors</span>
                <Tooltip
                  placement="topLeft"
                  title={
                    <div style={{ width: 400 }}>
                      <span>
                        Create a master list of color values here then select
                        colors when adding products.
                      </span>
                    </div>
                  }
                >
                  <span className="border-0">
                    &nbsp;<i className="fa fa-question-circle-o"></i>
                  </span>
                </Tooltip>
              </div>
              <div className="col-md-4">
                <span
                  style={{
                    textDecoration: "underline",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      showModal: true,
                    }));
                  }}
                >
                  Set Colors
                </span>
              </div>
              <div className="col-md-4">{`(${colors?.join(", ")})`}</div>
            </div>

            <div className="my-row mt-4">
              <span
                className="col"
                style={{ fontSize: 20, fontWeight: "bold" }}
              >
                Store (Web)
              </span>
            </div>

            <div className="my-row">
              <div className="col-md-4">
                <span>Publish standalone store webpage?</span>
                <Tooltip
                  placement="topLeft"
                  title={
                    <div style={{ width: 400 }}>
                      <span>
                        Select this option to generate a standalone store web
                        page allowing you to link from your own website. If
                        using a Chant website this option is not needed.
                      </span>
                    </div>
                  }
                >
                  <span className="border-0">
                    &nbsp;<i className="fa fa-question-circle-o"></i>
                  </span>
                </Tooltip>
              </div>
              <div className="col-md-8">
                <div className="my-row align-items-center">
                  <input
                    type="checkbox"
                    style={{ marginLeft: "18px", marginTop: "1px" }}
                    onChange={(e) => {
                      let publish = e.target.checked;
                      let updatedState = {
                        publishStandalone: publish,
                      };
                      if (publish) {
                        updatedState = {
                          ...updatedState,
                          storeUrl:
                            "https://group.chant.fan/" +
                            groupData?.groupName
                              .replace(/ /g, "")
                              .toLowerCase() +
                            "/store",
                          navigationLabel: "Store",
                        };
                      } else {
                        if (state.storeUrl.includes("group.chant.fan")) {
                          updatedState = {
                            ...updatedState,
                            storeUrl: "",
                            navigationLabel: "",
                          };
                        }
                      }
                      setState((prev) => ({
                        ...prev,
                        publishStandalone: updatedState?.publishStandalone,
                        storeUrl: updatedState?.storeUrl,
                        navigationLabel: updatedState?.navigationLabel,
                      }));
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    tabIndex="5"
                    id="publishStandalone"
                    checked={state.publishStandalone}
                  />

                  <div className="ml-4">
                    Status:{" "}
                    {state.publishStandalone ? (
                      <span>
                        Published (
                        <a
                          href={`https://group.chant.fan/${groupData?.groupName.toLowerCase().replace(/ /g, "")}/store`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Store
                        </a>
                        )
                      </span>
                    ) : (
                      "Unpublished"
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="my-row mt-4">
              <div className="col-md-4">
                <span>Store Intro Text</span>
              </div>
              <div className="col-md-8">
                <textarea
                  onChange={handleInputChange}
                  name="storeIntroText"
                  value={state.storeIntroText}
                  style={{
                    border: "1px solid #000000",
                    borderRadius: 3,
                    width: "100%",
                    boxShadow: "0px 0px 1px 1px #cccccc",
                  }}
                  my-rows={3}
                />
              </div>
            </div>

            <div className="my-row mt-4">
              <span
                className="col"
                style={{ fontSize: 20, fontWeight: "bold" }}
              >
                Store navigation(App)
              </span>
            </div>
            <div className="my-row">
              <div className="col">
                <p>
                  Using the Chant Store or an external store? Add navigation
                  name + store url to appear in More section of the app as web
                  link. Note that we will integrate the Store into the app in
                  the coming months – currently web only.
                </p>
              </div>
            </div>

            <div
              className="col-8"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <FSelect
                flex
                label="Navigation name"
                value={state.navigationLabel}
                onChange={(e) =>
                  changeSelect("navigationLabel", e.target.value)
                }
                name="type"
                dataList={["Store", "Merch", "Merchandise"]}
                dataOnlyValue
                placeholder="Select"
                // display="my-row"
                tabIndex="1"
              />

              <FInput
                label="Store Url"
                value={state.storeUrl}
                onChange={handleInputChange}
                name="storeUrl"
                // display="my-row"
                placeholder="Store URL"
                tabIndex="2"
              />

              <FSelect
                flex
                label="Processing Fee"
                name="processingFee"
                // display="my-row"
                tabIndex="5"
                required
                bottomHint="Stripe (2.9% + $.030) + Chant (3%). See ‘?’ for additional details."
                tooltip={
                  <div>
                    <p style={{ fontWeight: "bold" }}>Fees</p>
                    <p style={{ marginBottom: 0 }}>
                      Stripe card processing fee: 2.9% + $0.30 (can vary)
                    </p>
                    <p>Chant fee: 3%</p>

                    <p style={{ fontWeight: "bold" }}>Member Pay</p>
                    <p>
                      Stripe collects the fee on the entire purchase amount. So,
                      if a member covers fees that is added to the total charged
                      ($10 item + $0.59 fee paid by member for $10.59) stripe
                      will collect a fee on the entire $10.59.
                    </p>

                    <p>
                      To account for this we add 3% + $.30 (.1% more) to the
                      original item price for Stripe so your net is close to the
                      item price - it may be off very slightly.
                    </p>

                    <p>
                      Stripe fees can vary based on member payment method but
                      are not known until after the transaction is complete
                      (foreign card, etc). Your net proceeds may not cover the
                      full item amount but should be close.
                    </p>

                    <p style={{ fontWeight: "bold" }}>
                      Member Pay (legal Limitations)
                    </p>
                    <p>
                      Not all states/countries allow the fee to be passed to the
                      member. Chant is not responsible for determining your
                      local laws. Please confirm this for your location.
                    </p>
                  </div>
                }
                value={state.processingFee}
                onChange={(e) => changeSelect("processingFee", e.target.value)}
                placeholder="--Select--"
                dataOnlyValue
                dataList={ProcessingFeeTypes}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="my-row mt-4 justify-content-center">
        <button
          className="btn btn-success default-text-color"
          tabIndex="14"
          type="success"
          style={{
            backgroundColor: primaryColor,
            borderRadius: 5,
            border: "0px none",
            boxShadow: "transparent 0px 0px 0px",
            outline: "none",
            padding: "5px 10px",
          }}
          onClick={handleSubmit}
        >
          {state.loading ? <Spin size="small" spinning={true} /> : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default StoreSettings;
