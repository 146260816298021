import { countries } from "countries-list";
import React, { useState } from "react";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { AsYouType } from "libphonenumber-js";
import "./PhoneNumberInput.css";

const PhoneNumberInput = (props) => {
  const [country, setCountry] = useState("US");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");

  const handlePhoneNumberChange = (e) => {
    const asYouType = new AsYouType(country);
    asYouType.input(e.target.value);
    let formattedNumber = asYouType.input("");
    let phoneNumberObject = asYouType.getNumber();

    if (phoneNumberObject) {
      let phoneNumber = phoneNumberObject?.number;
      props.onChange(phoneNumber, `+${phoneNumberObject?.countryCallingCode}`);
    }
    setFormattedPhoneNumber(formattedNumber);
  };

  let countryList = [];
  Object.keys(countries).forEach((countryCode) => {
    countryList.push({ code: countryCode, label: countries[countryCode].name });
  });

  countryList.sort((a, b) => `${a.label}`.localeCompare(b.label));

  return (
    <div className="phone-number-input-container">
      <div className="phone-number-input-field">
        <div className="h-auto">{getUnicodeFlagIcon(country)}</div>
        <select
          className="phone-number-input-dropdown"
          value={country}
          tabIndex={props.tabIndex || 0}
          onChange={(e) => setCountry(e.target.value)}
        >
          {countryList.map((country) => {
            return (
              <option key={country.code} value={country.code}>
                {country.label}
              </option>
            );
          })}
        </select>
      </div>
      <div className="col phone-number-input-number">
        <input
          style={{
            background: "transparent",
            border: 0,
            outline: "none",
            width: "100%",
          }}
          // className="bg-transparent border-0 outline-none w-full"
          maxLength={20}
          placeholder={props.placeholder}
          value={formattedPhoneNumber}
          onChange={handlePhoneNumberChange}
        />
      </div>
    </div>
  );
};

export default PhoneNumberInput;
