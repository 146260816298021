import { db, storage } from "../../connection";
import md5 from "md5";
import moment from "moment";
import { data } from "./data/memberImportData";
import {
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import logger from "../../../../utils/logger";

export async function updateGroupsForPackageExpiry() {
  let snap = await db.collection("group").get();
  for (var doc of snap.docs) {
    let docRef = doc.ref;
    let id = doc.id;

    let packageExpiryMap = {};

    let packagesSnap = await db
      .collection("registration_form_layout")
      .doc(id)
      .collection("packages")
      .get();

    for (var packDoc of packagesSnap.docs) {
      let data = packDoc.data();
      packageExpiryMap[packDoc.id] = {
        name: data.name,
        expiryDate: data.packageExpiryDate || 0,
      };
    }

    await docRef.update({
      packageExpiryMap: packageExpiryMap,
    });
  }
}

export async function compareRecords(groupId) {
  let packages = await db
    .collection("registration_form_layout")
    .doc(groupId)
    .collection("packages")
    .get()
    .then((snap) => {
      let packIds = [];
      for (let doc of snap.docs) {
        let data = doc.data();
        if (data.status === "active") {
          packIds.push(doc.id);
        }
      }
      return packIds;
    });

  let snap = await db
    .collection("transaction_record")
    .doc(groupId)
    .collection("records")
    .get();

  let diff = [];

  for (let doc of snap.docs) {
    let transactionData = doc.data();
    let metadata = transactionData.data;
    let packId = "";
    if (metadata && transactionData.status === "success") {
      let intentId = doc.id;

      for (let key of Object.keys(metadata)) {
        if (key.includes("package")) {
          packId = key.split("-")[1];
        }
      }
      if (packId && packages.includes(packId)) {
        for (let key of Object.keys(metadata)) {
          if (key.includes("filledData")) {
            let value = parseString(metadata[key]);

            let obj = {
              fullName: value.fullName,
              email: value.email,
              phone: value.phone || "",
              address1: value.address1 || "",
              address2: value.address2 || "",
              city: value.city || "",
              state: value.state || "",
              zip: value.zip || "",
              country: value.country || "",
              officialClubMember: value.officialClubMember || "",
              joinedEmailList: value.joinedEmailList === "true",
              shareVolunteerOpportunities:
                value.shareVolunteerOpportunities === "true",
              shippingOption: "pickup",
              package: key.split("-")[1],
              referredBy: value.referredBy || "",
              chapter: value.chapter || "",
              package: packId,
            };

            if (Object.keys(metadata).includes("shipping")) {
              obj.shippingOption = "shipping";
            }

            if (key.includes("user1")) {
              let connetedUserKey = key.replace("user1", "user0");
              let connectedUserValue = parseString(metadata[connetedUserKey]);
              obj = {
                ...obj,
                address1: connectedUserValue.address1,
                address2: connectedUserValue.address2,
                city: connectedUserValue.city,
                state: connectedUserValue.state,
                zip: connectedUserValue.zip,
                country: connectedUserValue.country,
              };
            }

            if (obj.email) {
              await db
                .collection("userGroup")
                .doc(groupId)
                .collection("members")
                .where("email", "==", obj.email)
                .get()
                .then((snap) => {
                  for (let doc of snap.docs) {
                    let data = doc.data();
                    if (data.email === obj.email) {
                      let docId = doc.id;
                      let userDiff = {};

                      for (let key of Object.keys(obj)) {
                        if (data[key] !== obj[key]) {
                          userDiff[`${key}-transaction`] = obj[key] || "";
                          userDiff[`${key}-userGroup`] = data[key] || "";
                        }
                      }

                      if (Object.keys(userDiff).length > 0) {
                        userDiff.userId = docId;
                        userDiff.intentId = intentId;
                        diff.push(userDiff);
                      }
                    }
                  }
                });
            } else {
              logger.log(intentId);
            }
          }
        }
      }
    }
  }
}

export function parseString(str) {
  let obj = {};

  if (
    str.trim().charAt(0) === "[" &&
    str.trim().charAt(str.trim().length - 1) === "]"
  ) {
    obj = [];
    str = str.trim().slice(1, str.trim().length - 1);
    if (str.includes("{")) {
      let strArr = str.split("");
      let bracesStack = [];
      let index = 0;
      let strObj = "";
      while (index < strArr.length) {
        if (strArr[index] === "{") {
          bracesStack.push("{");
        }
        if (strArr[index] === "}") {
          bracesStack.pop();
        }
        if (bracesStack.length > 0) {
          strObj = strObj + strArr[index];
        }
        if (bracesStack.length === 0 && strObj.length > 1) {
          strObj = strObj + "}";
          obj.push(parseString(strObj));
          strObj = "";
        }
        index++;
      }
    } else {
      if (str.trim().length > 0) {
        obj = str.split(",").map((value) => {
          if (
            value.trim().charAt(0) === '"' &&
            value.trim().charAt(value.trim().length - 1) === '"'
          ) {
            value = value.slice(1, value.trim().length - 1);
          }
          return value.trim();
        });
      }
    }
    return obj;
  }

  obj = customSplit(str);
  Object.keys(obj).forEach((key) => {
    let value = obj[key];
    if (
      (value.includes("{") && value.includes("}")) ||
      (value.includes("[") && value.includes("]"))
    ) {
      value = parseString(value.trim());
    }
    if (
      key.trim().charAt(0) === '"' &&
      key.trim().charAt(key.trim().length - 1) === '"'
    ) {
      delete obj[key];
      key = key.slice(1, key.trim().length - 1);
    }
    obj[key.trim()] = value;
  });

  return obj;
}

function customSplit(str) {
  let arr = {};
  let updatedStr = str.trim().slice(1, str.length - 1);
  let strSplitArr = updatedStr.split(",");
  var index = 0;

  for (index; index < strSplitArr.length; index++) {
    if (strSplitArr[index].includes("{")) {
      let innerIndex = index + 1;
      let value = strSplitArr[index];
      if (!value.includes("}")) {
        for (
          innerIndex;
          innerIndex < strSplitArr.length &&
          !strSplitArr[innerIndex].includes("}");
          innerIndex++
        ) {
          value = value + "," + strSplitArr[innerIndex];
        }
        value =
          value +
          (strSplitArr[innerIndex] ? "," + strSplitArr[innerIndex] : "");
        index = innerIndex;
      }
      let key = value.slice(0, value.indexOf(":"));
      value = value.slice(value.indexOf(":") + 1);
      if (
        value.trim().charAt(0) === '"' &&
        value.trim().charAt(value.trim().length - 1) === '"'
      ) {
        value = value.slice(1, value.trim().length - 1);
      }

      arr[key.trim()] = value.trim();
    } else if (strSplitArr[index].includes("[")) {
      let innerIndex = index + 1;
      let value = strSplitArr[index];
      if (!value.includes("]")) {
        for (
          innerIndex;
          innerIndex < strSplitArr.length &&
          !strSplitArr[innerIndex].includes("]");
          innerIndex++
        ) {
          value = value + "," + strSplitArr[innerIndex];
        }
        value =
          value +
          (strSplitArr[innerIndex] ? "," + strSplitArr[innerIndex] : "");
        index = innerIndex;
      }
      let key = value.slice(0, value.indexOf(":"));
      value = value.slice(value.indexOf(":") + 1);
      if (
        value.trim().charAt(0) === '"' &&
        value.trim().charAt(value.trim().length - 1) === '"'
      ) {
        value = value.slice(1, value.trim().length - 1);
      }
      arr[key.trim()] = value.trim();
    } else {
      let value = strSplitArr[index];
      let key = value.slice(0, value.indexOf(":"));
      value = value.slice(value.indexOf(":") + 1);
      if (
        value.trim().charAt(0) === '"' &&
        value.trim().charAt(value.trim().length - 1) === '"'
      ) {
        value = value.slice(1, value.trim().length - 1);
      }
      arr[key.replace("{", "").trim()] = value.replace("}", "").trim();
    }
  }

  return arr;
}

export async function importDataToInviteEmails() {
  let groupId = "2nvaUYLhyWQWjhoysjGv";
  let docRef = db.collection("invite_emails").doc(groupId);

  let data = [
    {
      name: "Biñdie Cruz Test",
      membershipNumber: 999,
      officialClubMember: 999.999,
      phone: "(444) 444-4444",
      email: "madrid@chant.fan",
      birthday: "7/7/1994",
      address1: "55 MAIÑ STREET",
      address2: "",
      city: "SAN FRANCISCO",
      state: "CA",
      zip: 94134,
      memberSince: 2022,
      customFieldEYbzro: "MEN - 1XLG",
      title: "PEÑA ADMIN ROLE",
    },
  ];

  let newEmails = data.map((detail) => detail.email);

  let membershipPacakageAmount = {
    amount: 0,
    shipping: 0,
    type: "Per member",
  };
  let registeredOn = Math.floor(moment.now() / 1000);

  docRef.get().then((doc) => {
    if (doc.exists) {
      let emails = doc.data().emails || [];
      emails = [...emails, ...newEmails];
      docRef
        .update({
          emails: emails,
        })
        .then(() => {
          let batch = db.batch();
          data.map((detail) => {
            let birthday = {};
            if (detail.birthday) {
              let birthdayStringArr = detail.birthday.split("/");
              birthday = {
                month: moment()
                  .set("month", parseInt(birthdayStringArr[0]) - 1)
                  .format("MMM"),
                date: birthdayStringArr[1],
                year: birthdayStringArr[2],
              };
            }
            let userDetail = {
              ...(detail || {}),
              membershipPacakageAmount,
              userRole: "member",
              membershipNumber: padMembershipNumber(detail.membershipNumber),
              registeredOn,
              birthday,
              shipping: {
                unit: "$",
                value: 0,
              },
            };

            delete userDetail.package;

            let detailDocRef = docRef
              .collection("userDetails")
              .doc(md5(detail.email));
            batch.set(detailDocRef, userDetail);
          });

          return batch.commit();
        })
        .then(() => {
          logger.log("docs added");
        });
    }
  });
}

export async function createMembershipNumber(
  groupId,
  groupIndex,
  lastMemberCount,
  hideGroupNumber
) {
  if (!lastMemberCount && `${lastMemberCount}` === "0") {
    let lastMembershipNumber = await getDocs(
      collection(db, "userGroup", groupId, "members")
    ).then((snap) => {
      let userGroupMaxMembershipNumber = 0;
      snap.docs.forEach((doc) => {
        let data = doc.data();
        let membershipNumber = data.membershipNumber
          ? parseInt(
              (data.membershipNumber + "").substring(
                data.membershipNumber.length - 5
              )
            )
          : 0;
        if (userGroupMaxMembershipNumber < membershipNumber) {
          userGroupMaxMembershipNumber = membershipNumber;
        }
        if (data.children) {
          data.children.forEach((child) => {
            if (child.membershipNumber) {
              let childMembershipNumberAsInt = parseInt(
                (child.membershipNumber + "").substring(
                  child.membershipNumber.length - 5
                )
              );
              if (userGroupMaxMembershipNumber < childMembershipNumberAsInt) {
                userGroupMaxMembershipNumber = childMembershipNumberAsInt;
              }
            }
          });
        }
      });
      return userGroupMaxMembershipNumber;
    });

    await getDocs(collection(db, "invite_emails", groupId, "userDetails")).then(
      (snap) => {
        for (var doc of snap.docs) {
          let data = doc.data() || {};
          let membershipNumber = data.membershipNumber
            ? parseInt(
                (data.membershipNumber + "").substring(
                  data.membershipNumber.length - 5
                )
              )
            : 0;
          if (lastMembershipNumber < membershipNumber) {
            lastMembershipNumber = membershipNumber;
            logger.log(
              "last membership number in invite email: ",
              lastMembershipNumber
            );
          }
          if (data.children) {
            data.children.forEach((child) => {
              if (child.membershipNumber) {
                let childMembershipNumberAsInt = parseInt(
                  (child.membershipNumber + "").substring(
                    child.membershipNumber.length - 5
                  )
                );
                if (lastMembershipNumber < childMembershipNumberAsInt) {
                  lastMembershipNumber = childMembershipNumberAsInt;
                }
              }
            });
          }
        }
      }
    );

    lastMemberCount = lastMembershipNumber;
  }
  lastMemberCount = (lastMemberCount || 0) + 1;

  if (hideGroupNumber) {
    // let membershipIndex = '00000' + lastMemberCount;
    // membershipIndex = membershipIndex.slice(membershipIndex.length - 5);
    return lastMemberCount + "";
  }

  let membershipIndex = "00000" + lastMemberCount;
  membershipIndex = membershipIndex.slice(membershipIndex.length - 5);
  return groupIndex + "-" + membershipIndex;
}

function padMembershipNumber(number, groupIndex = "0365") {
  let membershipIndex = "00000" + number;
  membershipIndex = membershipIndex.slice(membershipIndex.length - 5);
  return groupIndex + "-" + membershipIndex;
}

export function modifyData() {
  let groupId = "2nvaUYLhyWQWjhoysjGv";
  let collectionRef = db
    .collection("invite_emails")
    .doc(groupId)
    .collection("userDetails");
  let batch = db.batch();
  collectionRef
    .get()
    .then((snap) => {
      snap.docs.forEach((doc) => {
        if (doc.exists) {
          let data = doc.data();
          let updateObj = {
            fullName: data.name,
          };

          batch.update(doc.ref, updateObj);
        }
      });
      return batch.commit();
    })
    .then(() => {
      logger.log("Update successful");
    });
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const FieldMap = {
  "Membership #": {
    fieldName: "membershipNumber",
    castFunction: (value, groupIndex) => padMembershipNumber(value, groupIndex),
  },
  Name: {
    fieldName: "name",
    castFunction: (value) => {
      if (value) return `${value}`;
      return "";
    },
  },
  "First Name": {
    fieldName: "firstName",
    castFunction: (value) => {
      if (value) return `${value}`;
      return "";
    },
  },
  "Last Name": {
    fieldName: "lastName",
    castFunction: (value) => {
      if (value) return `${value}`;
      return "";
    },
  },
  Email: {
    fieldName: "email",
    castFunction: (value) => `${value}`.trim().toLowerCase(),
  },
  "Official Club Member #": {
    fieldName: "officialClubMember",
    castFunction: (value) => (value ? `${value}` : ""),
  },
  Package: { fieldName: "package", castFunction: (value) => `${value}` },
  "Registration Date": {
    fieldName: "registeredOn",
    castFunction: (value) =>
      value
        ? parseInt(moment(`${value}`, "MM/DD/YYYY").unix())
        : moment().unix(),
  },
  Phone: { fieldName: "phone", castFunction: (value) => `${value}` },
  "Date of Birth": {
    fieldName: "birthday",
    castFunction: (value) => {
      if (value) {
        let dateParts = `${value}`.split("/");
        let birthDay = {
          month: months[parseInt(`${dateParts[0]}`) - 1].substring(0, 3),
          date: parseInt(`${dateParts[1]}`),
        };
        if (dateParts.length === 3) {
          birthDay.year = parseInt(`${dateParts[2]}`);
        }
        return birthDay;
      }
      return {};
    },
  },
  "Address 1": { fieldName: "address1", castFunction: (value) => `${value}` },
  "Address 2": { fieldName: "address2", castFunction: (value) => `${value}` },
  City: { fieldName: "city", castFunction: (value) => `${value}` },
  State: { fieldName: "state", castFunction: (value) => `${value}` },
  Country: { fieldName: "country", castFunction: (value) => `${value}` },
  Postal: { fieldName: "zip", castFunction: (value) => `${value}` },
  Chapter: { fieldName: "chapter", castFunction: (value) => `${value}` },
  Notes: { fieldName: "notes", castFunction: (value) => `${value}` },
  "Member Since": {
    fieldName: "memberSince",
    castFunction: (value) => `${value}`,
  },
  "T-Shirt Size": {
    fieldName: "tshirtSize",
    castFunction: (value) => `${value}`,
  },
  "Email?": {
    fieldName: "joinMailingList",
    castFunction: (value) => (`${value}` === "Yes" ? true : false),
  },
  "Season Ticket": {
    fieldName: "seasonTicketHolder",
    castFunction: (value) => ({ season: `${value}`, seats: "" }),
  },
};

export async function addMembersToInviteEmail(groupId) {
  let newEmails = [];
  let newUserDetails = {};
  let groupIndex = "0401";

  const membershipPacakageAmount = {
    "8s04Ak57zmpiVzAE7wo3": {
      amount: 20,
      priceId: "price_1PIZsrGWleyeB265od5dBdU4",
      priceIdWithFee: "price_1PIZssGWleyeB265irvuFlSe",
      secondaryPriceIdWithFee: "price_1PIZssGWleyeB2650MQ607XT",
      secondaryShippingPriceIdWithFee: "price_1PIZssGWleyeB2650MQ607XT",
      shipping: 0,
      shippingPriceId: "price_1PIZsrGWleyeB265od5dBdU4",
      shippingPriceIdWithFee: "price_1PIZssGWleyeB265irvuFlSe",
      type: "Per member",
    },
    Kep3OQLkap5Ja0JZOBmh: {
      amount: 40,
      priceId: "price_1PIZxlGWleyeB265nsdSjsr6",
      priceIdWithFee: "price_1PIZxlGWleyeB265kSCm6gfI",
      secondaryPriceIdWithFee: "price_1PIZxlGWleyeB265KHx6z1Pj",
      secondaryShippingPriceIdWithFee: "price_1PIZxlGWleyeB265KHx6z1Pj",
      shipping: 0,
      shippingPriceId: "price_1PIZxlGWleyeB265nsdSjsr6",
      shippingPriceIdWithFee: "price_1PIZxlGWleyeB265kSCm6gfI",
      type: "Per member",
    },
  };

  for (var memberData of data) {
    let member = {};

    Object.keys(memberData).map((key) => {
      if (key.includes("customField")) {
        member[key] = memberData[key];
      } else {
        if (FieldMap[key]) {
          member[FieldMap[key].fieldName] = FieldMap[key].castFunction(
            memberData[key] || "",
            groupIndex
          );
        } else {
          member["notes"] = `${member.notes};${key}:${memberData[key]}`;
        }
      }
    });
    newEmails.push(member.email.toLowerCase());

    if (!member.name && member.firstName) {
      member.name = `${member.firstName} ${member.lastName || ""}`;
    }

    delete member.firstName;
    delete member.lastName;
    if (!member.registeredOn) {
      delete member.registeredOn;
    }

    let memberDetail = {
      ...member,
    };

    if (memberDetail.package) {
      let chargeDetail = JSON.parse(
        JSON.stringify(membershipPacakageAmount[memberDetail.package])
      );
      if (memberData["Amount Paid"] && memberData["Amount Paid"].toString()) {
        chargeDetail.amount = parseFloat(
          parseFloat(memberData["Amount Paid"]).toFixed(2)
        );
      }
      memberDetail.membershipPacakageAmount = chargeDetail;
      // memberDetail.packageSeason = "2025";
    }
    newUserDetails[md5(member.email.toLowerCase())] = memberDetail;
  }

  let docRef = doc(collection(db, "invite_emails"), groupId);
  await getDoc(docRef)
    .then((doc) => {
      if (doc.exists()) {
        let emails = doc.data().emails || [];
        let userDetails = doc.data().userDetails || {};

        emails = Array.from(new Set([...emails, ...newEmails]));

        return updateDoc(docRef, { emails, userDetails });
      } else {
        return setDoc(docRef, { emails: newEmails });
      }
    })
    .then(() => {
      let collectionRef = collection(docRef, "userDetails");
      let batch = writeBatch(db);
      Object.keys(newUserDetails).map((docId) => {
        let userDetails = newUserDetails[docId];
        let detailDocRef = doc(collectionRef, docId);
        batch.set(detailDocRef, userDetails, { merge: true });
      });
      return batch.commit();
    });
}

export function updateMemberData() {
  let groupId = "vxyZZfGvbGotHJ1JRwB2";
  let userId = "EYF2wFbtJ4Uk79ZI4qZYmcnWcSj1";
  let docRef = db
    .collection("userGroup")
    .doc(groupId)
    .collection("members")
    .doc(userId);
  let data = {
    children: [
      {
        selectedProduct: {
          title: "2023 Members Scarf",
          id: "DuFtV9oz9Y0lBmli7Vgy",
          size: "",
        },
        name: "Eliza Julian",
        membershipNumber: "0013-00176",
      },
      {
        selectedProduct: {
          title: "2023 Members Scarf",
          id: "DuFtV9oz9Y0lBmli7Vgy",
          size: "",
        },
        name: "David Julian",
        membershipNumber: "0013-00177",
      },
      {
        selectedProduct: {
          title: "2023 Members Scarf",
          id: "DuFtV9oz9Y0lBmli7Vgy",
          size: "",
        },
        name: "James Julian",
        membershipNumber: "0013-00178",
      },
    ],
  };
  docRef.update({ ...data }).then(() => {
    logger.log("member updated");
  });
}

export async function downloadDeleteUserDetailsFromInviteEmails(groupId) {
  const docRef = doc(db, "invite_emails", groupId);
  let userDetails = await getDoc(docRef).then((doc) => {
    if (doc.exists()) {
      let data = doc.data() || {};
      let userDetails = data.userDetails || {};
      return userDetails;
    }
  });
  return userDetails;
}

export async function downloadDeleteUserDetailsFromInviteEmailsForGroups(
  ...groupIds
) {
  let idDetailsMap = {};
  for (var groupId of groupIds) {
    idDetailsMap[groupId] =
      await downloadDeleteUserDetailsFromInviteEmails(groupId);
  }
  let storageRef = ref(storage);
  let child = ref(storageRef, `backup-invite-email-${moment().unix()}`);
  uploadString(child, JSON.stringify(idDetailsMap))
    .then((res) => {
      return getDownloadURL(res.ref);
    })
    .then((url) => {
      logger.log("url==========>", url);
    });
}

export async function createMemberFromInviteEmails(groupId, userId, emailHash) {
  let inviteEmailDoc = getDoc(
    doc(db, "invite_emails", groupId, "userDetails", emailHash)
  );
  let userGroupDocRef = doc(db, "userGroup", groupId, "members", userId);
  let inviteEMailDocSnap = await inviteEmailDoc;
  if (inviteEMailDocSnap.exists()) {
    let data = inviteEMailDocSnap.data();
    let userGroupDocSnap = await getDoc(userGroupDocRef);
    if (userGroupDocSnap.exists()) {
      await updateDoc(userGroupDocRef, {
        ...data,
      });
    } else {
      await setDoc(userGroupDocRef, {
        ...data,
      });
    }
  }
}

export async function createNewMemberFromInviteEmails(
  groupId,
  userId,
  emailHash
) {
  let inviteEmailDoc = getDoc(
    doc(db, "invite_emails", groupId, "userDetails", emailHash)
  );
  let userGroupDocRef = doc(db, "userGroup", groupId, "members", userId);
  let inviteEMailDocSnap = await inviteEmailDoc;
  if (inviteEMailDocSnap.exists()) {
    let data = inviteEMailDocSnap.data();
    let userGroupDocSnap = await getDoc(userGroupDocRef);

    if (userGroupDocSnap.exists()) {
      await updateDoc(userGroupDocRef, {
        ...data,
      });
    } else {
      await setDoc(userGroupDocRef, {
        ...data,
      });
    }
  }
}
