import { ContentState, EditorState, convertToRaw } from "draft-js";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { FInput } from "../../commons/formFields/FInput";
import ImageUploadButton from "../website/ImageUploaderButton";
import { connect } from "react-redux";
import { FSelect } from "../../commons/formFields/FSelect";
import { Alert, Spin, Tooltip } from "antd";
import draftToHtml from "draftjs-to-html";
import {
  fbGetPartners,
  fbSavePartnerDetails,
  getPartnerDetails,
} from "../../../services/firebaseService/endPoints/admin/partners";
import htmlToDraft from "html-to-draftjs";
import {
  getCurrencyUnicode,
  postUpdateCDNUtil,
} from "../../../helperFunctions/util";
import { verifyStripeAccountId } from "../../../utils/errors";
import { withRouter } from "../../../utils/helper";

const ProcessingFeeTypes = [
  "Group pays (Fees absorbed by group)",
  "Member pays (Fees added to total)",
  "Ask Member to pay (Request member pay fee but not required)",
];

const MAX_LENGTH = 500;

class CreatePartners extends React.Component {
  state = {
    name: "",
    logo: null,
    details: EditorState.createEmpty(),
    discounts: "",
    galleryImages: [],
    qrImage: null,
    collectDonations: false,
    suggestedAmounts: [],
    displayOn: {
      "Website: Partners": false,
      "App: Partners": false,
      "App: Home": false,
    },
    processingFee: "",
    errorInForm: {
      isError: false,
      visible: false,
      message: "",
    },
    loadingDocument: true,
    saving: false,
  };

  handleEditMode = () => {
    getPartnerDetails(
      this.props.currentGroup,
      this.props.router.params.id
    ).then((data) => {
      let partnerData = {};
      partnerData = { ...(data || {}) };

      if (data.details) {
        let blockArray = htmlToDraft(data.details);
        if (blockArray) {
          let contentState = ContentState.createFromBlockArray(blockArray);
          let editorState = EditorState.createWithContent(contentState);
          partnerData.details = editorState;
        } else {
          partnerData.details = EditorState.createEmpty();
        }
      } else {
        partnerData.details = EditorState.createEmpty();
      }
      partnerData.displayOn = (data.displayOn || []).reduce(
        (acc, val) => {
          acc[val] = true;
          return acc;
        },
        {
          "Website: Partners": false,
          "App: Partners": false,
          "App: Home": false,
        }
      );
      partnerData.discounts = data.discounts || data.benefits || "";
      partnerData.qrImage = null;
      partnerData.logo = null;
      partnerData.galleryImages = [];
      if (data.showQrImage) {
        partnerData.qrImage = `https://firebasestorage.googleapis.com/v0/b/chant2019.appspot.com/o/${this.props.currentGroup}%2Fpartner%2F${this.props.router.params.id}%2FqrImage?alt=media`;
      }
      if (data.showLogo) {
        partnerData.logo = `https://firebasestorage.googleapis.com/v0/b/chant2019.appspot.com/o/${this.props.currentGroup}%2Fpartner%2F${this.props.router.params.id}%2Flogo?alt=media`;
      }
      Array.from({ length: data.galleryImageCount || 0 }, (_, index) => {
        partnerData.galleryImages.push(
          `https://firebasestorage.googleapis.com/v0/b/chant2019.appspot.com/o/${this.props.currentGroup}%2Fpartner%2F${this.props.router.params.id}%2Fgallery%2F${index}?alt=media`
        );
      });
      this.setState({
        ...partnerData,
        loadingDocument: false,
      });
    });
  };

  componentDidMount() {
    if (this.props.router.params.id) {
      if (this.state.loadingDocument) {
        this.handleEditMode();
      }
    } else {
      this.setState({
        loadingDocument: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.router.params.id &&
      this.props.currentGroup !== prevProps.currentGroup
    ) {
      this.handleEditMode();
    }
  }

  handleOptionChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  handleEditorStateChange = (state, field) => {
    this.setState({
      [field]: state,
    });
  };

  handleOptionChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    if (
      this.state.collectDonations &&
      !verifyStripeAccountId(
        this.props?.adminData?.data?.paymentDetails?.stripeAccountId
      )
    ) {
      return;
    }

    this.setState({
      saving: true,
    });
    if (
      this.state.collectDonations &&
      !(this.props.paymentDetails || {}).stripeAccountId
    ) {
      alert(
        "Must have Stripe account through Chant to collect donations. Request from support@chant.fan."
      );
      return;
    }

    let data = this.state;

    const errors = [];

    if (!data.name) errors.push("Please enter your full name");
    if (!data.logo) errors.push("Please add a logo");
    if (!data.details.getCurrentContent().getPlainText(""))
      errors.push("Please enter details");

    if (
      data.collectDonations &&
      data.suggestedAmounts.filter((amount) => amount && amount.toString())
        .length < 1
    ) {
      errors.push("Please enter donation amounts");
    }

    if (
      data.collectDonations &&
      (!data.processingFee || data.processingFee === "")
    ) {
      errors.push("Please select processing fee");
    }

    if (errors.length > 0) {
      this.setState({
        errorInForm: {
          isError: true,
          visible: true,
          message: errors[0],
        },
        saving: false,
      });
      window.scrollTo(0, 0);
      return;
    }

    data.details = draftToHtml(convertToRaw(data.details.getCurrentContent()));
    data.displayOn = Object.keys(data.displayOn).filter(
      (key) => data.displayOn[key]
    );
    data.sortIndex = -1;
    let promise = this.props.router.params.id
      ? fbSavePartnerDetails(
          data,
          this.props.currentGroup,
          this.props.router.params.id
        )
      : fbSavePartnerDetails(data, this.props.currentGroup);
    promise.then(() => {
      postUpdateCDNUtil(this.props.currentGroup);
      this.props.router.navigate("/admin/partners/list");
    });
  };

  _getLengthOfSelectedText = () => {
    const currentSelection = this.state.details.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = this.state.details.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  _handleBeforeInput = () => {
    const currentContent = this.state.details.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      window.alert("you can type max 500 characters");

      return "handled";
    }
  };

  _handlePastedText = (pastedText) => {
    const currentContent = this.state.details.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (
      currentContentLength + pastedText.length - selectedTextLength >
      MAX_LENGTH
    ) {
      window.alert("you can type max 500 characters");

      return "handled";
    }
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div className="col">
        {this.state.errorInForm.isError && this.state.errorInForm.visible && (
          <div className="mx-5 mt-2">
            <Alert
              message="Error"
              description={this.state.errorInForm.message}
              type="error"
              closable
              // className="mb-3"
              afterClose={this.handleErrorMessageClose}
            />
          </div>
        )}
        <div className="md-5 border-box" style={{ padding: "30px 16px" }}>
          <ul className="nav nav-tabs">
            <li
              className="nav-item"
              style={{
                fontSize: 22,
                fontWeight: "bold",
                borderBottom: `4px solid ${primaryColor}`,
                color: "black",
              }}
            >
              <a>Add Partner</a>
            </li>
          </ul>
          <div
            className="border rounded p-3 px-5 mt-4"
            style={{
              backgroundColor: "#ffffff",
              // boxShadow: "0px 3px 10px 1px",
            }}
          >
            {/* <div>
                            <span style={{ color: 'black', fontSize: 20, fontWeight: 'bolder' }}>Add Partner</span>
                            
                        </div> */}
            <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-4">
              <div className={`form-group col-md-12`}>
                <FSelect
                  flex
                  value={this.state.status}
                  label="Status"
                  onChange={this.handleOptionChange}
                  name="status"
                  dataList={["Active", "Inactive"]}
                  dataOnlyValue
                  placeholder="--Select status--"
                  // display="row"
                  tabIndex="1"
                  required
                />
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-4">
              <div className={`form-group col-md-12`}>
                <FInput
                  value={this.state.name}
                  label="Name"
                  onChange={this.handleInputChange}
                  name="name"
                  // display="row"
                  placeholder="Enter Partner Name"
                  tabIndex="2"
                  maxLength={25}
                  required
                />
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col-md-4">
                <label>
                  Partner Logo
                  <span style={{ color: "#ff0000" }}>*</span>
                </label>
              </div>
              <div className="form-group">
                <ImageUploadButton
                  text="Upload"
                  images={[this.state.logo]}
                  onSave={(images) => {
                    let logo = images[0];
                    this.setState({
                      logo,
                    });
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-2">
              <div className="col-md-4">
                Partner Images
                <Tooltip
                  placement="topLeft"
                  title={() => (
                    <div
                      style={{
                        height: "fit-content",
                        width: "400px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      Image dimensions are 800x450 (16:9 ratio).
                    </div>
                  )}
                >
                  <span className="border-0">
                    &nbsp;<i className="fa fa-question-circle-o"></i>
                  </span>
                </Tooltip>
              </div>
              <div
                className="col-md-8 d-flex"
                style={{
                  paddingLeft: 0,
                  marginLeft: "-0.5rem",
                  gap: 5,
                }}
              >
                {Array.from({ length: 5 }, (_, index) => {
                  return (
                    <div className="mx-2" key={index}>
                      <ImageUploadButton
                        text={`Upload #${index + 1}`}
                        images={[this.state.galleryImages[index]]}
                        aspectRatio={16 / 9}
                        onSave={(images) => {
                          let galleryImages = this.state.galleryImages;
                          galleryImages[index] = images[0];
                          this.setState({
                            galleryImages,
                          });
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col-md-4">
                <label>
                  Details
                  <span style={{ color: "#ff0000" }}>*</span>
                  <Tooltip
                    placement="topLeft"
                    title={() => (
                      <div
                        style={{
                          height: "fit-content",
                          width: "200px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        Max 500 characters
                      </div>
                    )}
                  >
                    <span className="border-0">
                      &nbsp;<i className="fa fa-question-circle-o"></i>
                    </span>
                  </Tooltip>
                </label>
              </div>
              <div
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: 5,
                  width: "66%",
                }}
              >
                <Editor
                  editorState={
                    typeof this.state.details === "object"
                      ? this.state.details
                      : undefined
                  }
                  onEditorStateChange={(state) =>
                    this.handleEditorStateChange(state, "details")
                  }
                  toolbar={{
                    options: ["inline", "list", "link"],
                    inline: {
                      inDropdown: false,
                      options: ["bold", "italic", "underline"],
                    },
                    list: {
                      inDropdown: false,
                      options: ["unordered", "ordered"],
                    },
                    link: {
                      inDropdown: false,
                      defaultTargetOption: "_self",
                      options: ["link", "unlink"],
                      linkCallback: undefined,
                    },
                  }}
                  stripPastedStyles={true}
                  handleBeforeInput={this._handleBeforeInput}
                  handlePastedText={this._handlePastedText}
                  tabIndex="2"
                />
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-4">
              <div
                className={`form-group col-md-12`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <p className="col-md-4">Discount Detail</p>
                <div className="row col-md-8">
                  <div className="col-md-6">
                    <FInput
                      value={this.state.discounts}
                      onChange={this.handleInputChange}
                      name="discounts"
                      maxLength={150}
                      tabIndex="2"
                    />
                  </div>

                  <div className="col-md-6">
                    <span className="form-text text-muted">
                      (Optional) Add brief discount summary. Will be appended to
                      details. Max 150 characters
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col-md-4">
                QR code
                <Tooltip
                  placement="topLeft"
                  title={() => (
                    <div
                      style={{
                        height: "fit-content",
                        width: "400px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      Upload QR code if provided by partner.
                    </div>
                  )}
                >
                  <span className="border-0">
                    &nbsp;<i className="fa fa-question-circle-o"></i>
                  </span>
                </Tooltip>
              </div>
              <div className="form-group">
                <ImageUploadButton
                  text="Upload"
                  aspectRatio={1}
                  images={[this.state.qrImage]}
                  onSave={(images) => {
                    let qrImage = images[0];
                    this.setState({
                      qrImage,
                    });
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-4">
              <div className="col-md-4">
                Display partner on
                <Tooltip
                  placement="topLeft"
                  title={() => (
                    <div
                      style={{
                        height: "fit-content",
                        width: "400px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      Partners appear under More / Partners. Select one partner
                      to feature in the app home feed.
                    </div>
                  )}
                >
                  <span className="border-0">
                    &nbsp;<i className="fa fa-question-circle-o"></i>
                  </span>
                </Tooltip>
              </div>
              <div className="d-flex" style={{ gap: 50 }}>
                <div>
                  <div className="d-flex" style={{ gap: 10 }}>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        checked={this.state.displayOn["App: Partners"]}
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: 5,
                        }}
                        onChange={(e) => {
                          let displayOn = this.state.displayOn;
                          displayOn["App: Partners"] = e.target.checked;
                          this.setState({
                            displayOn,
                          });
                        }}
                      />
                    </div>
                    <div>App: Partners</div>
                  </div>
                </div>
                <div>
                  <div className="d-flex" style={{ gap: 10 }}>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        checked={this.state.displayOn["App: Home"]}
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: 5,
                        }}
                        onChange={(e) => {
                          let displayOn = this.state.displayOn;
                          displayOn["App: Home"] = e.target.checked;
                          this.setState({
                            displayOn,
                          });
                        }}
                      />
                    </div>
                    <div>App: Home</div>
                  </div>
                </div>
                <div>
                  <div className="d-flex" style={{ gap: 10 }}>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        checked={this.state.displayOn["Website: Partners"]}
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: 5,
                        }}
                        onChange={(e) => {
                          let displayOn = this.state.displayOn;
                          displayOn["Website: Partners"] = e.target.checked;
                          this.setState({
                            displayOn,
                          });
                        }}
                      />
                    </div>
                    <div>Website: Partners</div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col-md-4">
                Collect donations?
                <Tooltip
                  placement="topLeft"
                  title={() => (
                    <div
                      style={{
                        height: "fit-content",
                        width: "400px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      Stripe account required. Request from support@stripe.com.
                    </div>
                  )}
                >
                  <span className="border-0">
                    &nbsp;<i className="fa fa-question-circle-o"></i>
                  </span>
                </Tooltip>
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                <div className="form-group">
                  <input
                    type="checkbox"
                    checked={this.state.collectDonations}
                    name="collectDonations"
                    style={{ height: "20px", width: "20px", borderRadius: 5 }}
                    onChange={(e) => {
                      this.setState({
                        collectDonations: e.target.checked,
                      });
                    }}
                  />
                </div>
                <div style={{ display: "contents" }}>
                  <span className="ml-2" style={{ fontSize: 12 }}>
                    Stripe account required.
                  </span>
                </div>
              </div>
            </div>

            {this.state.collectDonations && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  paddingBottom: "10px",
                }}
              >
                <div className="col-md-4">
                  Suggested amounts(
                  {getCurrencyUnicode(
                    (this.props.paymentDetails || {}).currency
                  )}
                  )
                  <Tooltip
                    placement="topLeft"
                    title={() => (
                      <div
                        style={{
                          height: "fit-content",
                          width: "400px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        Enter values for suggested donation amounts. A fourth
                        option will appear automatically allowing members to
                        donate a custom amount.
                      </div>
                    )}
                  >
                    <span className="border-0">
                      &nbsp;<i className="fa fa-question-circle-o"></i>
                    </span>
                  </Tooltip>
                </div>
                <div className="col-md-8 d-flex" style={{ gap: 40 }}>
                  <FInput
                    className="no-arrow-field"
                    value={this.state.suggestedAmounts[0]}
                    min={0}
                    max={999}
                    onChange={(e) => {
                      if (e.target.value.length > 4) {
                        return;
                      }
                      e.preventDefault();
                      let suggestedAmounts = this.state.suggestedAmounts;
                      if (Number(e.target.value) > 0) {
                        suggestedAmounts[0] =
                          e.target.value.indexOf(".") !== -1
                            ? Number(
                                e.target.value.slice(
                                  0,
                                  e.target.value.indexOf(".") + 3
                                )
                              )
                            : Number(e.target.value);
                        this.handleInputChange({
                          target: {
                            name: "suggestedAmounts",
                            value: suggestedAmounts,
                          },
                        });
                      } else if (Number(e.target.value) === 0) {
                        suggestedAmounts[0] = "";
                        this.handleInputChange({
                          target: {
                            name: "suggestedAmounts",
                            value: suggestedAmounts,
                          },
                        });
                      } else {
                        this.handleInputChange({
                          target: {
                            name: "suggestedAmounts",
                            value: suggestedAmounts,
                          },
                        });
                      }
                    }}
                    name="suggestedAmounts"
                    // display="row"
                    tabIndex="2"
                    type="number"
                    style={{ width: 100 }}
                  />

                  {[1, 2, 3].map((idx) => (
                    <FInput
                      className="no-arrow-field"
                      value={this.state.suggestedAmounts[idx]}
                      min={0}
                      max={999}
                      onChange={(e) => {
                        if (e.target.value.length > 4) {
                          return;
                        }
                        let suggestedAmounts = this.state.suggestedAmounts;
                        suggestedAmounts[idx] =
                          e.target.value.indexOf(".") !== -1
                            ? Number(
                                e.target.value.slice(
                                  0,
                                  e.target.value.indexOf(".") + 3
                                )
                              )
                            : Number(e.target.value);
                        if (suggestedAmounts[idx] === 0) {
                          suggestedAmounts[idx] = "";
                        }
                        this.handleInputChange({
                          target: {
                            name: "suggestedAmounts",
                            value: suggestedAmounts,
                          },
                        });
                      }}
                      name="suggestedAmounts"
                      // display="row"
                      tabIndex="2"
                      type="number"
                      style={{ width: 100 }}
                    />
                  ))}
                </div>
              </div>
            )}

            {this.state.collectDonations && (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="col">
                  <FSelect
                    flex
                    label="Processing Fee"
                    name="processingFee"
                    // display="row"
                    tabIndex="5"
                    bottomHint="Stripe (2.9% + $.030) + Chant (3%). See '?' for additional details."
                    tooltip={
                      <div>
                        <p style={{ fontWeight: "bold" }}>Fees</p>
                        <p style={{ marginBottom: 0 }}>
                          Stripe card processing fee: 2.9% + $0.30 (can vary)
                        </p>
                        <p>Chant fee: 3%</p>

                        <p style={{ fontWeight: "bold" }}>Member Pay</p>
                        <p>
                          Stripe collects the fee on the entire purchase amount.
                          So, if a member covers fees that is added to the total
                          charged ($10 item + $0.59 fee paid by member for
                          $10.59) stripe will collect a fee on the entire
                          $10.59.
                        </p>

                        <p>
                          To account for this we add 3% + $.30 (.1% more) to the
                          original item price for Stripe so your net is close to
                          the item price - it may be off very slightly.
                        </p>

                        <p>
                          Stripe fees can vary based on member payment method
                          but are not known until after the transaction is
                          complete (foreign card, etc). Your net proceeds may
                          not cover the full item amount but should be close.
                        </p>

                        <p style={{ fontWeight: "bold" }}>
                          Member Pay (legal Limitations)
                        </p>
                        <p>
                          Not all states/countries allow the fee to be passed to
                          the member. Chant is not responsible for determining
                          your local laws. Please confirm this for your
                          location.
                        </p>
                      </div>
                    }
                    required={this.state.collectDonations}
                    value={this.state.processingFee}
                    onChange={this.handleOptionChange}
                    placeholder="--Select--"
                    dataOnlyValue
                    dataList={ProcessingFeeTypes}
                  />
                </div>
              </div>
            )}

            <div
              style={{ display: "flex", flexWrap: "wrap" }}
              className="mt-3 justify-content-center"
            >
              <button
                className="btn btn-success"
                tabIndex="14"
                type="success"
                style={{
                  backgroundColor: "var(--primary-background)",
                  color: "var(--primary-text-color)",
                  borderColor: primaryColor,
                  padding: "3px 30px",
                }}
                onClick={this.handleSubmit}
              >
                {this.state.saving ? (
                  <Spin size="small" spinning={true} />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentGroup: state.adminData.currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    paymentDetails:
      state.adminData.allGroups[state.adminData.currentGroup].data
        .paymentDetails,
  };
};

export default connect(mapStateToProps)(withRouter(CreatePartners));
