import { Popconfirm, Spin, Tooltip } from "antd";
import React from "react";
import {
  createRegistrationLink,
  fbChangeGroupRegistrationCode,
} from "../../../services/firebaseService/endPoints/admin/groupConfig";
import OptionContainer from "./OptionContainer";
import generateRegistrationCode from "../../../helperFunctions/generateRegistrationCode";
import { setRegCodeRole } from "../../../services/indexedDb/members";
import html2canvas from "html2canvas";
import { generateQR } from "../../../helperFunctions/util";
import { BulkIcon } from "../../../utils/icons.jsx";
import ReactChipInput from "../../../CommonComponents/ReactChipInput";
import "../members/Onboard/Onboard.css";

const ROLES = [
  {
    label: "Member",
    value: "member",
  },
  {
    label: "Non-member",
    value: "fan",
  },
];

const SIZE = 150;

class Onboard extends React.Component {
  state = {
    isLoading: false,
    registrationCode: "",
    registrationLink: "",
    editStatus: false,
    regCodeRole: "",
    showModal: false,
    svg: "",
    groupId: "",
  };

  static getDerivedStateFromProps(props, state) {
    if (state.groupId !== props.currentGroup) {
      return {
        regCodeRole: props.regCodeRole,
        registrationLink: props.registrationLink,
        registrationCode: props.registrationCode,
        groupId: props.currentGroup,
      };
    }
    return null;
  }

  handleChangeRegistrationCode = async () => {
    this.setState({ isLoading: true });
    const registrationCode = generateRegistrationCode();
    await fbChangeGroupRegistrationCode(
      this.props.currentGroup,
      registrationCode
    ).then((link) => {
      generateQR(link, this.props.groupLogo, SIZE).then((svg) => {
        this.setState({
          registrationCode,
          isLoading: false,
          registrationLink: link,
          svg,
        });
      });
    });
  };

  openModal = () => {
    this.setState({
      showModal: true,
    });
  };

  updateRegCodeRole = (role) => {
    this.setState({
      regCodeRole: role,
      editStatus: true,
    });
  };

  saveRegCodeRole = () => {
    return new Promise((resolve, reject) => {
      setRegCodeRole(this.state.regCodeRole, this.props.currentGroup)
        .then(async () => {
          if (!this.state.registrationLink && this.state.registrationCode) {
            const link = await createRegistrationLink(
              this.state.registrationCode,
              this.props.currentGroup,
              this.state.regCodeRole
            );
            const svg = await generateQR(link, this.props.groupLogo, SIZE);
            this.setState({
              registrationLink: link,
              svg: svg,
            });
          } else if (!this.state.registrationCode) {
            await this.handleChangeRegistrationCode();
          }
        })
        .then(resolve)
        .catch(reject);
    });
  };

  downloadAsImage = (id) => {
    let htmlElem = document.getElementById(id);
    html2canvas(htmlElem).then((canvas) => {
      let imageUrl = canvas.toDataURL("image/png");
      this.downloadURI("data:" + imageUrl, "QRCode.png");
    });
  };

  downloadURI = (uri, filename) => {
    let xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = () => {
      let blob = xhr.response;
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    };
    xhr.open("GET", uri);
    xhr.send();
  };

  componentDidMount() {
    generateQR(this.props.registrationLink, this.props.groupLogo, SIZE).then(
      (svg) => {
        this.setState({ svg });
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentGroup !== this.props.currentGroup) {
      generateQR(this.props.registrationLink, this.props.groupLogo, SIZE).then(
        (svg) => {
          this.setState({ svg });
        }
      );
    }
  }

  render() {
    return (
      <>
        <div className="d-flex">
          <div style={{ flex: 1, display: "flex" }}>
            <div className="pb-4" style={{ marginTop: 20 }}>
              <p style={{ fontSize: 16 }}>
                Control group access by uploading member emails (options 1, 2)
                or selling membership packages (option 3). Have member data to
                import (address, shirt size, etc)? Email{" "}
                <a href="mailto:support@chant.fan">support@chant.fan</a>.
              </p>
              <p style={{ fontSize: 16 }}>
                For 'open' groups, share a QR code / link (option 4). Set for
                members (full access) or 'non-members' (limited app
                permissions). This provides open access, so share carefully.
              </p>
              <p style={{ fontSize: 16 }}>
                <span style={{ color: "#ff4040" }}>Important:</span> Chant does
                not email members. Notify members via email, social or sharing
                the <a href="/admin/members/promote"> Promote</a> image.
              </p>
            </div>
          </div>
        </div>
        <div style={{ width: "100%", overflow: "auto" }}>
          <div
            className="row"
            style={{
              marginTop: 5,
              justifyContent: "space-between",
              // minWidth: 1208,
            }}
          >
            <div
              style={{
                width: "calc(75% - 20px)",
                height: 30,
                backgroundColor: this.props.backgroundColor,
                marginLeft: 10,
                marginRight: 10,
                color: "#ffffff",
                textAlign: "center",
                fontSize: 19,
                borderRadius: "6px",
              }}
            >
              Members
            </div>
            <div
              style={{
                display: "flex",
                width: "calc(25% - 20px)",
                height: 30,
                backgroundColor:
                  !this.state.editStatus && this.state.regCodeRole === "member"
                    ? this.props.backgroundColor
                    : "#000000",
                marginLeft: 10,
                marginRight: 10,
                color: "#ffffff",
                textAlign: "center",
                fontSize: 19,
                justifyContent: "center",
                position: "relative",
                borderRadius: "6px",
              }}
            >
              {!this.state.editStatus && this.state.regCodeRole === "member"
                ? "Member"
                : !this.state.editStatus && this.state.regCodeRole === "fan"
                  ? "Non-members"
                  : "Set Role"}
              {!this.state.editStatus && this.state.regCodeRole && (
                <div
                  className="px-1"
                  style={{
                    border: "1px solid #ffffff",
                    cursor: "pointer",
                    color: "#ffffff",
                    fontSize: 15,
                    alignSelf: "flex-end",
                    position: "absolute",
                    right: 5,
                    margin: "auto 0px auto",
                    top: 2,
                    bottom: 2,
                    borderRadius: "8px",
                  }}
                  onClick={this.openModal}
                >
                  Edit Role
                </div>
              )}
            </div>
          </div>
          <div className="onboard-options-container row justify-content-space-between">
            <OptionContainer
              title="Option 1"
              subtitle="Add Individual"
              color={this.props.backgroundColor}
            >
              <>
                <div className="row justify-content-center mt-3">
                  <p style={{ marginBottom: 0 }}>
                    {" "}
                    To pre-register an individual or
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {" "}
                    small number of members, type{" "}
                  </p>
                  <p style={{ marginBottom: 0 }}> each email address below.</p>
                </div>
                <div className="row justify-content-center mt-3">
                  <p style={{ marginBottom: 0 }}>
                    {" "}
                    Press 'enter' on your keyboard after
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {" "}
                    each email. When done adding{" "}
                  </p>
                  <p style={{ marginBottom: 0 }}> emails click 'Upload'. </p>
                </div>
                <div className="row justify-content-center mt-3">
                  <div style={{ color: "#000000" }}>
                    <ReactChipInput
                      chips={this.props.chips}
                      onSubmit={(value) => this.props.addChip(value)}
                      onRemove={(index) => this.props.removeChip(index)}
                      classes="custom-direction-column"
                      validateEmail={true}
                    />
                  </div>
                </div>
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="justify-content-center mt-3"
                >
                  <div>
                    <button
                      style={{
                        backgroundColor:
                          this.props.chips.length !== 0
                            ? this.props.backgroundColor
                            : "#afafaf",
                        display: "inline-block",
                        padding: "10px 30px",
                        borderRadius: 25,
                        boxShadow: "transparent 0px 0px 0px",
                        outline: "none",
                        outlineOffset: "unset",
                        border: "0px none transparent",
                      }}
                      loading={this.props.downloading}
                      type={"danger"}
                      className="mr-1 default-text-color"
                      size="default"
                      onClick={this.props.uploadCSVToFirebase}
                      disabled={this.props.chips.length === 0}
                    >
                      {this.props.runningService ? (
                        <Spin size="small" spinning={true} />
                      ) : (
                        "Upload"
                      )}
                    </button>
                  </div>
                </div>
              </>
            </OptionContainer>
            <OptionContainer
              title="Option 2"
              subtitle="Bulk Upload"
              color={this.props.backgroundColor}
            >
              <>
                <div className="row justify-content-center mt-3">
                  <p style={{ marginBottom: 0 }}>
                    {" "}
                    To pre-register your full member
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {" "}
                    list, upload a CSV file with emails.
                  </p>
                </div>
                <div className="row justify-content-center mt-3">
                  <form
                    action="."
                    enctype="multipart/form-data"
                    style={{ width: 203 }}
                  >
                    <input
                      id="uploadCSVFileId"
                      type="file"
                      accept=".csv"
                      style={{ color: "#FF0000" }}
                      onChange={this.props.uploadCSV}
                    />
                  </form>
                </div>
                <div className="row justify-content-center mt-3">
                  <p style={{ marginBottom: 0 }}>
                    {" "}
                    Upload 500 emails at a time.
                  </p>
                </div>
                <div
                  className="row justify-content-center mt-5 px-1"
                  style={{ textAlign: "center" }}
                >
                  <p>
                    CSV file should have a single column of emails with 'Emails'
                    in the header. Create a CSV from a spreadsheet using File /
                    Save As.
                  </p>
                </div>
                {this.props.csvFile !== null && (
                  <div className="row justify-content-center mt-3">
                    <button
                      style={{
                        backgroundColor: this.props.backgroundColor,
                        display: "inline-block",
                        padding: "10px 30px",
                        borderRadius: 25,
                        boxShadow: "transparent 0px 0px 0px",
                        outline: "none",
                        outlineOffset: "unset",
                        border: "0px none transparent",
                      }}
                      loading={this.props.downloading}
                      type={"danger"}
                      className="mr-1"
                      size="default"
                      onClick={this.props.uploadCSVToFirebase}
                    >
                      {this.props.runningService ? (
                        <Spin size="small" spinning={true} />
                      ) : (
                        "Upload"
                      )}
                    </button>
                  </div>
                )}

                <div className="row justify-content-center mt-2">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold", color: "#ff4040" }}>
                      Example
                    </span>
                    <div className="mt-2" style={{ width: "60%" }}>
                      <img
                        alt="Bulk Registration"
                        style={{ width: "100%" }}
                        src={BulkIcon}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="row justify-content-center mt-5 px-1"
                  style={{ textAlign: "center" }}
                >
                  <span
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "#ff4040",
                    }}
                  >
                    Full Data Import
                  </span>
                  <p>Want to import your full member database?</p>
                  <p>
                    Copy and populate this{" "}
                    <a
                      href="https://docs.google.com/spreadsheets/d/1oF9odWkJ5hubdHvfPFbOLZfbjz64Aayoj35fX7XYlkE/edit?usp=sharing"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#ffffff",
                        textDecorationColor: "#ffffff",
                        textDecoration: "underline",
                      }}
                    >
                      template
                    </a>{" "}
                    and share with{" "}
                    <a
                      href="mailto:support@chant.fan"
                      style={{
                        color: "#ffffff",
                        textDecorationColor: "#ffffff",
                        textDecoration: "underline",
                      }}
                    >
                      support@chant.fan
                    </a>
                  </p>
                </div>
              </>
            </OptionContainer>

            <OptionContainer
              title="Option 3"
              subtitle="Registration Form"
              color={this.props.backgroundColor}
            >
              <>
                <div className="row justify-content-center mt-3">
                  <p style={{ marginBottom: 0 }}>
                    {" "}
                    Chant offers supporters groups the{" "}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {" "}
                    option to create a hosted webpage{" "}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {" "}
                    with membership packages to collect{" "}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {" "}
                    annual dues, offer merchandise and
                  </p>
                  <p style={{ marginBottom: 0 }}> ask {"for "} donations. </p>
                </div>
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="justify-content-center mt-3"
                >
                  <button
                    style={{
                      backgroundColor: this.props.backgroundColor,
                      display: "inline-block",
                      padding: "10px 30px",
                      borderRadius: 25,
                      boxShadow: "transparent 0px 0px 0px",
                      outline: "none",
                      outlineOffset: "unset",
                      border: "0px none transparent",
                    }}
                    loading={this.props.downloading}
                    type={"danger"}
                    className="mr-1 default-text-color"
                    onClick={() => {
                      window.location.href = "/admin/members/packages/setup";
                    }}
                  >
                    Customize Form
                  </button>
                </div>
                <div className="row justify-content-center mt-3">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ marginBottom: 0 }}>See examples: </p>
                    <p style={{ marginBottom: 0 }}>
                      <a
                        href="https://group.chant.fan/olscatlanta/join"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Liverpool Atlanta
                      </a>
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <a
                        href="http://group.chant.fan/losangelesredarmy/join"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Los Angeles Red Army
                      </a>
                    </p>
                  </div>
                </div>
              </>
            </OptionContainer>

            <OptionContainer
              title="Option 4"
              subtitle={
                "Share Link / QR Code"
                // (!this.state.editStatus && this.state.regCodeRole === 'member') ? "Member Access" : !this.state.editStatus && this.state.regCodeRole && "‘Fan’ Access"
              }
              color={this.props.backgroundColor}
            >
              {this.state.showModal && (
                <div
                  style={{
                    position: "absolute",
                    width: "90%",
                    height: "80%",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <span
                      style={{
                        color: "#000000",
                        paddingRight: 20,
                        fontSize: 19,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        let stateObj = {
                          showModal: false,
                        };

                        this.setState({
                          showModal: false,
                          regCodeRole: this.props.regCodeRole,
                          editStatus: false,
                        });
                      }}
                    >
                      x
                    </span>
                  </div>
                  <div className="row justify-content-center mt-3">
                    <p style={{ marginBottom: 0, color: "#000000" }}>
                      {" "}
                      Provide access to either
                    </p>
                    <p style={{ marginBottom: 0, color: "#000000" }}>
                      {" "}
                      members (full access) or{" "}
                    </p>
                    <p style={{ marginBottom: 0, color: "#000000" }}>
                      {" "}
                      non-members (restricted access).{" "}
                    </p>
                  </div>
                  <div className="row justify-content-center mt-3">
                    <div style={{ textAlign: "center" }}>
                      <p style={{ marginBottom: 0, color: "#000000" }}>
                        {" "}
                        Changing this setting does{" "}
                      </p>
                      <p style={{ marginBottom: 0, color: "#000000" }}>
                        {" "}
                        not change the role of existing{" "}
                      </p>
                      <p style={{ marginBottom: 0, color: "#000000" }}>
                        {" "}
                        members.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div>
                      <select
                        value={this.state.regCodeRole}
                        placeholder="Set role"
                        style={{ background: "transparent", color: "#ff4040" }}
                        onChange={(e) => {
                          this.updateRegCodeRole(e.target.value);
                        }}
                      >
                        <option key={"select"} value="">
                          Set role
                        </option>
                        {ROLES.map((role) => (
                          <option key={role.value} value={role.value}>
                            {role.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mt-1">
                      <button
                        style={{
                          backgroundColor:
                            this.props.chips.length !== 0
                              ? this.props.backgroundColor
                              : "#afafaf",
                          display: "inline-block",
                          padding: "0px 5px",
                          borderRadius: 5,
                          boxShadow: "transparent 0px 0px 0px",
                          outline: "none",
                          outlineOffset: "unset",
                          border: "0px none transparent",
                        }}
                        loading={this.props.downloading}
                        type={"danger"}
                        className="mr-1 default-text-color"
                        size="default"
                        onClick={() => {
                          this.saveRegCodeRole().then(() => {
                            this.setState({
                              editStatus: false,
                              showModal: false,
                            });
                          });
                        }}
                        disabled={!this.state.editStatus}
                      >
                        {this.props.runningService ? (
                          <Spin size="small" spinning={true} />
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {!this.state.editStatus && this.state.regCodeRole ? (
                <>
                  {this.state.regCodeRole === "fan" && (
                    <>
                      <div className="row justify-content-center mt-3">
                        <p style={{ marginBottom: 0 }}>
                          {" "}
                          Provide group access via web link or QR
                        </p>
                        <p style={{ marginBottom: 0 }}>
                          {" "}
                          code. No need to pre-register members.
                        </p>
                      </div>
                      <div className="row justify-content-center mt-3">
                        <p style={{ marginBottom: 0 }}>
                          {" "}
                          You are required to first set 'Fan'{" "}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                          {" "}
                          (non-member) permissions in Fan Settings.
                        </p>
                      </div>
                      <div className="row justify-content-center mt-3">
                        <button
                          style={{
                            backgroundColor: this.props.backgroundColor,
                            display: "inline-block",
                            padding: "10px 30px",
                            borderRadius: 25,
                            width: "fit-content",
                            boxShadow: "transparent 0px 0px 0px",
                            outline: "none",
                            outlineOffset: "unset",
                            border: "0px none transparent",
                          }}
                          type={"danger"}
                          className="mr-1 default-text-color"
                          onClick={() => {
                            window.location.href = "/admin/members/onboard/fan";
                          }}
                        >
                          {/* <a href="/admin/fanPermission"> */}
                          <span style={{ color: "#ffffff" }}>
                            {" "}
                            Manage Fan Permissions
                          </span>
                          {/* </a> */}
                        </button>
                      </div>
                    </>
                  )}

                  {this.state.regCodeRole === "member" && (
                    <div className="row justify-content-center mt-3">
                      <p style={{ marginBottom: 0 }}>
                        {" "}
                        Share the web link or QR code{" "}
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        {" "}
                        allowing registration. No need to{" "}
                      </p>
                      <p style={{ marginBottom: 0 }}> pre-register members. </p>
                    </div>
                  )}

                  {(this.state.regCodeRole === "member" ||
                    this.props.isFanPermissionSet) && (
                    <>
                      <div
                        className="mt-3"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0px 15px",
                          lineBreak: "anywhere",
                        }}
                      >
                        <div>
                          <p style={{ marginBottom: 0, fontWeight: "bold" }}>
                            {this.state.regCodeRole === "member"
                              ? "Registration"
                              : "Fan Registration"}
                          </p>

                          <p style={{ marginBottom: 0 }}>
                            {this.state.regCodeRole === "member"
                              ? " (Mobile-only link)"
                              : "(Mobile-only link)"}
                          </p>

                          <div
                            style={{ color: "#ffffff", fontSize: 13 }}
                            id="copy-reg-link-clipboard"
                          >
                            {(
                              this.state.registrationLink ||
                              this.props.registrationLink
                            )?.slice(0, 50) + "..."}

                            <Tooltip title="Copy">
                              <span
                                style={{
                                  padding: 7,
                                  cursor: "pointer",
                                  color: "#1890ff",
                                  fontSize: "18px",
                                }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    this.state.registrationLink ||
                                      this.props.registrationLink
                                  );
                                }}
                              >
                                <i className="fa fa-clipboard" />
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      <div
                        className="mt-3"
                        style={{
                          padding: "0px 15px",
                        }}
                      >
                        <div>
                          <p style={{ marginBottom: 0, fontWeight: "bold" }}>
                            {this.state.regCodeRole === "member"
                              ? "Registration "
                              : "Fan Registration"}{" "}
                          </p>

                          <p style={{ marginBottom: 0 }}>(QR Code) </p>

                          {(this.state.registrationLink ||
                            this.props.registrationLink) && (
                            <div id="reg-qr-code">
                              {/* <QRCode
                                                value={this.state.registrationLink || this.props.registrationLink}
                                                size={SIZE}
                                                logoImage={this.props.groupLogo || ''}
                                                logoHeight={0.3 * SIZE}
                                                logoWidth={0.3 * SIZE}
                                                logoOpacity={0.5}
                                            /> */}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: `${this.state.svg}`,
                                }}
                              ></span>
                            </div>
                          )}
                        </div>
                        <div
                          style={{ padding: 7, cursor: "pointer" }}
                          onClick={() => {
                            this.downloadAsImage("reg-qr-code");
                          }}
                        >
                          <i className="fa fa-download" />
                        </div>
                      </div>

                      <div className="row justify-content-center mt-3">
                        <p style={{ marginBottom: 0 }}>
                          If you feel these have been shared{" "}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                          inappropriately generate a new code.{" "}
                        </p>
                      </div>
                      <div
                        style={{ display: "flex", flexWrap: "wrap" }}
                        className="justify-content-center mt-3"
                      >
                        <Popconfirm
                          title="Changing this code will make the previous registration code invalid. Are you sure?"
                          onConfirm={this.handleChangeRegistrationCode}
                          onCancel={() => {}}
                          okText="Yes"
                          cancelText="No"
                        >
                          <button
                            style={{
                              backgroundColor: this.props.backgroundColor,
                              display: "inline-block",
                              padding: "10px 30px",
                              borderRadius: 25,
                              boxShadow: "transparent 0px 0px 0px",
                              outline: "none",
                              outlineOffset: "unset",
                              border: "0px none transparent",
                            }}
                            loading={this.state.isLoading}
                            type={"danger"}
                            className="mr-1 default-text-color"
                          >
                            Generate New Code
                          </button>
                        </Popconfirm>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="row justify-content-center mt-3">
                    <p style={{ marginBottom: 0 }}>
                      {" "}
                      Provide group access via{" "}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {" "}
                      web link or QR code. No need{" "}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {" "}
                      to pre-register members.{" "}
                    </p>
                  </div>

                  <div className="row justify-content-center mt-3">
                    <p style={{ marginBottom: 0 }}>
                      {" "}
                      Set option to provide access to{" "}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {" "}
                      either members (full access) or{" "}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {" "}
                      non-members (restricted access).{" "}
                    </p>
                  </div>

                  <div className="row justify-content-center mt-3">
                    <p style={{ marginBottom: 0 }}>
                      {" "}
                      Non-member role {"requires "}{" "}
                    </p>
                    <p style={{ marginBottom: 0 }}> setup (under Settings). </p>
                  </div>

                  <div className="d-flex flex-column align-items-center mt-3">
                    <div>
                      <select
                        value={this.state.regCodeRole}
                        placeholder="Set role"
                        style={{ background: "transparent", color: "#ff4040" }}
                        onChange={(e) => {
                          this.updateRegCodeRole(e.target.value);
                        }}
                      >
                        <option key={"select"} value="">
                          Set role
                        </option>
                        {ROLES.map((role) => (
                          <option key={role.value} value={role.value}>
                            {role.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mt-1">
                      <button
                        style={{
                          backgroundColor:
                            this.props.chips.length !== 0
                              ? this.props.backgroundColor
                              : "#afafaf",
                          display: "inline-block",
                          padding: "0px 5px",
                          borderRadius: 5,
                          boxShadow: "transparent 0px 0px 0px",
                          outline: "none",
                          outlineOffset: "unset",
                          border: "0px none transparent",
                        }}
                        loading={this.props.downloading}
                        type={"danger"}
                        className="mr-1"
                        size="default"
                        onClick={() => {
                          this.saveRegCodeRole().then(() => {
                            this.setState({
                              editStatus: false,
                            });
                          });
                        }}
                        disabled={!this.state.editStatus}
                      >
                        {this.props.runningService ? (
                          <Spin size="small" spinning={true} />
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </OptionContainer>
          </div>
        </div>
      </>
    );
  }
}

export default Onboard;
