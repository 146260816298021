import { Modal, Popconfirm, Select } from "antd";
import React, { useState } from "react";
import ChantButton from "../../../../CommonComponents/ChantButton/ChantButton";
import DescriptionText from "../../../../CommonComponents/DescriptionText";

const OrderUpdateModal = ({
  openUpdateModal,
  setOpenUpdateModal,
  selectedRows,
  editedRecords,
  handleRecordStatusChange,
  handleUpdateStatus,
  statusUpdateLoading,
  orderStatus,
  setOrderStatus,
}) => {
  const options = [
    { label: "--Select--", value: "select" },
    { label: "Shipped", value: "shipped" },
    { label: "Picked up", value: "pickedup" },
    { label: "Refunded", value: "refunded" },
    { label: "Unclaimed", value: "unclaimed" },
    { label: "Unfulfilled", value: "unfulfilled" },
  ];

  const handleStatusChange = (value) => {
    setOrderStatus(value);
    selectedRows.forEach((row) => {
      const [id, itemIndex] = row.split("|");

      let records = editedRecords || {};
      records[id] = {
        ...(editedRecords[id] || {}),
      };
      records[id][itemIndex] = value;

      handleRecordStatusChange(records);
    });
  };

  return (
    <Modal
      open={openUpdateModal}
      onCancel={() => {
        setOpenUpdateModal(false);
      }}
      width={500}
      height={500}
      title={"Update Order Status"}
      centered
      footer={[
        <Popconfirm
          title={`Update ${selectedRows?.length} ${selectedRows?.length > 1 ? "orders" : "order"}?`}
          onConfirm={handleUpdateStatus}
          okText="Yes"
          cancelText="No"
        >
          <ChantButton
            key="submit"
            type="primary"
            disabled={orderStatus === "select" || statusUpdateLoading}
            loading={statusUpdateLoading}
          >
            Update
          </ChantButton>
        </Popconfirm>,
      ]}
    >
      <DescriptionText>
        <p style={{ marginBottom: 0 }}>
          {`NOTE: Clicking update will update all selected orders (${selectedRows?.length})`}
        </p>
      </DescriptionText>

      <div
        style={{
          paddingTop: 20,
          width: "50%",
          maxHeight: "500px",
          overflow: "auto",
        }}
      >
        <Select
          disabled={statusUpdateLoading}
          value={orderStatus}
          popupMatchSelectWidth={false}
          onChange={handleStatusChange}
          style={{ width: "100%" }}
          options={options?.map((item) => ({
            value: item.value,
            label: <span>{item.label}</span>,
          }))}
        />
      </div>
    </Modal>
  );
};

export default OrderUpdateModal;
