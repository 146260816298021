const logger = (() => {
  const env = import.meta.env.VITE_NODE_ENV;
  const isDevelopment = env === "development";

  return {
    log: (...args) => {
      if (isDevelopment) {
        console.log(...args);
      }
    },
    warn: (...args) => {
      if (isDevelopment) {
        console.warn(...args);
      }
    },
    error: (...args) => {
      console.error.apply(console, args);
      console.trace();
    },
  };
})();

export default logger;
