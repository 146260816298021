import React, { Component } from "react";

class ViewMoreText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleViewMore = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };

  render() {
    const { text, maxLength } = this.props;
    const { expanded } = this.state;

    const needsTruncation = text.length > maxLength;
    const displayText =
      expanded || !needsTruncation
        ? text
        : text.substring(0, maxLength) + "...";

    return (
      <div>
        <span style={{ textWrap: "wrap" }}>{displayText}</span>
        {needsTruncation && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            style={{ color: "var(--primary-background-darker)" }}
            onClick={this.toggleViewMore}
          >
            {expanded ? "...View Less" : "View More"}
          </a>
        )}
      </div>
    );
  }
}

export default ViewMoreText;
