import React from "react";

import { Upload, Modal, Tooltip } from "antd";
import ImageCrop from "../ImageCrop";
import { PlusCircleFilled } from "@ant-design/icons";

// import React from "react";
// import { Upload, Modal, Icon, Tooltip } from "antd";
// import ImageCrop from "../ImageCrop";

const uploadButton = (
  <div>
    <PlusCircleFilled />
    <div className="ant-upload-text">Upload</div>
  </div>
);

class FUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: "",
    };
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleCancel = () => {
    this.setState({ previewVisible: false });

    // if (this.state.rejectUpload) {
    //   this.state.rejectUpload("Upload canceled by user");
    // }

    // this.setState({
    //   showCrop: false,
    //   originalFile: null,
    //   baseFile: null,
    //   resolveUpload: null,
    //   rejectUpload: null,
    // });
  };

  handleOk = (croppedFile) => {
    if (this.state.resolveUpload) {
      this.state.resolveUpload(croppedFile);
    }

    this.setState({
      showCrop: false,
      originalFile: null,
      baseFile: null,
      resolveUpload: null,
      rejectUpload: null,
    });
  };

  handleCropComplete = (imageFile) => {
    this.setState({ showCrop: false });
    if (this.props.onLoad) {
      this.getBase64(imageFile).then((baseFile) => {
        this.props.onLoad(baseFile);
      });
    }
    this.props.onChange({
      fileList: [{ ...this.state.originalFile, originFileObj: imageFile }],
    });
  };

  handleCancelImageCrop = () => {
    this.setState({ showCrop: false, originalFile: "" });
  };

  render() {
    const { isAvatar } = this.props;
    return (
      <div
        className={`form-group ${this.props.display !== "col" ? "my-row" : "col"}`}
        style={this.props.style}
      >
        <div className="col-4" style={!this.props.label ? { height: 0 } : {}}>
          <label>
            {" "}
            {this.props.label}
            {this.props.required && <span style={{ color: "red" }}>*</span>}
          </label>
          {this.props.tooltip && (
            <Tooltip
              placement="topLeft"
              title={() => (
                <div
                  style={{
                    height: "fit-content",
                    width: "400px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {this.props.tooltip}
                </div>
              )}
            >
              <span className="border-0">
                &nbsp;<i className="fa fa-question-circle-o"></i>
              </span>
            </Tooltip>
          )}
        </div>
        <div className="clearfix">
          <Upload
            onRemove={this.props.onRemove}
            action={this.props.action}
            listType="picture-card"
            fileList={this.props.fileList}
            onPreview={this.handlePreview}
            onChange={!this.props.viewMode && this.props.onChange}
            width="500"
            beforeUpload={(file) => {
              return new Promise((resolve, reject) => {
                this.getBase64(file)
                  .then((baseFile) => {
                    // Show cropping modal
                    this.setState({
                      showCrop: true,
                      originalFile: file,
                      baseFile,
                      resolveUpload: resolve, // Save the resolve to call later
                      rejectUpload: reject, // Save the reject to call later
                    });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            }}
            className={`${
              this.props.aspectRatio ? this.props.aspectRatio : "si9"
            }`}
            openFileDialogOnClick={!this.props.viewMode}
          >
            {this.props.fileList.length >= 1 ? null : uploadButton}
          </Upload>

          <Modal
            open={this.state.previewVisible}
            footer={null}
            onCancel={this.handleCancel}
            onOk={this.handleOk}
          >
            <div style={{ padding: "30px 0px" }}>
              <img
                alt="example"
                style={{ width: "100%" }}
                src={this.state.previewImage}
              />
            </div>
          </Modal>

          {this.state.showCrop && (
            <ImageCrop
              className="ReactCrop__crop-selection"
              //   style={{ borderRadius: "50% !important" }}
              membershipCard={this.props.membershipCard}
              isAvatar={isAvatar ? "true" : "false"}
              src={this.state.baseFile}
              handleCancelImageCrop={this.handleCancelImageCrop}
              handleCropComplete={this.handleCropComplete}
              aspectRatio={this.props.aspectRatio}
            />
          )}
        </div>
      </div>
    );
  }
}

export default FUpload;
