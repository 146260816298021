import React from "react";
import ChantButton from "../../../../CommonComponents/ChantButton/ChantButton";
import { Input, Select } from "antd";
import {
  FULFILLMENT_STATUS_ALL,
  FULFILLMENT_STATUS_FULFILLED,
  FULFILLMENT_STATUS_REFUNDED,
  FULFILLMENT_STATUS_UNCLAIMED,
  FULFILLMENT_STATUS_UNFULFILLED,
  SHIPPING_STATUS_ALL,
  SHIPPING_STATUS_PICKUP,
  SHIPPING_STATUS_SHIP,
} from "../../../../constants/orders";
import { isMobile } from "../../../../utils/helper";
import { SearchOutlined } from "@ant-design/icons";

const OrderFilters = ({ filters, handleFilterChange, handleUpdateOrder }) => {
  const FULFILLMENT_FILTERS = [
    FULFILLMENT_STATUS_ALL,
    FULFILLMENT_STATUS_FULFILLED,
    FULFILLMENT_STATUS_UNFULFILLED,
    FULFILLMENT_STATUS_REFUNDED,
    FULFILLMENT_STATUS_UNCLAIMED,
  ];

  const SHIPMENT_FILTERS = [
    SHIPPING_STATUS_ALL,
    SHIPPING_STATUS_SHIP,
    SHIPPING_STATUS_PICKUP,
  ];

  return (
    <div
      className="orders-filter-container"
      // style={{ padding: "0px 10px" }}
      collapsible-horizontal
    >
      <div>
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search item / name / email"
          onChange={(e) => {
            handleFilterChange("search", e.target.value);
          }}
          value={filters.search}
          style={{ width: isMobile() ? "100%" : "15rem" }}
        />
      </div>
      <div className="orders-dropdown-filters">
        <Select
          className="orders-filter-input"
          defaultActiveFirstOption
          // placeholder="Status"
          popupMatchSelectWidth={false}
          options={FULFILLMENT_FILTERS?.map((item) => ({
            value: item,
            label: <span>{item}</span>,
          }))}
          value={filters.fulfillmentStatus}
          onChange={(val) => handleFilterChange("fulfillmentStatus", val)}
        />
        <Select
          className="orders-filter-input"
          defaultActiveFirstOption
          // placeholder="Status"
          popupMatchSelectWidth={false}
          options={SHIPMENT_FILTERS?.map((item) => ({
            value: item,
            label: <span>{item}</span>,
          }))}
          value={filters.shippingType}
          onChange={(val) => handleFilterChange("shippingType", val)}
        />
      </div>
    </div>
  );
};

export default OrderFilters;
