import React, { useState } from "react";
import { isMobile } from "../../../utils/helper";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { FSelect } from "../../commons/formFields/FSelect";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { FInput } from "../../commons/formFields/FInput";

const PurchasesFilters = ({
  onFilterChange,
  filters,
  types,
  filterPackages,
}) => {
  const { RangePicker } = DatePicker;

  const [filtersExpanded, setFiltersExpanded] = useState(false);

  return (
    <div id="purchase-filter" style={{ marginTop: "2px" }}>
      {isMobile() ? (
        <div
          className="purchase-filters-header"
          onClick={() => setFiltersExpanded((prev) => !prev)}
          style={filtersExpanded ? { borderRadius: "0%" } : undefined}
        >
          <span>Filters</span>
          {filtersExpanded ? (
            <UpOutlined style={{ marginLeft: "auto" }} />
          ) : (
            <DownOutlined style={{ marginLeft: "auto" }} />
          )}
        </div>
      ) : null}

      {(filtersExpanded || !isMobile()) && (
        <div
          className="purchases-filter-container"
          style={{ paddingTop: "0.5rem" }}
        >
          <div
            style={{
              marginLeft: "-6px",
              marginRight: "12px",
            }}
          >
            <FInput
              value={filters.search}
              onChange={(e) => {
                onFilterChange("search", e.target.value);
              }}
              name="search"
              display="row"
              placeholder="Search name / email"
              tabIndex="2"
              style={{
                color: "black",
                fontSize: 16,
                padding: "0px 15px",
              }}
            />
          </div>
          <div>
            <FSelect
              value={filters.packageId}
              onChange={(e) => {
                onFilterChange("packageId", e.target.value);
              }}
              dataList={filterPackages}
              dataKey="key"
              dataValue="value"
              style={{
                backgroundColor: "transparent",
                border: "0px none transparent",
                color: "white",
                borderRadius: 0,
                fontSize: 16,
                width: "200px",
              }}
              name="status"
              display="row"
              placeholder="Package"
              tabIndex="2"
            />
          </div>
          <div>
            <FSelect
              value={filters.type}
              onChange={(e) => {
                onFilterChange("type", e.target.value);
              }}
              dataList={types}
              dataKey="key"
              dataValue="type"
              style={{
                backgroundColor: "transparent",
                border: "0px none transparent",
                color: "white",
                borderRadius: 0,
                fontSize: 16,
                width: "200px",
              }}
              name="Type"
              display="row"
              placeholder="Type"
              tabIndex="3"
            />
          </div>
          <div style={{ marginBottom: "0.75rem" }}>
            <RangePicker
              tabIndex="2"
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
              onChange={(value) => {
                onFilterChange("dateRange", value);
              }}
              className="col"
              size={15}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchasesFilters;
