import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  height: "450px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const DEFAULT_ZOOM = 14;

function MyGoogleMap({ lat, lng, onMarkerDragEnd }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.setZoom(DEFAULT_ZOOM);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      // center={center}
      center={{ lat: lat, lng: lng }}
      zoom={DEFAULT_ZOOM}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <Marker
        position={{ lat: lat, lng: lng }}
        draggable={true}
        onDragEnd={onMarkerDragEnd}
      />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MyGoogleMap);
