import React from "react";
import "./FeatureBadge.css";

const FeatureBadge = ({ title }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      {title}
      <div className="feature-badge-container">NEW</div>
    </div>
  );
};

export default FeatureBadge;
