import React, { useState } from "react";
import { Table } from "antd";
import useOrders from "./useOrders";

import OrderFilters from "./OrderFilters";
import OrderActions from "./OrderActions";
import EditOrderModal from "./EditOrderModal";
import OrderUpdateModal from "./OrderUpdateModal";

const Orders = () => {
  const {
    columns,
    columnsWidth,
    downloading,
    handleDownloadCsv,
    filteredTableData,
    selectedRows,
    handleUpdateOrder,
    filters,
    handleFilterChange,
    rowSelection,
    loading,
    editableOrderId,
    handleUpdateStatus,
    editedRecords,
    editStatus,
    setEditStatus,
    handleRecordStatusChange,
    statusUpdateLoading,
    openUpdateModal,
    setOpenUpdateModal,
    orderStatus,
    setOrderStatus,
  } = useOrders();

  return (
    <div className="mx-auto" style={{ padding: "12px" }}>
      <div className="orders-action-bar-container">
        <OrderActions
          tableData={filteredTableData}
          handleDownloadCsv={handleDownloadCsv}
          downloading={downloading}
          showUpdateOrders={selectedRows?.length > 0}
          handleUpdateOrder={handleUpdateOrder}
        />
        <OrderFilters
          filters={filters}
          handleUpdateOrder={handleUpdateOrder}
          handleFilterChange={handleFilterChange}
        />
      </div>

      <Table
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        loading={loading}
        selectedRows
        bordered
        columns={columns}
        dataSource={filteredTableData}
        rowKey={(record) => `${record.id}|${record.itemIndex}`}
        pagination={{
          position: ["bottomLeft"],
          defaultPageSize: 50,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
        scroll={{ x: columnsWidth }}
        className="table-backdrop"
        bodyStyle={{ backgroundColor: "#ffffff" }}
      />

      <EditOrderModal
        statusUpdateLoading={statusUpdateLoading}
        editableOrderId={editableOrderId}
        tableData={filteredTableData}
        handleUpdateStatus={handleUpdateStatus}
        editedRecords={editedRecords}
        editStatus={editStatus}
        setEditStatus={setEditStatus}
        handleRecordStatusChange={handleRecordStatusChange}
      />

      <OrderUpdateModal
        openUpdateModal={openUpdateModal}
        setOpenUpdateModal={setOpenUpdateModal}
        selectedRows={selectedRows}
        editedRecords={editedRecords}
        handleRecordStatusChange={handleRecordStatusChange}
        handleUpdateStatus={handleUpdateStatus}
        statusUpdateLoading={statusUpdateLoading}
        orderStatus={orderStatus}
        setOrderStatus={setOrderStatus}
      />
    </div>
  );
};

export default Orders;
