import React, { useEffect, useState } from "react";
import ScreenContainer from "../../../../CommonComponents/ScreenContainer/ScreenContainer";
import ScreenHeader from "../../../../CommonComponents/ScreenHeader";
import { Avatar, Popconfirm, Spin, Table } from "antd";
import { DownloadIcon } from "../../../../utils/icons.jsx";
import { useParams } from "react-router-dom";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import { useSelector } from "react-redux";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../services/firebaseService/connection";
import logger from "../../../../utils/logger";
import {
  getDayjsFromTimestamp,
  sortObjectByNumber,
  sortObjectByString,
} from "../../../../utils/helper";
import { getCurrencyUnicode } from "../../../../helperFunctions/util";
import DescriptionText from "../../../../CommonComponents/DescriptionText";
import useGlobalStore from "../../../../store/store";
import { json2csv } from "json-2-csv";

const AdminGiveawayEntries = () => {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(false);

  const { selectedGiveawayDetails } = useGlobalStore.getState().giveaways;

  const { giveawayId } = useParams();
  const groupData = useSelector(selectCurrentGroupData);

  let groupCurrencyISO = groupData?.paymentDetails
    ? groupData?.paymentDetails.currency || "USD"
    : "USD";
  let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

  const handleDownloadCsv = (tableData) => {
    let result = [];

    tableData.forEach((record) => {
      let row = {
        Member: record?.userName || "",
        Entries: record?.entriesCount || "",
        Amount: record?.amountSpent
          ? `${groupCurrency}${record?.amountSpent}`
          : "",
        "Added On": getDayjsFromTimestamp(record?.addedOn)
          ? getDayjsFromTimestamp(record?.addedOn).format("Do MMM, YYYY")
          : "",
      };
      result.push(row);
    });
    const csv = json2csv(result);

    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
    a.download = "GiveawayEntries.csv";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const fetchGiveawaysById = async (giveawayDocId) => {
    try {
      const giveawaysRef = collection(
        db,
        `/payments/${groupData?.id}/giveaways`
      );
      const q = query(
        giveawaysRef,
        where("giveawayDocId", "==", giveawayDocId)
      );

      const querySnapshot = await getDocs(q);

      const giveaways = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return giveaways;
    } catch (error) {
      logger.error("Error fetching giveaways:", error);
      return [];
    }
  };

  const fetchGiveawayEntries = async (giveawayId) => {
    try {
      setLoading(true);

      const entriesRef = collection(
        db,
        `/giveaways/${groupData?.id}/events/${giveawayId}/participants`
      );
      const entriesSnapshot = await getDocs(entriesRef);

      const entries = {};
      entriesSnapshot.docs.forEach((entryDoc) => {
        const data = entryDoc.data();
        const userDocId = data.userDocId;

        if (entries[userDocId]) {
          entries[userDocId].entriesCount += 1;
        } else {
          entries[userDocId] = {
            ...data,
            entriesCount: 1,
          };
        }
      });

      const data = await fetchGiveawaysById(giveawayId);

      data.forEach((entry) => {
        entries[entry.userDocId] = {
          ...entries[entry.userDocId],
          noOfEntriesPurchased:
            (entries[entry.userDocId].noOfEntriesPurchased || 0) +
            (entry?.noOfEntriesPurchased || 0),
          amountSpent:
            (entries[entry.userDocId].amountSpent || 0) + (entry?.amount || 0),
        };
      });

      const uniqueEntries = Object.values(entries);

      uniqueEntries.sort(
        (a, b) => (b?.entriesCount || 0) - (a?.entriesCount || 0)
      );

      setEntries(uniqueEntries);
    } catch (error) {
      logger.error("Failed to fetch entries");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Member",
      width: 300,
      dataIndex: "userName",
      key: "userName",
      sorter: (a, b) => sortObjectByString(a, b, "userName"),
      render: (name, record) => {
        const nameInitials =
          name
            ?.split(" ")
            ?.map((word) => word?.[0] || "")
            ?.join("")
            ?.toUpperCase() || "";

        return (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {" "}
            <div className="flex-center">
              {record?.userImage ? (
                <Avatar size="large" src={record?.userImage} />
              ) : (
                <Avatar
                  size="large"
                  style={{ backgroundColor: "var(--primary-background)" }}
                >
                  {nameInitials}
                </Avatar>
              )}
            </div>
            <span className="flex-center">{name}</span>
          </div>
        );
      },
    },
    {
      title: "Entries",
      key: "entriesCount",
      className: "header-white",
      dataIndex: "entriesCount",
      sorter: (a, b) => sortObjectByNumber(a, b, "entriesCount"),
      width: 100,
    },
    {
      title: "Amount",
      key: "amountSpent",
      className: "header-white",
      dataIndex: "amountSpent",
      width: 100,
      render: (data) => {
        return data ? (
          <span>
            {groupCurrency}
            {data}
          </span>
        ) : (
          <></>
        );
      },
    },

    {
      title: "Added On",
      key: "addedOn",
      className: "header-white",
      dataIndex: "addedOn",
      width: 100,
      render: (data) =>
        getDayjsFromTimestamp(data)
          ? getDayjsFromTimestamp(data).format("Do MMM, YYYY")
          : "",
    },
  ];

  useEffect(() => {
    if (giveawayId && giveawayId?.length > 0 && groupData?.id) {
      fetchGiveawayEntries(giveawayId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData?.id, giveawayId]);

  return (
    <ScreenContainer>
      <ScreenHeader
        title="Entries"
        elementCount={entries?.length}
        actions={
          <div>
            <Popconfirm
              title="Download entries list?"
              onConfirm={() => handleDownloadCsv(entries)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
              trigger="click"
            >
              <div className="icon-container" style={{ cursor: "pointer" }}>
                <img
                  src={DownloadIcon}
                  alt="icon"
                  style={{ fontSize: "20px" }}
                />
              </div>
            </Popconfirm>
          </div>
        }
      />

      <div className="admin-poll-description">
        <DescriptionText>
          <span style={{ fontWeight: "bold" }}>Giveaway</span>:{" "}
          {selectedGiveawayDetails?.giveaway || ""}
        </DescriptionText>
      </div>

      <Table
        bordered
        columns={columns}
        loading={loading}
        dataSource={entries}
        pagination={{
          position: ["bottomLeft"],
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
        rowKey="id"
        key="id"
      />
    </ScreenContainer>
  );
};

export default AdminGiveawayEntries;
