import { Result } from "antd";
import React from "react";
import { Navigate } from "react-router-dom";
import CreateContent from "../admin/content/Create";
import ListContent from "../admin/content/List";
// import Faq from "../admin/Faq";
import PaymentDetails from "../admin/groupConfig/PaymentDetails";
import RegistrationCode from "../admin/groupConfig/RegistrationCode";
import HelpPage from "../admin/help/HelpPage";
import MembershipCard from "../admin/members/MembershipCard/MembershipCard";
import CreateChant from "../admin/ourChants/Create";
import CreateTicket from "../admin/tickets/Create";
import ListTicket from "../admin/tickets/List";
import ViewTicket from "../admin/tickets/View";
import AdminLogin from "../authentication/AdminLogin";
import AdminRegister from "../authentication/AdminRegister";
import Register from "../authentication/Register";
import Chants from "../clubAdmin/chants/Chants";
import CreateChants from "../clubAdmin/chants/CreateChants";
import Content from "../clubAdmin/contents/Content";
import CreateClubContent from "../clubAdmin/contents/CreateClubContent";
import Gallery from "../clubAdmin/gallery/Gallery";
import ListGiveaways from "../clubAdmin/giveaways/ListGiveaways";
import ListGroups from "../clubAdmin/groups/ListGroups";
import ListMembers from "../clubAdmin/members/List";
import ClubMembershipCard from "../clubAdmin/membership/ClubMembershipCard";
import CreatePoll from "../clubAdmin/polls/Create";
import Polls from "../clubAdmin/polls/List";
import Membership from "../member/Membership";
import CreateGroup from "../superAdmin/group/Create";
import ListGroup from "../superAdmin/group/List";
import SuperAdminPolls from "../superAdmin/polls/List";
import CreateSuperAdminPoll from "../superAdmin/polls/Create";
import CreateGiveaway from "../clubAdmin/giveaways/Create";
import MembershipForm from "../authentication/MembershipForm";
import Container from "../admin/website/Container";
import MemberPackageForm from "../admin/members/MemberPackageForm";
import CreateItem from "../admin/store/create";
import AppSettings from "../admin/groupConfig/AppSetting";
import DirectoryAdd from "../admin/global/directory";
import TransactionReport from "../superAdmin/report/transactions";
import Deactivated from "../superAdmin/deactivated/Deactivated";
import Data from "../superAdmin/data/Data";
import PartnersList from "../admin/partners/PartnersList";
import CreatePartners from "../admin/partners/CreatePartner";
import Welcome from "../admin/content/Welcome";
import DirectoryReview from "../superAdmin/group/Directory";
import StadiumContainer from "../admin/stadium/Container";
import StatusReport from "../superAdmin/status/Report";
import Logos from "../superAdmin/logos/logos";
import CreateLocations from "../admin/locations/CreateLocation/CreateLocations";
import AdoptionClass from "../superAdmin/adoption/AdoptionClass";
import TicketsReport from "../superAdmin/ticketsReport/TicketsReport";
import OurChants from "../admin/ourChants/OurChants";
import Members from "../admin/members/Members";
import Packages from "../admin/members/Packages";
import Onboard from "../admin/members/Onboard/Onboard";
import Promote from "../admin/members/Promote/Promote";
import PurchasesReport from "../admin/purchases/PurchasesReport";
import GroupDesign from "../admin/groupConfig/GroupDesign/GroupDesign";
import Leaderboard from "../admin/members/Leaderboard/Leaderboard";
import Merchandise from "../admin/store/Merchandise/Merchandise";
import StoreSettings from "../admin/store/Settings/StoreSettings";
import Orders from "../admin/store/Orders/Orders";
import Locations from "../admin/locations/Locations";
import AdminGallery from "../admin/gallery/AdminGallery";
import AdminPolls from "../admin/content/AdminPolls/AdminPolls";
import AdminGiveaways from "../admin/content/AdminGiveaways/AdminGiveaways";
import AdminPollVotes from "../admin/content/AdminPolls/AdminPollVotes";
import AdminGiveawayEntries from "../admin/content/AdminGiveaways/AdminGiveawayEntries";
import AdminPollCreate from "../admin/content/AdminPolls/CreatePoll/AdminPollCreate";

export const adminRoutes = [
  { path: "/admin/content", element: <ListContent /> },
  { path: "/admin/content/polls", element: <AdminPolls /> },
  { path: "/admin/content/polls/:pollId", element: <AdminPollVotes /> },
  { path: "/admin/content/polls/create", element: <AdminPollCreate /> },
  { path: "/admin/content/giveaways", element: <AdminGiveaways /> },
  {
    path: "/admin/content/giveaways/:giveawayId",
    element: <AdminGiveawayEntries />,
  },
  { path: "/admin/content/create", element: <CreateContent /> },
  {
    path: "/admin/content/edit/:group/:id",
    element: <CreateContent />,
  },
  {
    path: "/admin/content/view/:group/:id",
    element: <CreateContent />,
  },
  {
    path: "/admin/directory/groupListing",
    element: <DirectoryAdd />,
  },
  // { path: "/admin/promote", component: Promote, exact: true },
  { path: "/admin/membershipcard", element: <MembershipCard /> },
  { path: "/admin/members/leaderboard/:action?", element: <Leaderboard /> },
  {
    path: "/admin/members/purchaseReport",
    element: <PurchasesReport />,
  },
  { path: "/admin/members/all", element: <Members /> },
  { path: "/admin/members", element: <Navigate to="/admin/members/all" /> },
  {
    path: "/admin/members/acceptRequest/:id/:group",
    element: <Members />,
  },
  {
    path: "/admin/members/packages/:action?",
    element: <Packages />,
  },
  {
    path: "/admin/members/onboard/:action?",
    element: <Onboard />,
  },

  {
    path: "/admin/members/promote",
    element: <Promote />,
  },
  { path: "/admin/store/create", element: <CreateItem /> },
  { path: "/admin/store/orders", element: <Orders /> },
  { path: "/admin/store/merch", element: <Merchandise /> },
  { path: "/admin/store/settings", element: <StoreSettings /> },
  { path: "/admin/store/edit/:id", element: <CreateItem /> },
  {
    path: "/admin/registrationCode",
    element: <RegistrationCode />,
  },
  { path: "/admin/groupSettings", element: <GroupDesign /> },
  // { path: "/admin/faq", element: <Faq },
  { path: "/admin/chants/create", element: <CreateChant /> },
  { path: "/admin/appSettings", element: <AppSettings /> },
  { path: "/admin/help", element: <HelpPage /> },
  {
    path: "/admin/chants/edit/:group/:id",
    element: <CreateChant />,
  },
  { path: "/admin/gallery", element: <AdminGallery /> },
  { path: "/admin/welcome", element: <Welcome /> },
  { path: "/admin/content/locations", element: <Locations /> },
  {
    path: "/admin/content/locations/create",
    element: <CreateLocations />,
  },
  {
    path: "/admin/content/locations/edit/:id",
    element: <CreateLocations />,
  },
  { path: "/admin/chants", element: <OurChants /> },
  { path: "/admin/partners/add", element: <CreatePartners /> },
  {
    path: "/admin/partners/edit/:id",
    element: <CreatePartners />,
  },
  { path: "/admin/partners/:path", element: <PartnersList /> },
  { path: "/admin/tickets/create", element: <CreateTicket /> },
  { path: "/admin/tickets/:tab", element: <ListTicket /> },
  { path: "/admin/website/:tab", element: <Container /> },
  {
    path: "/admin/tickets/edit/:group/:id",
    element: <CreateTicket />,
  },
  {
    path: "/admin/tickets/:group/:id",
    element: <ViewTicket />,
  },
  { path: "/admin/paymentDetails", element: <PaymentDetails /> },
  {
    path: "/admin/members/:groupName/add",
    element: <MembershipForm />,
  },

  {
    path: "/admin/members/addPackage",
    element: <MemberPackageForm />,
  },
  { path: "/admin/stadium/:tab", element: <StadiumContainer /> },
  {
    path: "/login",
    element: <Navigate to="/admin/content" replace />,
  },
  { path: "/", element: <Navigate to="/admin/content" replace /> },
];

export const superAdminRoutes = [
  { path: "/superadmin/groups/create", element: <CreateGroup /> },
  { path: "/superadmin/groups", element: <ListGroup /> },
  {
    path: "/superadmin/groups/edit/:id",
    element: <CreateGroup />,
  },
  { path: "/superadmin/polls", element: <SuperAdminPolls /> },
  {
    path: "/superadmin/polls/create",
    element: <CreateSuperAdminPoll />,
  },
  {
    path: "/superadmin/polls/edit/:id",
    element: <CreateSuperAdminPoll />,
  },
  {
    path: "/superadmin/report/transactions",
    element: <TransactionReport />,
  },
  { path: "/superadmin/deactivated", element: <Deactivated /> },
  { path: "/superadmin/data", element: <Data /> },
  { path: "/superadmin/directory", element: <DirectoryReview /> },
  { path: "/superadmin/adoption", element: <AdoptionClass /> },
  {
    path: "/superadmin/missing-transactions",
    element: <TicketsReport />,
  },

  { path: "/superadmin/logos", element: <Logos /> },
  { path: "/superadmin/status/report", element: <StatusReport /> },

  {
    path: "/login",
    element: <Navigate to="/superadmin/groups" replace />,
  },
  {
    path: "/",
    element: <Navigate to="/superadmin/groups" replace />,
  },
];

export const unauthenticatedRoutes = [
  { path: "/login", element: <AdminLogin /> },
  { path: "/adminlogin", element: <AdminLogin /> },
  { path: "/", element: <Navigate to="/login" replace /> },
  { path: "/admin/*", element: <Navigate to="/login" replace /> },
];

export const memberRoutes = [{ path: "/membership", element: <Membership /> }];

export const commonRoutes = [
  { path: "/admin/register/:email", element: <AdminRegister /> },
  { path: "/register", element: <Register /> },
  { path: "/:groupName/join", element: <MembershipForm /> },
  {
    path: "*",
    element: (
      <Result status="404" title="404" subTitle="This page is not found" />
    ),
  },
];

export const clubAdminRoutes = [
  { path: "/clubadmin/groups", element: <ListGroups /> },
  {
    path: "/clubadmin/groups/:id/members",
    element: <ListMembers />,
  },
  { path: "/clubadmin/members", element: <ListMembers /> },
  { path: "/clubadmin/content", element: <Content /> },
  {
    path: "/clubadmin/content/create",
    element: <CreateClubContent />,
  },
  {
    path: "/clubadmin/content/edit/:group/:id",
    element: <CreateClubContent />,
  },
  { path: "/clubadmin/gallery", element: <Gallery /> },
  { path: "/clubadmin/giveaways", element: <ListGiveaways /> },
  {
    path: "/clubadmin/giveaways/create",
    element: <CreateGiveaway />,
  },
  {
    path: "/clubadmin/giveaways/edit/:group/:id",
    element: <CreateGiveaway />,
  },
  { path: "/clubadmin/predictions", element: <ListGiveaways /> },
  { path: "/clubadmin/polls", element: <Polls /> },
  { path: "/clubadmin/polls/create", element: <CreatePoll /> },
  {
    path: "/clubadmin/polls/edit/:group/:id",
    element: <CreatePoll />,
  },
  { path: "/clubadmin/var", element: <ListGiveaways /> },
  { path: "/clubadmin/chants", element: <Chants /> },
  { path: "/clubadmin/help", element: <HelpPage /> },
  { path: "/clubadmin/chants/create", element: <CreateChants /> },
  {
    path: "/clubadmin/chants/edit/:group/:id",
    element: <CreateChants />,
  },
  { path: "/clubadmin/approvals", element: <ListGiveaways /> },
  {
    path: "/clubadmin/membership",
    element: <ClubMembershipCard />,
  },
  {
    path: "/login",
    element: <Navigate to="/clubadmin/groups" replace />,
  },
  { path: "/", element: <Navigate to="/clubadmin/groups" replace /> },
];
