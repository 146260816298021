import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Typography } from "antd";
import "./GroupDropdown.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAdminData,
  selectCurrentGroupData,
} from "../../../../redux/selectors/adminData";
import { adminGroupChange } from "../../../../redux/actions/adminData";
import { useNavigate } from "react-router-dom";

const GroupDropdown = ({
  dashboard,
  clubGroup,
  groupNameKeyMap,
  setDashboard,
  isParent,
}) => {
  const groupData = useSelector(selectCurrentGroupData);

  const items = [
    ...(clubGroup
      ? [
          {
            key: `${clubGroup.data.id}|clubadmin`,
            label: `${clubGroup.data.groupName} Parent`,
          },
        ]
      : []),
    ...Object.keys(groupNameKeyMap)
      .sort((name1, name2) => name1.localeCompare(name2))
      .map((groupName) => ({
        key: `${groupNameKeyMap[groupName]}|admin`,
        label: groupName,
      })),
  ];

  const adminData = useSelector(selectAdminData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const restrictedRoutes = [
    "edit",
    "create",
    "/admin/partners/add",
    /^\/admin\/content\/polls\/[a-zA-Z0-9]+$/,
    /^\/admin\/content\/giveaways\/[a-zA-Z0-9]+$/,
  ];

  const handleAdminGroupChange = (value) => {
    const [groupId, selectedDashboard] = value?.key.split("|");

    if (selectedDashboard === "admin") {
      const isSwitchAllowed = restrictedRoutes.some((route) => {
        if (typeof route === "string") {
          return window.location.pathname.includes(route);
        } else if (route instanceof RegExp) {
          return route.test(window.location.pathname);
        }
        return false;
      });

      if (isSwitchAllowed) {
        window.alert("You cannot change group at this page");
      } else {
        window.localStorage.setItem("currentGroup", groupId);
        const group = Object.keys(adminData.allGroups).find(
          (group) => group === groupId
        );
        dispatch(adminGroupChange({ group, dashboard: "admin" }));
      }
    } else {
      dispatch(
        adminGroupChange({ group: groupId, dashboard: selectedDashboard })
      );
    }

    if (dashboard !== selectedDashboard) {
      const newPath =
        selectedDashboard === "clubadmin"
          ? "/clubadmin/groups"
          : "/admin/content";
      if (selectedDashboard === "clubadmin") {
        document.documentElement.style.setProperty("--main-color", "#fafafa");
      }
      navigate(newPath);
      setDashboard(selectedDashboard);
    }
  };

  // const handleAdminGroupChange = (value) => {
  //   const [groupId, selectedDashboard] = value?.key.split("|");

  //   if (selectedDashboard === "admin") {
  //     if (
  //       !window.location.pathname.includes("/admin/content/") ||
  //       !window.location.pathname.includes("/admin/content/locations") ||
  //       !window.location.pathname.includes("/admin/content/leaderboard")
  //     ) {
  //       window.localStorage.setItem("currentGroup", groupId);
  //       let group = Object.keys(adminData.allGroups).find((group) => {
  //         return group === groupId;
  //       });
  //       dispatch(adminGroupChange({ group, dashboard: "admin" }));
  //     } else {
  //       window.alert("You cannot change group at this page");
  //     }
  //   } else {
  //     dispatch(
  //       adminGroupChange({ group: groupId, dashboard: selectedDashboard })
  //     );
  //   }

  //   if (dashboard !== selectedDashboard) {
  //     let history;
  //     if (selectedDashboard === "clubadmin") {
  //       history = "/clubadmin/groups";
  //       let root = document.querySelector(":root");
  //       root.style.setProperty("--main-color", "#fafafa");
  //     } else {
  //       history = "/admin/content";
  //     }
  //     navigate(history);
  //   }

  //   setDashboard(selectedDashboard);
  // };
  return (
    <>
      {items?.length === 1 ? (
        <Typography>
          <Space className="group-dropdown-name--singular">
            {dashboard === "clubadmin"
              ? clubGroup
                ? `${clubGroup.data.groupName} Parent`
                : ""
              : groupData.groupName}
          </Space>
        </Typography>
      ) : (
        <Dropdown
          className="custom-dropdown"
          trigger={["click"]}
          menu={{
            items,
            selectable: true,
            defaultSelectedKeys: [
              dashboard === "clubadmin"
                ? `${clubGroup?.data.id}|clubadmin`
                : `${groupNameKeyMap[groupData.groupName]}|admin`,
            ],
            onClick: handleAdminGroupChange,
            style: {
              maxHeight: "400px",
              overflowY: "auto",
            },
          }}
        >
          <Typography.Link>
            <Space className="group-dropdown-name">
              {dashboard === "clubadmin"
                ? clubGroup
                  ? `${clubGroup.data.groupName} Parent`
                  : ""
                : groupData.groupName}
              <DownOutlined />
            </Space>
          </Typography.Link>
        </Dropdown>
      )}
    </>
  );
};

export default GroupDropdown;
