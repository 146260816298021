import React from "react";
import { Tooltip } from "antd";

export function FInput(props) {
  return (
    <div
      // style={{ display: "flex", flexWrap: "wrap" }}
      className={`form-group ${props.display || "my-row"} ${props.containerClassname}`}
    >
      {props.label && (
        <div className={`${props.display !== "col" ? "col-4" : ""}`}>
          <label
            className={props.textCenter ? "text-center" : ""}
            style={{ marginBottom: 0 }}
          >
            {props.label}
            {props.required && <span style={{ color: "red" }}>*</span>}
          </label>
          {props.tooltip && (
            <Tooltip
              placement="topLeft"
              title={() => (
                <div
                  style={{
                    height: "fit-content",
                    width: "400px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {props.tooltip}
                </div>
              )}
            >
              <span className="border-0">
                &nbsp;<i className="fa fa-question-circle-o"></i>
              </span>
            </Tooltip>
          )}
        </div>
      )}

      <input
        onChange={props.onChange}
        value={props.value}
        name={props.name}
        className={`form-control form-control-sm ${
          props.display !== "col" && props.label && props.postTip
            ? "col-md-3"
            : "col"
        } ${
          props.validFeedback && "is-valid"
        } ${props.invalidFeedback && "is-invalid"} ${props.className}`}
        placeholder={props.placeholder}
        onBlur={true && props.onBlur}
        tabIndex={props.tabIndex || 0}
        type={props.type || "text"}
        step={true && props.step}
        required={props.required}
        disabled={props.disabled}
        min={props.min}
        max={props.max}
        maxLength={props.maxLength}
        style={props.style}
        onKeyDown={props.onKeyPress}
        readOnly={props.readOnly}
        onWheel={(e) => {
          if (props.type === "number") {
            e.target.blur();
          }
        }}
      />
      {props.postTip && (
        <div className="col-md-5">
          <span style={{ fontSize: 13 }}>{props.postTip}</span>
        </div>
      )}

      {props.validFeedback && (
        <div className="valid-feedback">{props.validFeedback}</div>
      )}

      {props.invalidFeedback && (
        <div className="invalid-feedback">{props.invalidFeedback}</div>
      )}

      {props.bottomHint && (
        <div className="col">
          <div className="row" style={{ fontSize: 13 }}>
            <div className="col-md-4"></div>
            {props.bottomHint}
          </div>
        </div>
      )}
    </div>
  );
}
