import React from "react";
import "./ScreenHeader.css";

const ScreenHeader = ({
  title,
  elementCount,
  actions,
  className = "",
  style = {},
}) => {
  return (
    <div className={`screen-header-container ${className}`} style={style}>
      <div>
        <h4
          style={{
            marginBottom: "0px",
            display: "flex",
            gap: "6px",
          }}
        >
          <span>{title} </span>
          {elementCount >= 0 && (
            <div>
              <span
                style={{ fontSize: "18px", color: "gray", fontWeight: "400" }}
              >{`(${elementCount})`}</span>
            </div>
          )}
        </h4>
      </div>

      <div
        className="screen-header-actions-container"
        style={{ display: "flex", gap: "12px" }}
      >
        {actions}
      </div>
    </div>
  );
};

export default ScreenHeader;
