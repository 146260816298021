import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from "redux";
import { thunk } from "redux-thunk";
import groupReducer from "./reducers/group";
import userReducer from "./reducers/user";
import adminDataReducer from "./reducers/adminData";
import countryReducer from "./reducers/appData/country";
import errorReducer from "./reducers/error";
import pathReducer from "./reducers/appData/path";
import promoteReducer from "./reducers/appData/promote";
import superAdminReducer from "./reducers/superadmin";
import appReducer from "./reducers/app";

const composeEnhancer =
  typeof window !== "undefined" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  import.meta.env.VITE_NODE_ENV !== "production"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : compose;

const configureStore = () => {
  return createStore(
    combineReducers({
      groups: groupReducer,
      user: userReducer,
      adminData: adminDataReducer,
      country: countryReducer,
      error: errorReducer,
      path: pathReducer,
      promote: promoteReducer,
      superAdmin: superAdminReducer,
      app: appReducer,
    }),
    composeEnhancer(applyMiddleware(thunk))
  );
};

export default configureStore;
